import { commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../../environment';

const mutation = graphql`
  mutation ContactusMutation($email: String!, $message: String!, $mobileNumber: String!, $name: String!) {
        contactForm(email: $email, message: $message, mobileNumber: $mobileNumber, name: $name){
            ok
            message
        }
  }
`

export default (userData, callback,errCallback) => {
    const variables = {
        email: userData.email,
        message: userData.message,
        mobileNumber: userData.mobileNumber,
        name: userData.name
    }

    // 5
    commitMutation(
        environment,
        {
            mutation,
            variables,
            // 6
            onCompleted: (response,err) => {

                if(response.contactForm !== null){
                    callback(response)
                } else{
                    errCallback(err[0].message);
                }

            },
            onError: err => {
                errCallback(err);
            },
        },
    )
}
