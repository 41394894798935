import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import reportWebVitals from './reportWebVitals';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import 'react-accessible-accordion/dist/fancy-example.css';
import 'aos/dist/aos.css';
import 'react-multi-carousel/lib/styles.css';


import {TransitionGroup,CSSTransition} from "react-transition-group";
import {withRouter} from "react-router-dom";

import {Home} from "./Pages/Home/Home";
import Service from "./Pages/Services/service";
import AboutUs from "./Pages/AboutUs/AboutUs";
import MainGallery from "./Pages/Gallery/mainGallery";
import SubGallery from "./Pages/Gallery/subGallery";
import ContactUs from "./Pages/ContactUs/ContactUs";
import FAQ from "./Pages/FAQ/FAQ";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./Pages/TermsAndConditions/TermsAndConditions";
import Header from "./Components/Header/Header";
import Test from "./test";


// import "./assets/fonts/Montserrat/static/Montserrat-Black.ttf";
// import "./assets/fonts/Montserrat/static/Montserrat-BlackItalic.ttf";
// import "./assets/fonts/Montserrat/static/Montserrat-Bold.ttf";
// import "./assets/fonts/Montserrat/static/Montserrat-BoldItalic.ttf";
// import "./assets/fonts/Montserrat/static/Montserrat-ExtraBold.ttf";
// import "./assets/fonts/Montserrat/static/Montserrat-ExtraBoldItalic.ttf";
// import "./assets/fonts/Montserrat/static/Montserrat-ExtraLight.ttf";
// import "./assets/fonts/Montserrat/static/Montserrat-ExtraLightItalic.ttf";
// import "./assets/fonts/Montserrat/static/Montserrat-Italic.ttf";
// import "./assets/fonts/Montserrat/static/Montserrat-Light.ttf";
// import "./assets/fonts/Montserrat/static/Montserrat-LightItalic.ttf";
// import "./assets/fonts/Montserrat/static/Montserrat-Medium.ttf";
// import "./assets/fonts/Montserrat/static/Montserrat-MediumItalic.ttf";
// import "./assets/fonts/Montserrat/static/Montserrat-Regular.ttf";
// import "./assets/fonts/Montserrat/static/Montserrat-SemiBold.ttf";
// import "./assets/fonts/Montserrat/static/Montserrat-SemiBoldItalic.ttf";
// import "./assets/fonts/Montserrat/static/Montserrat-Thin.ttf";
// import "./assets/fonts/Montserrat/static/Montserrat-ThinItalic.ttf";



const AnimatedSwitch = withRouter(({ location }) => (
    <>
        <Header page={location.pathname}/>
        <TransitionGroup>
            <CSSTransition
                key={location.key}
                classNames="fade"
                timeout={450}
            >
                <Switch location={location}>
                    <Route path="/" exact>
                        <Home />
                    </Route>
                    <Route path="/home" exact>
                        <Home />
                    </Route>
                    <Route path="/services" exact>
                        <Service />
                    </Route>
                    <Route path="/aboutus" exact>
                        <AboutUs />
                    </Route>
                    <Route path="/gallery" exact>
                        <MainGallery />
                    </Route>
                    <Route path="/sub-gallery/:gallery" exact>
                        <SubGallery />
                    </Route>
                    <Route path="/contactus" exact>
                        <ContactUs />
                    </Route>
                    <Route path="/faq" exact>
                        <FAQ />
                    </Route>
                    <Route path="/privacy-policy" exact>
                        <PrivacyPolicy />
                    </Route>
                    <Route path="/terms-and-conditions" exact>
                        <TermsAndConditions />
                    </Route>
                    <Route path="/test" exact>
                        <Test />
                    </Route>
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    </>

));




ReactDOM.render( <Router><AnimatedSwitch/></Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
