import "./ContactUs.scss";
import React, {useEffect, useState} from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import {MDBCol, MDBContainer, MDBRow} from "mdbreact";
import GoogleMapReact from 'google-map-react';
import AOS from "aos";
import GoToTop from "../../GoToTop";
import * as EmailValidator from 'email-validator';
import cogoToast from 'cogo-toast';
import ContactusMutation from "./ContactusMutation";

const ContactUs = () => {
    const [getname, setname] = useState("");
    const [getnumber, setnumber] = useState("");
    const [getEmail, setEmail] = useState("");
    const [getmessage, setmessage] = useState("");
    const [error,setError] = useState({});

    const show = () => {
        let tmp_error = {};
        if(getname === ""){
            tmp_error['name'] = "Please fill valid Name";
            setError(tmp_error);
        } else if(getnumber === ""){
            tmp_error['phone_number'] = "Please fill valid Mobile Number";
            setError(tmp_error);
        } else if(getnumber.length < 10){
            tmp_error['phone_number'] = "Mobile Number should be 10 digit";
            setError(tmp_error);
        }
        else if(EmailValidator.validate(getEmail) === false){
            tmp_error['email'] = "Please fill valid Email";
            setError(tmp_error);
        }
        else if(getmessage === ""){
            tmp_error['message'] = "Please fill valid Message";
            setError(tmp_error);
        } else {
            tmp_error = {};
            setError(tmp_error);
            let userData = {
                email: getEmail,
                message: getmessage,
                mobileNumber: getnumber,
                name: getname
            }
            ContactusMutation(userData,function (response) {

                if(response.contactForm !== null){

                    setname("");
                    setnumber("");
                    setEmail("");
                    setmessage("");

                    cogoToast.success(response.contactForm.message, { position: 'top-center'});

                } else {
                    cogoToast.error("form not submitted", { position: 'top-center'});
                }
            },function (err) {
                cogoToast.error(err, { position: 'top-center'});
            });
        }
    }


    GoToTop()
    useEffect(() => {
        AOS.init();
    },[]);

    const handleMobileNoChange = (event) => {
       let tmp_no = (event.target.validity.valid) ? event.target.value : getnumber
        setnumber(tmp_no);
    };

    return (
        <div className="contact">
            {/*<Header page={"contactus"}/>*/}
            <div className="top">
                <div className="bg px-4">

                    <p className="title"
                       data-aos="fade-up"
                       data-aos-duration="1000"
                       data-aos-easing="linear"
                       data-aos-once="true"
                    >
                        <span className={"highlighted-title"}>
                        Enquiry
                    </span>


                    </p>
                    <div className="input" data-aos="fade-up"
                         data-aos-duration="1000"
                         data-aos-easing="linear"
                         data-aos-once="true"
                         data-aos-delay="100"
                    >
                        <div className="name">
                            <MDBContainer className={"contactus-container"}>
                                <MDBRow>
                                    <MDBCol sm="6">
                                        <div className={"input-feild-section"}>
                                            <input
                                                onChange={e => setname(e.target.value)}
                                                type="text"
                                                id="lname"
                                                className="input-text"
                                                placeholder="Name" value={getname}
                                            />
                                            {
                                                error.name &&
                                                    <p className={"error-message  mb-0"}>{error.name}</p>
                                            }
                                        </div>
                                    </MDBCol>
                                    <MDBCol sm="6">
                                        <div className={"input-feild-section"}>
                                            <input
                                                onChange={handleMobileNoChange}
                                                onInput={handleMobileNoChange}
                                                type="text"
                                                pattern="[0-9]*"
                                                maxLength="10"
                                                id="lname"
                                                className="input-text"
                                                placeholder="Mobile Number" value={getnumber}
                                            />
                                            {
                                                error.phone_number &&
                                                <p className={"error-message  mb-0"}>{error.phone_number}</p>
                                            }
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12">
                                        <div className={"input-feild-section"}>
                                            <input
                                                onChange={e => setEmail(e.target.value)}
                                                type="email"
                                                id="lname"
                                                className="input-text E-mail"
                                                placeholder="Email" value={getEmail}
                                            />
                                            {
                                                error.email &&
                                                <p className={"error-message  mb-0"}>{error.email}</p>
                                            }
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol sm="12">
                                        <div className={"input-feild-section"}>
                                             <textarea rows="4" cols="50" className="input-text Message mb-0"
                                                       placeholder="Your Message "
                                                        onChange={e => setmessage(e.target.value)} value={getmessage}
                                                        />
                                            {
                                                error.message &&
                                                <p className={"error-message  mb-0"}>{error.message}</p>
                                            }
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <div className='button mx-auto' onClick={show}>Get in touch</div>

                            </MDBContainer>
                        </div>
                    </div>
                </div>
            </div>
            <p className={"map-title"}
               data-aos="fade-up"
               data-aos-duration="1000"
               data-aos-easing="linear"
               data-aos-once="true"
            >Get Directions</p>
            <div data-aos="fade-up"
                 data-aos-duration="1000"
                 data-aos-easing="linear"
                 data-aos-once="true">
                <SimpleMap/>
            </div>
            <br/>
            <br/>
            <Footer/>
        </div>
    );
};

export default ContactUs;


const AnyReactComponent = ({ text }) => <div>{text}</div>;


class SimpleMap extends React.Component {
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render() {
        return (
            // Important! Always set the container height explicitly
            <div style={{ height: '100vh', width: '100%' }}>
                <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.5697316893998!2d79.90834496704358!3d12.935352766744703!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52ed79c99d3bd3%3A0x3b9328efe9d883ef!2sPRM%20Roadways%20private%20limited!5e0!3m2!1sen!2sin!4v1647411800653!5m2!1sen!2sin"
    width="100%" height="100%" frameBorder="0" allowFullScreen="" loading="lazy"/>
                {/*<iframe*/}
                {/*    src="https://www.google.com/maps/place/PRM+Roadways+private+limited/@12.9353528,79.908345,17z/data=!4m5!3m4!1s0x3a52ed79c99d3bd3:0x3b9328efe9d883ef!8m2!3d12.9349599!4d79.9093071"*/}
                {/*    ></iframe>*/}
                {/*<GoogleMapReact*/}
                {/*    bootstrapURLKeys={{ key: "" }}*/}
                {/*    defaultCenter={this.props.center}*/}
                {/*    defaultZoom={this.props.zoom}*/}
                {/*>*/}
                {/*    <AnyReactComponent*/}
                {/*        lat={59.955413}*/}
                {/*        lng={30.337844}*/}
                {/*        text="My Marker"*/}
                {/*    />*/}
                {/*</GoogleMapReact>*/}
            </div>
        );
    }
}

