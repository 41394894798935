/**
 * @generated SignedSource<<ed4e71f58da2f8572ff80323c61daf40>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type ContactusMutation$variables = {|
  email: string,
  message: string,
  mobileNumber: string,
  name: string,
|};
export type ContactusMutation$data = {|
  +contactForm: ?{|
    +ok: ?boolean,
    +message: ?string,
  |},
|};
export type ContactusMutation = {|
  variables: ContactusMutation$variables,
  response: ContactusMutation$data,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "message"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "mobileNumber"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "message",
        "variableName": "message"
      },
      {
        "kind": "Variable",
        "name": "mobileNumber",
        "variableName": "mobileNumber"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "concreteType": "ContactFormMutation",
    "kind": "LinkedField",
    "name": "contactForm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContactusMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContactusMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e7265f3cd477439137042aff7b9a6eab",
    "id": null,
    "metadata": {},
    "name": "ContactusMutation",
    "operationKind": "mutation",
    "text": "mutation ContactusMutation(\n  $email: String!\n  $message: String!\n  $mobileNumber: String!\n  $name: String!\n) {\n  contactForm(email: $email, message: $message, mobileNumber: $mobileNumber, name: $name) {\n    ok\n    message\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "d27cb73066ccc83e5f137d4fb13a8f50";

module.exports = ((node/*: any*/)/*: Mutation<
  ContactusMutation$variables,
  ContactusMutation$data,
>*/);
