import React from "react";
import "./StaggeredGrid.scss";
import * as R from 'ramda';

import subGallery1 from "./assets/images/subGallery1.png";
import subGallery2 from "./assets/images/subGallery2.png";
import subGallery3 from "./assets/images/subGallery3.png";
import subGallery4 from "./assets/images/subGallery4.png";
import subGallery5 from "./assets/images/subGallery5.png";
import subGallery6 from "./assets/images/subGallery6.png";

import {FleetImages,InfraImages,FacilityImages} from "./Pages/Gallery/gallery-data";
import {MDBCol, MDBRow} from "mdbreact";


const { map, is, contains, curry, forEachObjIndexed, __, prop, equals, pipe, find, ifElse, F, identity } = R
const cloneChildren = (children, props) => React.Children.map(children, child => <child.type {...child.props} {...props} />)
const Head = ({ children, ...props }) => cloneChildren(children, props)
const Content = ({ children, ...props }) => cloneChildren(children, props)

const easingFunctions = {
    linear: t => t,
    easeInQuad: t => t * t,
    easeOutQuad: t => t * (2 - t),
    easeInOutQuad: t => t < .5 ? 2 * t * t : - 1 + (4 - 2 * t) * t,
    easeInCubic: t => t * t * t,
    easeOutCubic: t => (--t) * t * t + 1,
    easeInOutCubic: t => t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
    easeInQuart: t => t * t * t * t,
    easeOutQuart: t => 1 - (--t) * t * t * t,
    easeInOutQuart: t => t < .5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t,
    easeInQuint: t => t * t * t * t * t,
    easeOutQuint: t => 1 + (--t) * t * t * t * t,
    easeInOutQuint: t => t < .5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t,
    easeOutElastic: t => .04 * t / (--t) * Math.sin(25 * t),
    easeInElastic: t => (.04 - .04 / t) * Math.sin(25 * t) + 1,
    easeInSin: t => 1 + Math.sin(Math.PI / 2 * t - Math.PI / 2),
    easeOutSin : t => Math.sin(Math.PI / 2 * t),
    easeInOutSin: t => (1 + Math.sin(Math.PI * t - Math.PI / 2)) / 2
}

const getElapsedTime = start => +new Date() - start
const calcProgressVal = (x1, x2, progress) => x1 + (x2 - x1) * progress

const getNewStyles = ({
                          el: [ el1, el2 ],
                          progress,
                          timingFunction
                      }) => {
    const progressFn = easingFunctions[timingFunction](progress)
    const x = calcProgressVal(el1.x, el2.x, progressFn)
    const y = calcProgressVal(el1.y, el2.y, progressFn)

    return {
        maxWidth: calcProgressVal(el1.width, el2.width, progressFn),
        height: calcProgressVal(el1.height, el2.height, progressFn),
        transform: `translate3d(${x}px, ${y}px, 0)`
    }
}

const setStyleToElement = curry((value, key, el) => el.style[key] = value)
const setStylesToElement = curry((styles, element) => forEachObjIndexed(setStyleToElement(__, __, element), styles))

const pickFromRect = rect => {
    const { width, height, top, left } = rect
    return { width, height, x: left, y: top }
}

const _findChildren = curry((component, children) => find(
    pipe(
        prop('type'),
        equals(component)
    )
)(children))
const findChildrenOr = curry((val, component, children) =>
    ifElse(
        is(Array),
        _findChildren(component),
        val
    )(children))
const findChildren = findChildrenOr(F)
const findChildrenOrIdentity = findChildrenOr(identity)

const getLastPositionStyles = ({ maxWidth, maxHeight }) => {
    const mW = maxWidth > window.innerWidth ? window.innerWidth : maxWidth
    const mH = maxHeight > window.innerHeight ? window.innerHeight : maxHeight

    return{
        width: mW,
        height: mH,
        x: window.innerWidth / 2 - mW / 2,
        y: window.innerHeight / 2 - mH / 2
    }
}

const states = {
    IDLE: 'IDLE',
    OPEN: 'OPEN',
    OPENED: 'OPENED',
    CLOSE: 'CLOSE',
    IMMEDIATELY_CLOSE: 'IMMEDIATELY_CLOSE'
}

const openState = [ states.OPEN, states.OPENED, states.CLOSE, states.IMMEDIATELY_CLOSE ]
const afterOpenState = [ states.OPENED, states.CLOSE ]
const closingState = [ states.CLOSE, states.IMMEDIATELY_CLOSE ]
const isActiveState = contains(__, openState)
const isAfterOpenState = contains(__, afterOpenState)
const isClosingState = contains(__, closingState)
const isOpenedState = equals(states.OPENED)

class Modal extends React.Component {
    static Head = Head
    static Content = Content

    static defaultProps = {
        timingFunction: 'easeInOutCubic'
    }

    state = {
        styles: {},
        state: states.IDLE
    }

    constructor(props) {
        super(props)

        this._open = this._open.bind(this)
        this._close = this._close.bind(this)
        this.open = this.open.bind(this)
        this.close = this.close.bind(this)
        this.createProps = this.createProps.bind(this)
        this.processCallback = this.processCallback.bind(this)
        this.closeDoneCallback = this.closeDoneCallback.bind(this)
        this.openDoneCallback = this.openDoneCallback.bind(this)

        this.clone = React.createRef()
        this.content = React.createRef()
    }

    animate({ from, to, loop, processCallback, doneCallBack }) {
        const elapsed = getElapsedTime(this.state.startDate)
        const progress = Math.min(elapsed / this.props.ms, 1)
        const styles = this.contentStyles

        const newStyles = getNewStyles({
            el: [ from, to ],
            progress,
            timingFunction: this.props.timingFunction
        })

        if (progress < 1) {
            if (is(Function, processCallback)) {
                processCallback({
                    styles,
                    newStyles
                })
            }

            requestAnimationFrame(loop)
        } else {
            if (is(Function, doneCallBack)) {
                doneCallBack({
                    styles,
                    newStyles
                })
            }
        }
    }

    processCallback({ styles, newStyles }) {
        setStylesToElement({
            ...newStyles,
            maxWidth: `${newStyles.maxWidth}px`,
            height: `${newStyles.height}px`
        }, this.content.current)

        this.contentStyles = {
            ...styles,
            ...newStyles
        }
    }

    openDoneCallback({ styles, newStyles }) {
        this.setState({
            state: states.OPENED,
            styles: {
                ...styles,
                ...newStyles,
                left: '50%',
                top: '50%',
                transform: 'translate3d(-50%, -50%, 0)'
            }
        })
    }

    closeDoneCallback() {
        this.setState({
            state: states.IDLE,
            styles: {},
            bodyStyles: {}
        })
    }

    _open() {
        const { rect, state } = this.state

        if (state !== states.OPEN) return

        this.animate({
            from: rect,
            to: getLastPositionStyles(this.props),
            loop: this._open,
            processCallback: this.processCallback,
            doneCallBack: this.openDoneCallback
        })
    }

    _close() {
        const { cloneRect, rect } = this.state

        this.animate({
            from: rect,
            to: cloneRect,
            loop: this._close,
            processCallback: this.processCallback,
            doneCallBack: this.closeDoneCallback
        })
    }

    setStartData(state) {
        const startDate = +new Date()
        const cloneRect = pickFromRect(this.clone.current.getBoundingClientRect())
        const rect = pickFromRect(this.content.current.getBoundingClientRect())

        const styles = {
            maxWidth: rect.width,
            height: rect.height,
            top: 0,
            left: 0,
            transform: `translate3d(${rect.x}px, ${rect.y}px, 0)`
        }

        this.contentStyles = styles
        this.setState({
            ...state,
            cloneRect,
            startDate,
            rect,
            styles
        })
    }

    open() {
        if (this.state.state !== states.IDLE) return

        this.setStartData({ state: states.OPEN })

        requestAnimationFrame(this._open)
    }

    close() {
        if (this.state.state === states.IDLE) return

        this.setStartData({
            state: this.state.state === states.OPEN
                ? states.IMMEDIATELY_CLOSE
                : states.CLOSE
        })

        requestAnimationFrame(this._close)
    }

    createProps(Component, props) {
        return {
            ...Component.props,
            modal: {
                ...props,
                isOpen: isActiveState(this.state.state),
                close: this.close
            }
        }
    }

    renderClone() {
        const Head = findChildrenOrIdentity(Modal.Head, this.props.children)

        return Head && isActiveState(this.state.state)
            ? <Head.type {...this.createProps(Head)}/>
            : null
    }

    renderHead() {
        const Head = findChildrenOrIdentity(Modal.Head, this.props.children)

        return Head
            ? <Head.type {...this.createProps(Head, { original: true })}/>
            : null
    }

    renderContent() {
        const Content = findChildren(Modal.Content, this.props.children)

        return Content && isOpenedState(this.state.state)
            ? <Content.type {...this.createProps(Content)}/>
            : null
    }

    getBackgroundStyle() {
        return {
            transition: `opacity ${this.props.ms}ms ease-in-out`
        }
    }

    getContentStyle() {
        return {
            ...this.state.styles,
            transition: `box-shadow ${this.props.ms}ms ease-in-out`
        }
    }

    getBodyStyles() {
        return {
            ...this.state.bodyStyles
        }
    }

    getContaninerClassNames() {
        const { state } = this.state
        return [
            'transform-modal__container',
            isActiveState(state) ? 'transform-modal__container--open' : '',
            isClosingState(state) ? 'transform-modal__container--closing' : ''
        ].join(' ')
    }

    render() {
        return (
            <div className='transform-modal' {...this.props}>
                <div ref={this.clone}>
                    {this.renderClone()}
                </div>
                <div className={this.getContaninerClassNames()}>
                    <div
                        className='transform-modal__background'
                        onClick={this.close}
                        style={this.getBackgroundStyle()}
                    />
                    <div
                        className='transform-modal__content'
                        style={this.getContentStyle()}
                        onClick={this.open}
                        ref={this.content}
                    >
                        {this.renderHead()}
                        {this.renderContent()}
                    </div>
                </div>
            </div>
        )
    }
}

// const images = [
//     {
{/*        bg: subGallery1,*/}
//         title: 'Winner #1',
//         text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna.'
//     },
//     {
//         bg: subGallery2,
//         title: 'Winner #1',
//         text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna.'
//     },
//     {
//         bg: subGallery3,
//         title: 'Winner #1',
//         text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna.'
//     },
//     {
//         bg: subGallery4,
//         title: 'Winner #1',
//         text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna.'
//     },
//     {
//         bg: subGallery5,
//         title: 'Winner #1',
//         text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna.'
//     },
//     {
//         bg: subGallery6,
//         title: 'Winner #1',
//         text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar, hendrerit id, lorem. Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut libero venenatis faucibus. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt. Duis leo. Sed fringilla mauris sit amet nibh. Donec sodales sagittis magna.'
//     },
// ]

const ImageHead = ({ title, bg, modal: { isOpen, original, close } }) => (
    <div className={`image ${isOpen & original ? 'image--active' : 'image-in-active'}`} style={{ }}>
        <div className='image__bg' style={{ backgroundImage: `url(${bg})` }}/>
        <img src={bg} alt={"bg"} className={"img-fluid inline-image"}/>
        <div className='image__content'>
            {
                isOpen & original
                    ? <button className='image__close' onClick={close}>&#10006;</button>
                    : null
            }
            {/*<h2>{title}</h2>*/}
        </div>
    </div>
)



const ImageModal = ({item}) => {
   const viewportToPixels = (value) => {
        var parts = value.match(/([0-9\.]+)(vh|vw)/)
        var q = Number(parts[1])
        var side = window[['innerHeight', 'innerWidth'][['vh', 'vw'].indexOf(parts[2])]]
        return side * (q/100)
    }
    return(
        <div className='grid__item'>
            <Modal
                // maxWidth={viewportToPixels("95vw")}
                // maxHeight={500}
                ms={500}
                // style={{ marginBottom: '15px' }}
            >
                <Modal.Head>
                    <ImageHead {...item}/>
                </Modal.Head>

            </Modal>
        </div>
    )
}



export const StaggeredGrid = (props) => {
    const {currentPage} = props;
    let images = FleetImages;
    if(props.gallery === "fleet"){
        images = FleetImages;
    }
    if(props.gallery === "infrastructure"){
        images = InfraImages;
    }
    if(props.gallery === "facilities"){
        images = FacilityImages;
    }
    return(
        <div className={"staggered-grid-component"}>
            <div className={"container large-device-gallery"}>
                {
                    currentPage === 1 &&
                    <div className={""}>
                        <MDBRow>
                            <MDBCol size={"12"} sm={"5"}><ImageModal item={images[0]} key={0}/></MDBCol>
                            <MDBCol size={"12"} sm={"7"}>
                                <MDBRow>
                                    <MDBCol size={"12"} sm={"6"}><ImageModal item={images[1]} key={1}/></MDBCol>
                                    <MDBCol size={"12"} sm={"6"}><ImageModal item={images[2]} key={2}/></MDBCol>
                                </MDBRow>
                                <div className={"divider"}/>
                                <MDBRow>
                                    <MDBCol size={"12"} sm={"6"}><ImageModal item={images[3]} key={3}/></MDBCol>
                                    <MDBCol size={"12"} sm={"6"}><ImageModal item={images[4]} key={4}/></MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                        <div className={"divider"}/>
                        <MDBRow>
                            <MDBCol size={"12"} sm={"12"}><ImageModal item={images[5]} key={5}/></MDBCol>
                        </MDBRow>
                        {/*<div className={"gallery-row"}>*/}
                        {/*    <div className="half-width-column">*/}
                        {/*        <ImageModal item={images[0]} key={0}/>*/}
                        {/*    </div>*/}
                        {/*    <div className="half-width-column">*/}
                        {/*        <div className={"gallery-row"}>*/}
                        {/*            <div className="half-width-column">*/}
                        {/*                <ImageModal item={images[1]} key={1}/>*/}
                        {/*            </div>*/}
                        {/*            <div className="half-width-column">*/}
                        {/*                <ImageModal item={images[2]} key={2}/>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className={"divider"}/>*/}
                        {/*        <div className={"gallery-row"}>*/}
                        {/*            <div className="half-width-column">*/}
                        {/*                <ImageModal item={images[3]} key={3}/>*/}
                        {/*            </div>*/}
                        {/*            <div className="half-width-column">*/}
                        {/*                <ImageModal item={images[4]} key={4}/>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className={"divider"}/>*/}
                        {/*<div className={"gallery-row"}>*/}
                        {/*    <div className="full-width-column">*/}
                        {/*        <ImageModal item={images[5]} key={5}/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                }
                {
                    currentPage === 2 && props.gallery === "fleet" &&
                    <div className={""}>
                        <MDBRow>
                            <MDBCol size={"12"} sm={"5"}><ImageModal item={images[6]} key={6}/></MDBCol>
                            <MDBCol size={"12"} sm={"7"}>
                                <MDBRow>
                                    <MDBCol size={"12"} sm={"6"}><ImageModal item={images[7]} key={7}/></MDBCol>
                                    <MDBCol size={"12"} sm={"6"}><ImageModal item={images[8]} key={8}/></MDBCol>
                                </MDBRow>
                                <div className={"divider"}/>
                                <MDBRow>
                                    <MDBCol size={"12"} sm={"6"}><ImageModal item={images[9]} key={9}/></MDBCol>
                                    <MDBCol size={"12"} sm={"6"}><ImageModal item={images[10]} key={10}/></MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                        <div className={"divider"}/>
                        <MDBRow>
                            <MDBCol size={"12"} sm={"12"}><ImageModal item={images[11]} key={11}/></MDBCol>
                        </MDBRow>
                        {/*<div className={"gallery-row"}>*/}
                        {/*    <div className="half-width-column">*/}
                        {/*        <ImageModal item={images[6]} key={6}/>*/}
                        {/*    </div>*/}
                        {/*    <div className="half-width-column">*/}
                        {/*        <div className={"gallery-row"}>*/}
                        {/*            <div className="half-width-column">*/}
                        {/*                <ImageModal item={images[7]} key={7}/>*/}
                        {/*            </div>*/}
                        {/*            <div className="half-width-column">*/}
                        {/*                <ImageModal item={images[8]} key={8}/>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className={"divider"}/>*/}
                        {/*        <div className={"gallery-row"}>*/}
                        {/*            <div className="half-width-column">*/}
                        {/*                <ImageModal item={images[9]} key={9}/>*/}
                        {/*            </div>*/}
                        {/*            <div className="half-width-column">*/}
                        {/*                <ImageModal item={images[10]} key={10}/>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className={"divider"}/>*/}
                        {/*<div className={"gallery-row"}>*/}
                        {/*    <div className="full-width-column">*/}
                        {/*        <ImageModal item={images[11]} key={11}/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                }
                {
                    currentPage === 2 && props.gallery === "infrastructure" &&
                    <div className={""}>
                        <MDBRow>
                            <MDBCol size={"12"} sm={"6"}>
                                <ImageModal item={images[6]} key={6}/>
                            </MDBCol>
                            <MDBCol size={"12"} sm={"6"}><ImageModal item={images[7]} key={7}/></MDBCol>
                        </MDBRow>
                        {/*<div className={"gallery-row"}>*/}
                        {/*    <div className="half-width-column">*/}
                        {/*        <ImageModal item={images[6]} key={6}/>*/}
                        {/*    </div>*/}
                        {/*    <div className="half-width-column">*/}
                        {/*        <ImageModal item={images[7]} key={7}/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                }
                {
                    currentPage === 2 && props.gallery === "facilities" &&
                    <div className={""}>
                        <MDBRow>
                            <MDBCol size={"12"} sm={"12"}><ImageModal item={images[6]} key={6}/></MDBCol>
                        </MDBRow>
                        {/*<div className={"gallery-row"}>*/}
                        {/*    <div className="full-width-column">*/}
                        {/*        <ImageModal item={images[6]} key={6}/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                }

            </div>

            <div className={"small-device-gallery"}>
                <div className={"container"}>
                    {
                        currentPage === 1 &&
                        <div className="row">
                            <div className="col-12 p-0">
                                <ImageModal item={images[0]} key={0}/>
                            </div>
                            <div className={"col-12"}>
                                <div className={"divider"}/>
                            </div>

                            <div className="col-12 p-0">
                                <div className="row">
                                    <div className="col-6">
                                        <ImageModal item={images[1]} key={1}/>
                                    </div>
                                    <div className="col-6">
                                        <ImageModal item={images[2]} key={2}/>
                                    </div>
                                    <div className={"col-12"}>
                                        <div className={"divider"}/>
                                    </div>
                                    <div className="col-6">
                                        <ImageModal item={images[3]} key={3}/>
                                    </div>
                                    <div className="col-6">
                                        <ImageModal item={images[4]} key={4}/>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-12"}>
                                <div className={"divider"}/>
                            </div>
                            <div className="col-12 p-0">
                                <ImageModal item={images[5]} key={5}/>
                            </div>
                        </div>
                    }
                    {
                        currentPage === 2 && props.gallery === "fleet" &&
                        <div className="row">
                            <div className="col-12 p-0">
                                <ImageModal item={images[6]} key={6}/>
                            </div>
                            <div className={"col-12"}>
                                <div className={"divider"}/>
                            </div>

                            <div className="col-12 p-0">
                                <div className="row">
                                    <div className="col-6">
                                        <ImageModal item={images[7]} key={7}/>
                                    </div>
                                    <div className="col-6">
                                        <ImageModal item={images[8]} key={8}/>
                                    </div>
                                    <div className={"col-12"}>
                                        <div className={"divider"}/>
                                    </div>
                                    <div className="col-6">
                                        <ImageModal item={images[9]} key={9}/>
                                    </div>
                                    <div className="col-6">
                                        <ImageModal item={images[10]} key={10}/>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-12"}>
                                <div className={"divider"}/>
                            </div>
                            <div className="col-12 p-0">
                                <ImageModal item={images[11]} key={11}/>
                            </div>
                        </div>
                    }
                    {
                        currentPage === 2 && props.gallery === "infrastructure" &&
                        <div className="row">
                            <div className="col-6">
                                <ImageModal item={images[6]} key={6}/>
                            </div>
                            <div className="col-6">
                                <ImageModal item={images[7]} key={7}/>
                            </div>
                        </div>
                    }
                    {
                        currentPage === 2 && props.gallery === "facilities" &&
                        <div className="row">
                            <div className="col-12">
                                <ImageModal item={images[6]} key={6}/>
                            </div>
                        </div>
                    }
                </div>
            </div>

        </div>
    )
}


