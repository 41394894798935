import React, {useEffect} from "react";
import "./HeroBanner.scss";
import {MDBCol, MDBContainer, MDBRow} from "mdbreact";
import AOS from 'aos';

import HeroBus from "../../assets/images/hero-bus.png";
import ActiveHeroBus from "../../assets/images/hero-bus-active.png";

import BusCount from "../../assets/images/bus-count.png";
import TruckCount from "../../assets/images/truck-count.png";
import CabCount from "../../assets/images/cab-count.png";
import TravelCount from "../../assets/images/tempo-travel-count.png";


export const HeroBanner = (props) => {
    useEffect(() => {
        AOS.init();
    });
    return(
        <div className={"hero-banner-component"}>
            <MDBContainer className={"banner-container"}>
                <MDBRow>
                    <MDBCol md="6" size={"12"}>
                        <div className={"banner-content-section"}
                             data-aos="fade-up"
                             data-aos-duration="500"
                             data-aos-easing="linear"
                        >


                            <p className={"banner-content"}>Feel The Best Travel</p>
                            <p className={"banner-content"}>Experience with our best</p>
                            <p className={"banner-content"}>in class Vehicles for</p>

                            <p className={"banner-content"}><span>Safe and Splendour
                            Commuting.</span></p>
                        </div>
                    </MDBCol>
                    <MDBCol md="6" size={"12"}>
                        <div className={"image-section large"}
                             data-aos="fade-in"
                             data-aos-duration="1500"
                             data-aos-easing="linear">
                            <img src={TruckCount} alt={"fleet"} className={"fleet img-fluid"}/>
                            <img src={TravelCount} alt={"pasengers_daily"} className={"travel_count img-fluid"}/>

                            <img src={HeroBus} alt={"HeroBus"} className={"img-fluid hero-bus"}
                            />
                            <img src={BusCount} alt={"km_daily"} className={"km_daily img-fluid"}/>
                            <img src={CabCount} alt={"pasengers_daily"} className={"pasengers_daily img-fluid"}/>
                        </div>
                        <div className={"image-section small"}
                             data-aos="fade-in"
                             data-aos-duration="1500"
                             data-aos-easing="linear">

                            <img src={ActiveHeroBus} alt={"HeroBus"} className={"img-fluid hero-bus"}
                            />
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div>
    );
}
