import React, {useEffect} from "react";
import ReactDOM from 'react-dom';

import "./FAQ.scss";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import GoToTop from "../../GoToTop";

const panels = [
    {
        label: 'Who are all your customers?',
        content: 'All major MNCs are our customers',
    },
    {
        label: 'What is the highest fleets to the client?',
        content: 'We are capable of providing any volumes with minimum turnaround time',
    },
    {
        label: 'What are the value-added services you have?',
        content: 'Latest technology vehicles, Own fuel stations, Maintenance Facilities and Parking areas',
    },
    {
        label: 'What is your Unique Selling Point (USP)',
        content: 'To cater safe, cost effective, reliable transportation',
    },
    {
        label: 'Are you providing 24x7, services?',
        content: 'Yes, abundance experience in Shift operations at continuous process industries',
    },
    {
        label: 'How is financial position?',
        content: 'We have study and strong financial positions',
    },
    {
        label: 'How good you are during Emergency situations?',
        content: 'Ready to meet any type of situations with well experienced Management and operation team',
    },
    {
        label: 'What is your TAT (Turn-around Time) to start up the services?',
        content: 'We are capable of meeting the timeline before agreed timelines mentioned in the agreement',
    },
    {
        label: 'What is your price?',
        content: 'Reasonably best in the market',
    },
    {
        label: 'How the responsibility is ensured?',
        content: 'We have Centralized Command Centre – 24/7 and Toll-Free number',
    },
];

class FAQ extends React.Component {
    // constructor(props) {
    //     super(props)
    //     this.myRef = React.createRef()   // Create a ref object
    // }


    componentDidMount() {
        window.scrollTo(0, 0)
    }

render() {


        return (
            <div   className={"faq-component"}>
                {/*<Header page={""}/>*/}
                <div>
                    <p className={"title"}>FAQ</p>
                </div>
                <Accordion panels={ panels }/>
                <br/>
                <br/>
                <Footer/>
            </div>
        );
    }
}

export default FAQ;

class Accordion extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 0
        };

        this.activateTab = this.activateTab.bind(this);
    }

    activateTab(index) {
        this.setState(prev => ({
            activeTab: prev.activeTab === index ? -1 : index
        }));
    }

    render() {
        const { panels } = this.props;
        const { activeTab } = this.state;
        return (
            <div className='accordion' role='tablist'>
                {panels.map((panel, index) =>
                    <Panel
                        key={ index }
                        activeTab={ activeTab }
                        index={ index }
                        { ...panel }
                        activateTab={ this.activateTab.bind(null, index) }
                    />
                )}
            </div>
        );
    }
}
class Panel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            height: 0
        };
    }

    componentDidMount() {
        window.setTimeout(() => {
            const el = ReactDOM.findDOMNode(this);
            const height = el.querySelector('.panel__inner').scrollHeight;
            this.setState({
                height
            });
        }, 333);
    }

    render () {
        const { label, content, activeTab, index, activateTab } = this.props;
        const { height } = this.state;
        const isActive = activeTab === index;
        const innerStyle = {
            height:  `${isActive ? height : 0}px`
        }

        return (
            <div className='panel'
                 role='tabpanel'
                 aria-expanded={ isActive }>
                <button className='panel__label'
                        role='tab'
                        onClick={ activateTab }>
                    { label }
                </button>
                <div className='panel__inner'
                     style={ innerStyle }
                     aria-hidden={ !isActive }>
                    <p className='panel__content'>
                        { content }
                    </p>
                </div>
            </div>
        );
    }
}




