import React, {useState,useEffect,useRef} from "react";
import "./Header.scss";

import Logo from "../../assets/images/Asset 22iconsfXS.svg";
import {withRouter} from "react-router-dom";

 const Header = (props) => {
    const [isActive, setActive] = useState(false);
    const [headerHeight, setHeaderHeight] = useState(0);
    const headerElement = useRef(null);
    const [activePage,setActivePage] = useState('');
    useEffect(() => {
        let height = headerElement.current?headerElement.current.clientHeight:0;
        setHeaderHeight(height);
        window.addEventListener('resize', setContentHeight, true);
    },[]);
     useEffect(() => {
         setActivePage(props.page); 
     });

    const setContentHeight = () => {
        let height = headerElement.current?headerElement.current.clientHeight:0;
        setHeaderHeight(height);
    }

    const toggleMenu = () => {
        setActive(!isActive);
        if(!isActive){
            document.body.style = "overflow:hidden";
        } else {
            document.body.style = "overflow:auto";
        }
    }
    const handlePageChange = (page) => {
        setActivePage(page);
        setActive(false);
        props.history.push("/"+page);
    }
    return(
        <div className={"header-component"}>
            <nav className="Navigation" ref={headerElement}>
                <img src={Logo} alt={"PRM ROADWAYS PRIVATE LTD"} className={"img-fluid Navigation-smallLogo"} onClick={()=>{
                    props.history.push("/");
                }}/>
                <div className={`Overlay ${isActive?'Overlay--on':''}`} style={{top:"100px"}}>
                    <ul className="Navigation-list">
                        <li className={activePage==="/home" || activePage==="/" ?"active":""}
                            onClick={()=>handlePageChange("home")}
                        >
                            <a>Home</a>
                        </li>
                        <li className={activePage==="/services"?"active":""}
                            onClick={()=>handlePageChange("services")}
                        >
                            <a>Service</a>
                        </li>
                        <li className={activePage==="/aboutus"?"active":""}
                            onClick={()=>handlePageChange("aboutus")}
                        >
                            <a>About Us</a>
                        </li>
                        <li className={activePage==="/gallery" || activePage === "/sub-gallery"?"active":""}
                            onClick={()=>handlePageChange("gallery")}
                        >
                            <a>Gallery</a>
                        </li>
                        <li className={activePage==="/contactus"?"active":""}
                            onClick={()=>handlePageChange("contactus")}
                        >
                            <a>Contact Us</a>
                        </li>
                    </ul>
                </div>
                <section className="Navigation--collapsed" onClick={toggleMenu}>
                    <div className={`BurgerMenu ${isActive?'BurgerMenu--on':''}`}/>
                </section>
            </nav>
        </div>
    );
}

export default withRouter(Header);

