import "./mainGallery.scss";
import Header from "../../Components/Header/Header";
import React, {useEffect} from "react";
import Footer from "../../Components/Footer/Footer";
import {withRouter} from "react-router-dom";
import AOS from 'aos';

import Gallery1 from "../../assets/images/Gallery1.png"
import GoToTop from "../../GoToTop";

const MainGallery = (props) => {
    const goToSubCategory = (gallery) => {
        props.history.push("/sub-gallery/"+gallery);
    }
    GoToTop()
    useEffect(() => {
        AOS.init();
    },[]);

  return (
      <div>
          {/*<Header page={"gallery"}/>*/}
          <div className="mainGallery row mx-0">
              <div className="Gallery-1 col-12 px-0 para-center"
                   data-aos="fade-up"
                   data-aos-duration="1000"
                   data-aos-easing="linear"
                   data-aos-once="true"
                   onClick={()=>goToSubCategory("fleet")}>
                  <div className="parent-para">
                      <div className="para">Fleets</div>
                  </div>
              </div>
              <div className="Gallery-2 col-12 px-0 para-center"
                   data-aos="fade-up"
                   data-aos-duration="1000"
                   data-aos-easing="linear"
                   data-aos-once="true"
                   onClick={()=>goToSubCategory("infrastructure")}>
                  <div className="parent-para">
                      <div className="para">Office Infrastructure</div>
                  </div>
              </div>
              <div className="Gallery-3 col-12 px-0 para-center"
                   data-aos="fade-up"
                   data-aos-duration="1000"
                   data-aos-easing="linear"
                   data-aos-once="true"
                   onClick={()=>goToSubCategory("facilities")}>
                  <div className="parent-para">
                      <div className="para">Facilities</div>
                  </div>
              </div>
          </div>
          <br/>
          <br/>
          <Footer/>
      </div>

  );
};

export default withRouter(MainGallery);
