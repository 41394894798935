import React, {useRef, useEffect, useState} from "react";
import "./Home.scss";
import Header from "../../Components/Header/Header";
import {HeroBanner} from "../../Components/HeroBanner/HeroBanner";
import Footer from "../../Components/Footer/Footer";
import {MDBBox, MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBRow} from "mdbreact";
import Slider from "react-slick";
import {Carousel as SlickCarousel} from '3d-react-carousal';
import {gsap} from "gsap";
import {MotionPathPlugin, ScrollTrigger} from "gsap/all";
import AOS from 'aos';

import TeamIcon from "../../assets/images/team.png";
import TeamActiveIcon from "../../assets/images/team-active.png";
import CustomerCareIcon from "../../assets/images/customer-care.png";
import CustomerCareActiveIcon from "../../assets/images/customer-care-active.png";
import SafetyIcon from "../../assets/images/safety.png";
import ServiceIcon from "../../assets/images/service-icon.png";
import OrganizationIcon from "../../assets/images/organization-icon.png";
import TransportIcon from "../../assets/images/transport-icon.png";

import SafetyActiveIcon from "../../assets/images/safety-active.png";
import Car from "../../assets/images/car.png";
import Lorry from "../../assets/images/lorry.png";
import AboutUsImg from "../../assets/images/about-us-img.png";
import AboutUsBG from "../../assets/images/about-us-bg.png";
import OilService from "../../assets/images/oil-service.png";
import MaintananceService from "../../assets/images/maintenance-service.png";
import PaintShop from "../../assets/images/spray-paint.png";
import WaterWash from "../../assets/images/car-wash.png";
import CraneLifting from "../../assets/images/crane-lifting.png";
import WhealMaintanance from "../../assets/images/wheal-maintanance.png";
import Quote from "../../assets/images/quote.png";
import Star from "../../assets/images/star.png";
import Policy from "../../assets/images/policy.png";
import DrivenByValues from "../../assets/images/driven-by-values.png";
import RevenueStratergy from "../../assets/images/revenue-stratergy.png";
import PassionateTowardsBusiness from "../../assets/images/passionate-towards-business.png";
import Award1 from "../../assets/images/award-1.png";
import Award2 from "../../assets/images/award-2.png";
import Award3 from "../../assets/images/award-3.png";
import ArrowLeft from "../../assets/images/arrow-left.png";
import ArrowRight from "../../assets/images/arrow-right.png";
import RightArrowIcon from "../../assets/images/right-arrow-icon.svg";
import LeftArrowIcon from "../../assets/images/left-arrow-icon.svg";

import BusServiceImg from "../../assets/images/bus-service-img.png";
import GoodsServiceImg from "../../assets/images/goods-service-img.png";
import TourServiceImg from "../../assets/images/tour-service-img.png";
import Management1 from "../../assets/images/management-1.png";
import Management2 from "../../assets/images/management-2.png";
import Management3 from "../../assets/images/management-3.png";
import Management4 from "../../assets/images/policy.png";


import Carousel from 'react-multi-carousel';
import GoToTop from "../../GoToTop";
import {Link} from "react-router-dom";

gsap.registerPlugin(MotionPathPlugin);
gsap.registerPlugin(ScrollTrigger);

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
};


export const Home = (props) => {
    useEffect(() => {
        AOS.init();
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
    });
    GoToTop()
    return (
        <div className={"home-component"}>

            <HeroBanner/>
            <ReasonsForBest/>
            <ServicesAndAboutUs/>
            {/*<Services/>*/}
            {/*<AboutUs/>*/}
            <Facilities/>
            <TestimonialsAndManagement/>
            <AwardsAndRecognition/>
            <Footer/>
        </div>
    );
}

const ReasonsForBest = (props) => {

    const busRef = useRef();
    const pathRef = useRef();
    var tween, requestId, startY, finishDistance;

    useEffect(() => {

        const offsetFromTop = window.innerWidth * 0.5;
        const pathBB = pathRef.current.getBoundingClientRect();
        startY = pathBB.bottom - window.innerWidth + (offsetFromTop);
        finishDistance = startY + pathBB.width + (offsetFromTop);

        tween = gsap.to(busRef.current, {
            duration: 5,
            paused: true,
            ease: "none",
            immediateRender: true,
            motionPath: {
                path: pathRef.current,
                align: pathRef.current,
                autoRotate: true,
                alignOrigin: [0.5, 0.5],
                start: 0,
                end: 1
            }
        });

        document.addEventListener("scroll", function () {
            if (!requestId) {
                requestId = requestAnimationFrame(update);
            }


            let next_section = document.getElementById("services-and-aboutus");
            if(busRef.current){
                if(next_section && next_section.classList.contains('active')) {
                    busRef.current.style.opacity = 0;
                } else {
                    busRef.current.style.opacity = 1;
                }
            }
        });
        update();



    });

    const update = () => {
        tween.progress((window.scrollY * 3 - startY) / finishDistance);
        requestId = null;
    }

    return (
        <div className={"reasons-for-best-component"} id={"reasons-for-best-component"}>
            <div ref={busRef} id={"busref"}>
                <svg width="209" height="62" viewBox="0 0 209 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9.7176 51.4176C10.0657 56.3888 13.6083 58.6425 16.9251 58.6425H193.061V61.6052C193.061 61.7099 193.103 61.8103 193.177 61.8844C193.251 61.9584 193.351 62 193.455 62C193.56 62 193.66 61.9584 193.734 61.8844C193.808 61.8103 193.849 61.7099 193.849 61.6052V60.1066H195.506C196.005 60.1385 196.501 60.0149 196.927 59.7528C197.354 59.4906 197.688 59.1028 197.885 58.6425H198.138C201.443 58.6425 205.586 53.5486 207.113 48.7653C207.113 48.7653 208.643 45.3924 208.643 34.7678C208.643 24.1432 207.113 21.4181 207.113 21.4181C206.057 17.409 201.294 11.5371 198.138 11.5371H197.885C197.688 11.0768 197.354 10.6889 196.927 10.4268C196.501 10.1646 196.005 10.041 195.506 10.073H193.849V8.5705C193.849 8.4658 193.808 8.36538 193.734 8.29135C193.66 8.21731 193.56 8.17572 193.455 8.17572C193.351 8.17572 193.251 8.21731 193.177 8.29135C193.103 8.36538 193.061 8.4658 193.061 8.5705V11.5371H16.9251C13.7651 11.5371 10.0657 13.1775 9.7176 18.7543C9.7176 18.7543 9.57605 24.1202 9.57605 34.764C9.57605 45.4077 9.7176 51.4176 9.7176 51.4176Z"
                        fill="#B7C0C4"/>
                    <path
                        d="M0.359932 43.2421C0.708065 48.2248 4.26208 50.4632 7.56743 50.4632H188.78C192.086 50.4632 196.229 45.3732 197.755 40.586C197.755 40.586 199.285 37.2131 199.285 26.5885C199.285 15.9639 197.755 13.2388 197.755 13.2388C196.699 9.2335 191.936 3.36162 188.78 3.36162H7.56743C4.42658 3.36162 0.708065 5.00207 0.359932 10.5827C0.359932 10.5827 0.218384 15.9486 0.218384 26.5885C0.218384 37.2284 0.359932 43.2421 0.359932 43.2421Z"
                        fill="#005B8D"/>
                    <path
                        d="M189.718 5.45821C191.302 5.45821 195.514 10.391 196.447 13.7333C196.466 13.8099 197.882 16.4162 197.882 26.5809C197.882 36.8184 196.447 40.0878 196.428 40.1452C194.653 45.7105 190.49 48.3666 189.718 48.3666H6.59597C5.83084 48.3666 1.87131 48.428 1.68385 42.7899C1.68385 42.7439 1.62646 36.8184 1.62646 26.5809C1.62646 16.4201 1.6609 11.1538 1.6609 11.1001C1.69533 7.35929 3.71144 5.79166 6.59597 5.45821H189.718Z"
                        fill="#0063A6"/>
                    <path
                        d="M189.717 6.73869C190.762 6.73869 194.438 11.2844 195.215 14.0785C195.41 14.6995 196.603 17.187 196.603 26.5812C196.603 36.5465 195.222 39.7124 195.207 39.7699C193.979 43.6027 191.542 47.0868 189.717 47.0868H6.59538C5.13781 47.0868 2.93807 45.5843 2.89981 42.3992V26.5812C2.89599 16.75 2.87303 11.5642 2.87303 11.4991C2.84625 8.68193 5.16841 6.66587 6.59538 6.72719L189.717 6.73869Z"
                        fill="#0075B3"/>
                    <path d="M182.732 43.9823C182.732 43.9823 184.308 25.9681 182.732 10.1615H177.376V43.9823H182.732Z"
                          fill="url(#paint0_linear_1097_44670)"/>
                    <path
                        d="M183.543 43.8439L189.664 45.1854C190.341 45.0206 192.273 42.7516 193.402 39.2101L193.429 39.1219L193.455 39.0529C193.585 38.7233 194.714 35.5804 194.714 26.5771C194.714 21.0731 194.308 17.1483 193.505 14.91C193.467 14.8065 193.436 14.7183 193.398 14.588C192.847 12.6141 190.379 9.40988 189.354 8.63948L183.543 9.96564C183.543 9.96564 184.343 13.6681 184.343 26.83C184.343 39.992 183.543 43.8439 183.543 43.8439Z"
                        fill="#072129"/>
                    <path
                        d="M188.749 10.6019C187.912 13.6413 187.483 18.9766 187.483 26.4583C187.483 38.6965 188.669 42.9662 188.803 43.4147L188.83 43.5066L188.86 43.6255C188.987 44.1352 189.121 44.6297 189.266 45.1011L189.648 45.1854C190.326 45.0206 192.258 42.7516 193.386 39.2101L193.413 39.1219L193.44 39.0529C193.57 38.7233 194.698 35.5804 194.698 26.5771C194.698 21.0731 194.293 17.1483 193.489 14.91C193.451 14.8065 193.421 14.7183 193.382 14.588C192.831 12.6141 190.364 9.40988 189.339 8.63948C189.147 9.13834 188.992 9.65079 188.876 10.1726C188.822 10.3489 188.792 10.4562 188.749 10.6019Z"
                        fill="#25333A"/>
                    <path
                        d="M183.543 43.8441L185.903 44.3615C186.087 43.1235 186.646 38.4015 186.646 26.8379C186.646 15.2743 186.079 10.6136 185.9 9.43689L183.543 9.96582C183.543 9.96582 184.343 13.6683 184.343 26.8302C184.343 39.9921 183.543 43.8441 183.543 43.8441Z"
                        fill="#D5EEF2"/>
                    <path d="M187.614 46.3966H8.43982V45.4537H181.837L187.614 46.3966Z" fill="#25333A"/>
                    <path d="M163.018 8.36758H181.836L187.613 7.42854H163.018V8.36758Z" fill="#25333A"/>
                    <path d="M141.335 7.42854H48.3871V8.37142H141.335V7.42854Z" fill="#25333A"/>
                    <path d="M26.7072 7.42854H8.43982V8.37142H26.7072V7.42854Z" fill="#25333A"/>
                    <path
                        d="M117.401 20.6322H97.5462C96.9821 20.6322 96.5248 21.0904 96.5248 21.6556V32.169C96.5248 32.7342 96.9821 33.1924 97.5462 33.1924H117.401C117.965 33.1924 118.423 32.7342 118.423 32.169V21.6556C118.423 21.0904 117.965 20.6322 117.401 20.6322Z"
                        fill="#0063A6"/>
                    <path d="M97.4279 21.5293V32.2842H114.425V21.5293H97.4279Z" fill="#0075B3"/>
                    <path d="M97.4279 23.0588V30.7551H112.619V23.0588H97.4279Z" fill="url(#paint1_linear_1097_44670)"/>
                    <path
                        d="M116.181 32.2807H117.788V21.5488H116.181C116.027 21.5498 115.88 21.6115 115.771 21.7205C115.662 21.8296 115.601 21.9772 115.6 22.1314V31.7134C115.605 31.8649 115.668 32.0087 115.776 32.1144C115.885 32.2202 116.03 32.2798 116.181 32.2807Z"
                        fill="#072129"/>
                    <path
                        d="M78.8583 20.6322H59.0033C58.4391 20.6322 57.9818 21.0904 57.9818 21.6556V32.169C57.9818 32.7342 58.4391 33.1924 59.0033 33.1924H78.8583C79.4224 33.1924 79.8798 32.7342 79.8798 32.169V21.6556C79.8798 21.0904 79.4224 20.6322 78.8583 20.6322Z"
                        fill="#0063A6"/>
                    <path d="M58.8842 21.5332V32.2881H75.8814V21.5332H58.8842Z" fill="#0075B3"/>
                    <path d="M58.881 23.0588V30.7551H74.0726V23.0588H58.881Z" fill="url(#paint2_linear_1097_44670)"/>
                    <path
                        d="M77.638 32.2807H79.2448V21.5488H77.638C77.4838 21.5488 77.3359 21.6102 77.2268 21.7194C77.1178 21.8287 77.0565 21.9769 77.0565 22.1314V31.7134C77.0615 31.8649 77.1248 32.0087 77.2332 32.1144C77.3416 32.2202 77.4867 32.2798 77.638 32.2807Z"
                        fill="#072129"/>
                    <path
                        d="M40.3146 20.6322H20.4596C19.8954 20.6322 19.4381 21.0904 19.4381 21.6556V32.169C19.4381 32.7342 19.8954 33.1924 20.4596 33.1924H40.3146C40.8787 33.1924 41.3361 32.7342 41.3361 32.169V21.6556C41.3361 21.0904 40.8787 20.6322 40.3146 20.6322Z"
                        fill="#0063A6"/>
                    <path d="M20.3373 21.5332V32.2881H37.3346V21.5332H20.3373Z" fill="#0075B3"/>
                    <path d="M20.3373 23.0588V30.7551H35.5289V23.0588H20.3373Z" fill="url(#paint3_linear_1097_44670)"/>
                    <path
                        d="M39.0942 32.2807H40.701V21.5488H39.0942C38.9397 21.5488 38.7914 21.61 38.6817 21.7192C38.572 21.8283 38.5099 21.9765 38.5089 22.1314V31.7134C38.5148 31.8653 38.579 32.009 38.688 32.1147C38.797 32.2203 38.9425 32.2798 39.0942 32.2807Z"
                        fill="#072129"/>
                    <path
                        d="M174.112 13.6487H131.327C130.369 13.6487 129.594 14.4261 129.594 15.385V38.4394C129.594 39.3983 130.369 40.1757 131.327 40.1757H174.112C175.07 40.1757 175.845 39.3983 175.845 38.4394V15.385C175.845 14.4261 175.07 13.6487 174.112 13.6487Z"
                        fill="#0063A6"/>
                    <path d="M131.496 15.5387V38.2596H171.255V15.5387L131.496 15.5387Z" fill="#0075B3"/>
                    <path d="M131.496 17.0454V36.7653H168.532V17.0454L131.496 17.0454Z"
                          fill="url(#paint4_linear_1097_44670)"/>
                    <path
                        d="M172.877 38.2711H174.533V27.1751H172.877C172.718 27.1751 172.565 27.2385 172.452 27.3514C172.34 27.4642 172.276 27.6173 172.276 27.7769V37.6694C172.276 37.829 172.34 37.982 172.452 38.0949C172.565 38.2077 172.718 38.2711 172.877 38.2711Z"
                        fill="#072129"/>
                    <path
                        d="M172.877 26.6428H174.533V15.5276H172.877C172.718 15.5276 172.565 15.591 172.452 15.7039C172.34 15.8167 172.276 15.9698 172.276 16.1294V26.0372C172.276 26.1165 172.291 26.1952 172.321 26.2687C172.351 26.3421 172.395 26.4089 172.451 26.4652C172.507 26.5215 172.573 26.5661 172.646 26.5966C172.719 26.6271 172.798 26.6428 172.877 26.6428Z"
                        fill="#072129"/>
                    <path
                        d="M184.098 53.8248C184.199 53.8248 184.296 53.7844 184.368 53.7125C184.44 53.6407 184.48 53.5432 184.48 53.4415V51.939H186.144C188.229 51.939 188.78 50.2104 188.78 49.2982V46.485H188.111V49.2944C188.111 49.4975 188.042 51.2683 186.144 51.2683H184.488V49.8272C184.488 49.7225 184.446 49.6221 184.372 49.548C184.299 49.474 184.198 49.4324 184.094 49.4324C183.989 49.4324 183.889 49.474 183.815 49.548C183.741 49.6221 183.7 49.7225 183.7 49.8272V53.43C183.698 53.4826 183.707 53.535 183.727 53.5839C183.746 53.6328 183.776 53.6771 183.813 53.7142C183.85 53.7512 183.895 53.7801 183.944 53.7992C183.993 53.8182 184.045 53.8269 184.098 53.8248Z"
                        fill="#0075B3"/>
                    <path
                        d="M184.098 0.00030677C184.2 0.00030677 184.297 0.0406882 184.369 0.112567C184.44 0.184447 184.481 0.281936 184.481 0.383589V1.91672H186.145C188.23 1.91672 188.781 3.64532 188.781 4.55753V7.37082H188.111V4.5422C188.111 4.33906 188.042 2.5683 186.145 2.5683H184.488V3.99794C184.488 4.10264 184.447 4.20305 184.373 4.27709C184.299 4.35113 184.199 4.39272 184.094 4.39272C183.99 4.39272 183.89 4.35113 183.816 4.27709C183.742 4.20305 183.7 4.10264 183.7 3.99794V0.383589C183.7 0.331939 183.711 0.280814 183.731 0.233283C183.751 0.185753 183.781 0.142799 183.818 0.107C183.855 0.0712008 183.899 0.043296 183.947 0.0249614C183.995 0.00662688 184.047 -0.00175924 184.098 0.00030677Z"
                        fill="#0075B3"/>
                    <defs>
                        <linearGradient id="paint0_linear_1097_44670" x1="177.636" y1="27.0719" x2="182.674"
                                        y2="27.0719"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0075B3"/>
                            <stop offset="0.39" stopColor="#007DB9"/>
                            <stop offset="1" stopColor="#0093C9"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_1097_44670" x1="6451.94" y1="4782.56" x2="6756.98"
                                        y2="4782.56"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0075B3"/>
                            <stop offset="0.39" stopColor="#007DB9"/>
                            <stop offset="1" stopColor="#0093C9"/>
                        </linearGradient>
                        <linearGradient id="paint2_linear_1097_44670" x1="5639.43" y1="4782.57" x2="5944.48"
                                        y2="4782.57"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0075B3"/>
                            <stop offset="0.39" stopColor="#007DB9"/>
                            <stop offset="1" stopColor="#0093C9"/>
                        </linearGradient>
                        <linearGradient id="paint3_linear_1097_44670" x1="4826.86" y1="4782.57" x2="5131.91"
                                        y2="4782.57"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0075B3"/>
                            <stop offset="0.39" stopColor="#007DB9"/>
                            <stop offset="1" stopColor="#0093C9"/>
                        </linearGradient>
                        <linearGradient id="paint4_linear_1097_44670" x1="18525.5" y1="11757.7" x2="20431" y2="11757.7"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0075B3"/>
                            <stop offset="0.39" stopColor="#007DB9"/>
                            <stop offset="1" stopColor="#0093C9"/>
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <svg width="1700" height="182" viewBox="0 0 1700 182" fill="none" xmlns="http://www.w3.org/2000/svg"
                 id={"first-section-path"}>
                <path
                    d="M0.644226 20.4175H599.499H1544.49C1619.04 20.4175 1679.49 80.859 1679.49 155.417V173.717V181.036"
                    stroke="#DADADA" strokeOpacity="0.45" strokeWidth="40" ref={pathRef}/>
            </svg>
            <div className={"empty-spacer"}/>
            <div className={"main-content"}
                 data-aos="fade-up"
                 data-aos-duration="1000"
                 data-aos-easing="linear"
                 data-aos-once="true"
            >
                <p className={"title"}>
                    Why We Are The Best
                </p>
                <p className={"description"}>We are in this domain for 45 years serving our customers
                    <br className={"break-sentence"}/>
                    with humility and integrity ensuring long term strategic alliance
                    {/*We have been leading transport operator in Staff Transportation Segment at Tamil Nadu*/}
                </p>
                <div className={"spacer"}/>
                {/*<MDBContainer className={"reasons-container"}>*/}
                {/*    <MDBRow className={"reason-row"}>*/}
                {/*        <MDBCol size="12" md={"4"}>*/}
                {/*            <div className={"reason-card"}*/}
                {/*                 data-aos="fade-right"*/}
                {/*                 data-aos-duration="1000"*/}
                {/*                 data-aos-easing="linear"*/}
                {/*                 data-aos-once="true"*/}
                {/*            >*/}
                {/*                <img src={TeamIcon} className={"img-fluid team-inactive"} alt={"Team"}/>*/}
                {/*                <img src={TeamActiveIcon} className={"img-fluid team-active"} alt={"Team"}/>*/}
                {/*                <p className={"reason-title"}>Dedicated Site Operation Team</p>*/}
                {/*                <p className={"reason-description"}>Lorem ipsum dolor sit amet, consectetur*/}
                {/*                    adipiscing elit. Augue sapien viverra mi*/}
                {/*                    lorem ipsum dolor sit amet, consectetur*/}
                {/*                    adipiscing elit. </p>*/}
                {/*            </div>*/}
                {/*        </MDBCol>*/}
                {/*        <MDBCol size="12" md={"4"}>*/}
                {/*            <div className={"reason-card"} data-aos="fade-up"*/}
                {/*                 data-aos-duration="1000"*/}
                {/*                 data-aos-easing="linear"*/}
                {/*                 data-aos-once="true"*/}
                {/*            >*/}
                {/*                <img src={CustomerCareIcon} className={"img-fluid customer-care-inactive"}*/}
                {/*                     alt={"Customer Care"}/>*/}
                {/*                <img src={CustomerCareActiveIcon} className={"img-fluid customer-care-active"}*/}
                {/*                     alt={"Customer Care"}/>*/}
                {/*                <p className={"reason-title"}>Central Command Center</p>*/}
                {/*                <p className={"reason-description"}>Lorem ipsum dolor sit amet, consectetur*/}
                {/*                    adipiscing elit. Augue sapien viverra mi*/}
                {/*                    lorem ipsum dolor sit amet, consectetur*/}
                {/*                    adipiscing elit. </p>*/}
                {/*            </div>*/}
                {/*        </MDBCol>*/}
                {/*        <MDBCol size="12" md={"4"}>*/}
                {/*            <div className={"reason-card"} data-aos="fade-left"*/}
                {/*                 data-aos-duration="1000"*/}
                {/*                 data-aos-easing="linear"*/}
                {/*                 data-aos-once="true"*/}
                {/*            >*/}
                {/*                <img src={SafetyIcon} className={"img-fluid safety-inactive"} alt={"Safety"}/>*/}
                {/*                <img src={SafetyActiveIcon} className={"img-fluid safety-active"} alt={"Safety"}/>*/}
                {/*                <p className={"reason-title"}>Safety For Everyone</p>*/}
                {/*                <p className={"reason-description"}>Lorem ipsum dolor sit amet, consectetur*/}
                {/*                    adipiscing elit. Augue sapien viverra mi*/}
                {/*                    lorem ipsum dolor sit amet, consectetur*/}
                {/*                    adipiscing elit. </p>*/}
                {/*            </div>*/}
                {/*        </MDBCol>*/}
                {/*    </MDBRow>*/}
                {/*</MDBContainer>*/}
                <MDBContainer className={"reasons-container"}>
                <Carousel
                    additionalTransfrom={0}
                    arrows={false}
                    autoPlay={false}
                    autoPlaySpeed={5000}
                    centerMode={false}
                    infinite={true}
                    className="multi-carousel-container"
                    containerClass="container-padding-bottom"
                    customButtonGroup={<CustomButtonGroup />}
                    draggable
                    // focusOnSelect={false}
                    // infinite={false}
                    itemClass="carousel-item-padding-40-px"
                    keyBoardControl
                    minimumTouchDrag={80}
                    renderButtonGroupOutside={true}
                    renderDotsOutside={false}
                    partialVisible={true}
                    responsive={{
                        desktop: {
                            breakpoint: {
                                max: 3000,
                                min: 1024
                            },
                            items: 3,
                            partialVisibilityGutter: 40
                        },
                        mobile: {
                            breakpoint: {
                                max: 464,
                                min: 0
                            },
                            items: 1,
                            partialVisibilityGutter: 30
                        },
                        tablet: {
                            breakpoint: {
                                max: 1024,
                                min: 464
                            },
                            items: 2,
                            partialVisibilityGutter: 40,
                        }
                    }}
                    showDots={false}
                    sliderClass="slider-list-track"
                    slidesToSlide={1}
                    swipeable={true}
                >

                    <div className={"reason-card"}
                         // data-aos="fade-right"
                         // data-aos-duration="1000"
                         // data-aos-easing="linear"
                         // data-aos-once="true"
                    >
                        <img src={TeamIcon} className={"img-fluid team-inactive"} alt={"Team"}/>
                        {/*<img src={TeamActiveIcon} className={"img-fluid team-active"} alt={"Team"}/>*/}
                        <p className={"reason-title"}>The Best Fleet
                            for Travel
                        </p>
                        <p className={"reason-description"}>
                            Our Operational Excellence with better understanding of customer requirements ensuring customer delight
                        </p>
                    </div>
                    <div className={"reason-card"}
                         // data-aos="fade-up"
                         // data-aos-duration="1000"
                         // data-aos-easing="linear"
                         // data-aos-once="true"
                    >
                        <img src={CustomerCareIcon} className={"img-fluid customer-care-inactive"}
                             alt={"Customer Care"}/>
                        {/*<img src={CustomerCareActiveIcon} className={"img-fluid customer-care-active"}*/}
                        {/*     alt={"Customer Care"}/>*/}
                        <p className={"reason-title"}>Dedicated Site
                            Operation Team
                        </p>
                        <p className={"reason-description"}>
                            Round the clock operations catering to multiple shift timings with 24x7 central command centre support.
                        </p>
                    </div>
                    <div className={"reason-card"}
                         // data-aos="fade-left"
                         // data-aos-duration="1000"
                         // data-aos-easing="linear"
                         // data-aos-once="true"
                    >
                        <img src={SafetyIcon} className={"img-fluid safety-inactive"} alt={"Safety"}/>
                        {/*<img src={SafetyActiveIcon} className={"img-fluid safety-active"} alt={"Safety"}/>*/}
                        <p className={"reason-title"}>Safety For Everyone</p>
                        <p className={"reason-description"}>
                            Company Owned and operated fleet with verified, trained and vaccinated drivers
                        </p>
                    </div>
                    <div className={"reason-card"}
                        // data-aos="fade-right"
                        // data-aos-duration="1000"
                        // data-aos-easing="linear"
                        // data-aos-once="true"
                    >
                        <img src={ServiceIcon} className={"img-fluid team-inactive"} alt={"Team"}/>
                        {/*<img src={TeamActiveIcon} className={"img-fluid team-active"} alt={"Team"}/>*/}
                        <p className={"reason-title"}>Service
                        </p>
                        <p className={"reason-description"}>Best in class seamless service with an
                            effective MIS system</p>
                    </div>
                    <div className={"reason-card"}
                        // data-aos="fade-up"
                        // data-aos-duration="1000"
                        // data-aos-easing="linear"
                        // data-aos-once="true"
                    >
                        <img src={OrganizationIcon} className={"img-fluid customer-care-inactive"}
                             alt={"Customer Care"}/>
                        {/*<img src={CustomerCareActiveIcon} className={"img-fluid customer-care-active"}*/}
                        {/*     alt={"Customer Care"}/>*/}
                        <p className={"reason-title"}>Organization
                        </p>
                        <p className={"reason-description"}>
                            100 % Compliance Organization
                        </p>
                    </div>
                    <div className={"reason-card"}
                        // data-aos="fade-left"
                        // data-aos-duration="1000"
                        // data-aos-easing="linear"
                        // data-aos-once="true"
                    >
                        <img src={TransportIcon} className={"img-fluid safety-inactive"} alt={"Safety"}/>
                        {/*<img src={SafetyActiveIcon} className={"img-fluid safety-active"} alt={"Safety"}/>*/}
                        <p className={"reason-title"}>Transport</p>
                        <p className={"reason-description"}>End to end transport management solutions</p>
                    </div>
                </Carousel>
                </MDBContainer>
            </div>
        </div>
    );
}


const CustomButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
        <div className="carousel-button-group text-right">
            <button className={currentSlide === 0 ? 'disable' : ''}
                    onClick={() => previous()} >
                <img src={LeftArrowIcon} alt={"right-arrow"}/>
            </button>
            <button onClick={() => next()} >
                <img src={RightArrowIcon} alt={"right-arrow"} style={{transform: "rotate(180deg)"}}/>
            </button>
        </div>
    );
};

const ServicesAndAboutUs = (props) => {
    const busRef1 = useRef();
    const pathRef1 = useRef();

    useEffect(() => {
        gsap.to(busRef1.current, {
            motionPath: {
                path: pathRef1.current,
                align: pathRef1.current,
                autoRotate: true,
                immediateRender: true,
                alignOrigin: [0.5, 0.5],
            }
        });
        gsap.timeline({
            scrollTrigger: {
                trigger: '.services-and-aboutus',
                start: 'top 80%',
                end: 'bottom center',
                toggleClass:"active",
                scrub: 0.3,
                onUpdate: ({progress}) => console.log(progress) //  info for position
            },
            defaults: {duration: 3, ease: 'none'}
        })
            .to(busRef1.current, {
                motionPath: {
                    path: pathRef1.current,
                    align: pathRef1.current,
                    paused: true,
                    ease: "none",
                    autoRotate: true,
                    immediateRender: true,
                    alignOrigin: [0.5, 0.5],
                    start:1,
                    end:0

                }
            });
    });
    return(
        <div className={"services-and-aboutus"} id={"services-and-aboutus"} >
            <div ref={busRef1} id={"busref1"}>
                <svg width="209" height="62" viewBox="0 0 209 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9.7176 51.4176C10.0657 56.3888 13.6083 58.6425 16.9251 58.6425H193.061V61.6052C193.061 61.7099 193.103 61.8103 193.177 61.8844C193.251 61.9584 193.351 62 193.455 62C193.56 62 193.66 61.9584 193.734 61.8844C193.808 61.8103 193.849 61.7099 193.849 61.6052V60.1066H195.506C196.005 60.1385 196.501 60.0149 196.927 59.7528C197.354 59.4906 197.688 59.1028 197.885 58.6425H198.138C201.443 58.6425 205.586 53.5486 207.113 48.7653C207.113 48.7653 208.643 45.3924 208.643 34.7678C208.643 24.1432 207.113 21.4181 207.113 21.4181C206.057 17.409 201.294 11.5371 198.138 11.5371H197.885C197.688 11.0768 197.354 10.6889 196.927 10.4268C196.501 10.1646 196.005 10.041 195.506 10.073H193.849V8.5705C193.849 8.4658 193.808 8.36538 193.734 8.29135C193.66 8.21731 193.56 8.17572 193.455 8.17572C193.351 8.17572 193.251 8.21731 193.177 8.29135C193.103 8.36538 193.061 8.4658 193.061 8.5705V11.5371H16.9251C13.7651 11.5371 10.0657 13.1775 9.7176 18.7543C9.7176 18.7543 9.57605 24.1202 9.57605 34.764C9.57605 45.4077 9.7176 51.4176 9.7176 51.4176Z"
                        fill="#B7C0C4"/>
                    <path
                        d="M0.359932 43.2421C0.708065 48.2248 4.26208 50.4632 7.56743 50.4632H188.78C192.086 50.4632 196.229 45.3732 197.755 40.586C197.755 40.586 199.285 37.2131 199.285 26.5885C199.285 15.9639 197.755 13.2388 197.755 13.2388C196.699 9.2335 191.936 3.36162 188.78 3.36162H7.56743C4.42658 3.36162 0.708065 5.00207 0.359932 10.5827C0.359932 10.5827 0.218384 15.9486 0.218384 26.5885C0.218384 37.2284 0.359932 43.2421 0.359932 43.2421Z"
                        fill="#005B8D"/>
                    <path
                        d="M189.718 5.45821C191.302 5.45821 195.514 10.391 196.447 13.7333C196.466 13.8099 197.882 16.4162 197.882 26.5809C197.882 36.8184 196.447 40.0878 196.428 40.1452C194.653 45.7105 190.49 48.3666 189.718 48.3666H6.59597C5.83084 48.3666 1.87131 48.428 1.68385 42.7899C1.68385 42.7439 1.62646 36.8184 1.62646 26.5809C1.62646 16.4201 1.6609 11.1538 1.6609 11.1001C1.69533 7.35929 3.71144 5.79166 6.59597 5.45821H189.718Z"
                        fill="#0063A6"/>
                    <path
                        d="M189.717 6.73869C190.762 6.73869 194.438 11.2844 195.215 14.0785C195.41 14.6995 196.603 17.187 196.603 26.5812C196.603 36.5465 195.222 39.7124 195.207 39.7699C193.979 43.6027 191.542 47.0868 189.717 47.0868H6.59538C5.13781 47.0868 2.93807 45.5843 2.89981 42.3992V26.5812C2.89599 16.75 2.87303 11.5642 2.87303 11.4991C2.84625 8.68193 5.16841 6.66587 6.59538 6.72719L189.717 6.73869Z"
                        fill="#0075B3"/>
                    <path d="M182.732 43.9823C182.732 43.9823 184.308 25.9681 182.732 10.1615H177.376V43.9823H182.732Z"
                          fill="url(#paint0_linear_1097_44670)"/>
                    <path
                        d="M183.543 43.8439L189.664 45.1854C190.341 45.0206 192.273 42.7516 193.402 39.2101L193.429 39.1219L193.455 39.0529C193.585 38.7233 194.714 35.5804 194.714 26.5771C194.714 21.0731 194.308 17.1483 193.505 14.91C193.467 14.8065 193.436 14.7183 193.398 14.588C192.847 12.6141 190.379 9.40988 189.354 8.63948L183.543 9.96564C183.543 9.96564 184.343 13.6681 184.343 26.83C184.343 39.992 183.543 43.8439 183.543 43.8439Z"
                        fill="#072129"/>
                    <path
                        d="M188.749 10.6019C187.912 13.6413 187.483 18.9766 187.483 26.4583C187.483 38.6965 188.669 42.9662 188.803 43.4147L188.83 43.5066L188.86 43.6255C188.987 44.1352 189.121 44.6297 189.266 45.1011L189.648 45.1854C190.326 45.0206 192.258 42.7516 193.386 39.2101L193.413 39.1219L193.44 39.0529C193.57 38.7233 194.698 35.5804 194.698 26.5771C194.698 21.0731 194.293 17.1483 193.489 14.91C193.451 14.8065 193.421 14.7183 193.382 14.588C192.831 12.6141 190.364 9.40988 189.339 8.63948C189.147 9.13834 188.992 9.65079 188.876 10.1726C188.822 10.3489 188.792 10.4562 188.749 10.6019Z"
                        fill="#25333A"/>
                    <path
                        d="M183.543 43.8441L185.903 44.3615C186.087 43.1235 186.646 38.4015 186.646 26.8379C186.646 15.2743 186.079 10.6136 185.9 9.43689L183.543 9.96582C183.543 9.96582 184.343 13.6683 184.343 26.8302C184.343 39.9921 183.543 43.8441 183.543 43.8441Z"
                        fill="#D5EEF2"/>
                    <path d="M187.614 46.3966H8.43982V45.4537H181.837L187.614 46.3966Z" fill="#25333A"/>
                    <path d="M163.018 8.36758H181.836L187.613 7.42854H163.018V8.36758Z" fill="#25333A"/>
                    <path d="M141.335 7.42854H48.3871V8.37142H141.335V7.42854Z" fill="#25333A"/>
                    <path d="M26.7072 7.42854H8.43982V8.37142H26.7072V7.42854Z" fill="#25333A"/>
                    <path
                        d="M117.401 20.6322H97.5462C96.9821 20.6322 96.5248 21.0904 96.5248 21.6556V32.169C96.5248 32.7342 96.9821 33.1924 97.5462 33.1924H117.401C117.965 33.1924 118.423 32.7342 118.423 32.169V21.6556C118.423 21.0904 117.965 20.6322 117.401 20.6322Z"
                        fill="#0063A6"/>
                    <path d="M97.4279 21.5293V32.2842H114.425V21.5293H97.4279Z" fill="#0075B3"/>
                    <path d="M97.4279 23.0588V30.7551H112.619V23.0588H97.4279Z" fill="url(#paint1_linear_1097_44670)"/>
                    <path
                        d="M116.181 32.2807H117.788V21.5488H116.181C116.027 21.5498 115.88 21.6115 115.771 21.7205C115.662 21.8296 115.601 21.9772 115.6 22.1314V31.7134C115.605 31.8649 115.668 32.0087 115.776 32.1144C115.885 32.2202 116.03 32.2798 116.181 32.2807Z"
                        fill="#072129"/>
                    <path
                        d="M78.8583 20.6322H59.0033C58.4391 20.6322 57.9818 21.0904 57.9818 21.6556V32.169C57.9818 32.7342 58.4391 33.1924 59.0033 33.1924H78.8583C79.4224 33.1924 79.8798 32.7342 79.8798 32.169V21.6556C79.8798 21.0904 79.4224 20.6322 78.8583 20.6322Z"
                        fill="#0063A6"/>
                    <path d="M58.8842 21.5332V32.2881H75.8814V21.5332H58.8842Z" fill="#0075B3"/>
                    <path d="M58.881 23.0588V30.7551H74.0726V23.0588H58.881Z" fill="url(#paint2_linear_1097_44670)"/>
                    <path
                        d="M77.638 32.2807H79.2448V21.5488H77.638C77.4838 21.5488 77.3359 21.6102 77.2268 21.7194C77.1178 21.8287 77.0565 21.9769 77.0565 22.1314V31.7134C77.0615 31.8649 77.1248 32.0087 77.2332 32.1144C77.3416 32.2202 77.4867 32.2798 77.638 32.2807Z"
                        fill="#072129"/>
                    <path
                        d="M40.3146 20.6322H20.4596C19.8954 20.6322 19.4381 21.0904 19.4381 21.6556V32.169C19.4381 32.7342 19.8954 33.1924 20.4596 33.1924H40.3146C40.8787 33.1924 41.3361 32.7342 41.3361 32.169V21.6556C41.3361 21.0904 40.8787 20.6322 40.3146 20.6322Z"
                        fill="#0063A6"/>
                    <path d="M20.3373 21.5332V32.2881H37.3346V21.5332H20.3373Z" fill="#0075B3"/>
                    <path d="M20.3373 23.0588V30.7551H35.5289V23.0588H20.3373Z" fill="url(#paint3_linear_1097_44670)"/>
                    <path
                        d="M39.0942 32.2807H40.701V21.5488H39.0942C38.9397 21.5488 38.7914 21.61 38.6817 21.7192C38.572 21.8283 38.5099 21.9765 38.5089 22.1314V31.7134C38.5148 31.8653 38.579 32.009 38.688 32.1147C38.797 32.2203 38.9425 32.2798 39.0942 32.2807Z"
                        fill="#072129"/>
                    <path
                        d="M174.112 13.6487H131.327C130.369 13.6487 129.594 14.4261 129.594 15.385V38.4394C129.594 39.3983 130.369 40.1757 131.327 40.1757H174.112C175.07 40.1757 175.845 39.3983 175.845 38.4394V15.385C175.845 14.4261 175.07 13.6487 174.112 13.6487Z"
                        fill="#0063A6"/>
                    <path d="M131.496 15.5387V38.2596H171.255V15.5387L131.496 15.5387Z" fill="#0075B3"/>
                    <path d="M131.496 17.0454V36.7653H168.532V17.0454L131.496 17.0454Z"
                          fill="url(#paint4_linear_1097_44670)"/>
                    <path
                        d="M172.877 38.2711H174.533V27.1751H172.877C172.718 27.1751 172.565 27.2385 172.452 27.3514C172.34 27.4642 172.276 27.6173 172.276 27.7769V37.6694C172.276 37.829 172.34 37.982 172.452 38.0949C172.565 38.2077 172.718 38.2711 172.877 38.2711Z"
                        fill="#072129"/>
                    <path
                        d="M172.877 26.6428H174.533V15.5276H172.877C172.718 15.5276 172.565 15.591 172.452 15.7039C172.34 15.8167 172.276 15.9698 172.276 16.1294V26.0372C172.276 26.1165 172.291 26.1952 172.321 26.2687C172.351 26.3421 172.395 26.4089 172.451 26.4652C172.507 26.5215 172.573 26.5661 172.646 26.5966C172.719 26.6271 172.798 26.6428 172.877 26.6428Z"
                        fill="#072129"/>
                    <path
                        d="M184.098 53.8248C184.199 53.8248 184.296 53.7844 184.368 53.7125C184.44 53.6407 184.48 53.5432 184.48 53.4415V51.939H186.144C188.229 51.939 188.78 50.2104 188.78 49.2982V46.485H188.111V49.2944C188.111 49.4975 188.042 51.2683 186.144 51.2683H184.488V49.8272C184.488 49.7225 184.446 49.6221 184.372 49.548C184.299 49.474 184.198 49.4324 184.094 49.4324C183.989 49.4324 183.889 49.474 183.815 49.548C183.741 49.6221 183.7 49.7225 183.7 49.8272V53.43C183.698 53.4826 183.707 53.535 183.727 53.5839C183.746 53.6328 183.776 53.6771 183.813 53.7142C183.85 53.7512 183.895 53.7801 183.944 53.7992C183.993 53.8182 184.045 53.8269 184.098 53.8248Z"
                        fill="#0075B3"/>
                    <path
                        d="M184.098 0.00030677C184.2 0.00030677 184.297 0.0406882 184.369 0.112567C184.44 0.184447 184.481 0.281936 184.481 0.383589V1.91672H186.145C188.23 1.91672 188.781 3.64532 188.781 4.55753V7.37082H188.111V4.5422C188.111 4.33906 188.042 2.5683 186.145 2.5683H184.488V3.99794C184.488 4.10264 184.447 4.20305 184.373 4.27709C184.299 4.35113 184.199 4.39272 184.094 4.39272C183.99 4.39272 183.89 4.35113 183.816 4.27709C183.742 4.20305 183.7 4.10264 183.7 3.99794V0.383589C183.7 0.331939 183.711 0.280814 183.731 0.233283C183.751 0.185753 183.781 0.142799 183.818 0.107C183.855 0.0712008 183.899 0.043296 183.947 0.0249614C183.995 0.00662688 184.047 -0.00175924 184.098 0.00030677Z"
                        fill="#0075B3"/>
                    <defs>
                        <linearGradient id="paint0_linear_1097_44670" x1="177.636" y1="27.0719" x2="182.674"
                                        y2="27.0719"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0075B3"/>
                            <stop offset="0.39" stopColor="#007DB9"/>
                            <stop offset="1" stopColor="#0093C9"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_1097_44670" x1="6451.94" y1="4782.56" x2="6756.98"
                                        y2="4782.56"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0075B3"/>
                            <stop offset="0.39" stopColor="#007DB9"/>
                            <stop offset="1" stopColor="#0093C9"/>
                        </linearGradient>
                        <linearGradient id="paint2_linear_1097_44670" x1="5639.43" y1="4782.57" x2="5944.48"
                                        y2="4782.57"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0075B3"/>
                            <stop offset="0.39" stopColor="#007DB9"/>
                            <stop offset="1" stopColor="#0093C9"/>
                        </linearGradient>
                        <linearGradient id="paint3_linear_1097_44670" x1="4826.86" y1="4782.57" x2="5131.91"
                                        y2="4782.57"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0075B3"/>
                            <stop offset="0.39" stopColor="#007DB9"/>
                            <stop offset="1" stopColor="#0093C9"/>
                        </linearGradient>
                        <linearGradient id="paint4_linear_1097_44670" x1="18525.5" y1="11757.7" x2="20431" y2="11757.7"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0075B3"/>
                            <stop offset="0.39" stopColor="#007DB9"/>
                            <stop offset="1" stopColor="#0093C9"/>
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            {/*<svg width="41" height="794" viewBox="0 0 41 794" fill="none" xmlns="http://www.w3.org/2000/svg"*/}
            {/*     id={"second-section-path"}>*/}
            {/*    <path d="M20.5414 0.447021V793.21" stroke="#DADADA" strokeOpacity="0.45" strokeWidth="40"*/}
            {/*          ref={pathRef1}/>*/}
            {/*</svg>*/}

            <svg  id={"second-section-path"} width="749" height="1201" viewBox="0 0 749 1201" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path ref={pathRef1} d="M0.269531 1175.35H588.731C663.289 1175.35 723.731 1114.9 723.731 1040.35V0.974121" stroke="#DADADA" strokeOpacity="0.45" strokeWidth="50"/>
            </svg>


            <Services/>
            <AboutUs/>
        </div>
    );
}

const Services = (props) => {

    // const busRef1 = useRef();
    // const pathRef1 = useRef();
    //
    // useEffect(() => {
    //     gsap.to(busRef1.current, {
    //         motionPath: {
    //             path: pathRef1.current,
    //             align: pathRef1.current,
    //             autoRotate: true,
    //             immediateRender: true,
    //             alignOrigin: [0.5, 0.5],
    //         }
    //     });
    //     gsap.timeline({
    //         scrollTrigger: {
    //             trigger: '.services-component',
    //             start: 'top 80%',
    //             end: 'bottom center',
    //             toggleClass:"active",
    //             scrub: 0.3,
    //             onUpdate: ({progress}) => console.log(progress) //  info for position
    //         },
    //         defaults: {duration: 3, ease: 'none'}
    //     })
    //         .to(busRef1.current, {
    //             motionPath: {
    //                 path: pathRef1.current,
    //                 align: pathRef1.current,
    //                 paused: true,
    //                 ease: "none",
    //                 autoRotate: true,
    //                 immediateRender: true,
    //                 alignOrigin: [0.5, 0.5],
    //
    //             }
    //         });
    // });

    return (
        <div className={"services-component"}>
            {/*<div ref={busRef1} id={"busref1"}>*/}
            {/*    <svg width="209" height="62" viewBox="0 0 209 62" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*        <path*/}
            {/*            d="M9.7176 51.4176C10.0657 56.3888 13.6083 58.6425 16.9251 58.6425H193.061V61.6052C193.061 61.7099 193.103 61.8103 193.177 61.8844C193.251 61.9584 193.351 62 193.455 62C193.56 62 193.66 61.9584 193.734 61.8844C193.808 61.8103 193.849 61.7099 193.849 61.6052V60.1066H195.506C196.005 60.1385 196.501 60.0149 196.927 59.7528C197.354 59.4906 197.688 59.1028 197.885 58.6425H198.138C201.443 58.6425 205.586 53.5486 207.113 48.7653C207.113 48.7653 208.643 45.3924 208.643 34.7678C208.643 24.1432 207.113 21.4181 207.113 21.4181C206.057 17.409 201.294 11.5371 198.138 11.5371H197.885C197.688 11.0768 197.354 10.6889 196.927 10.4268C196.501 10.1646 196.005 10.041 195.506 10.073H193.849V8.5705C193.849 8.4658 193.808 8.36538 193.734 8.29135C193.66 8.21731 193.56 8.17572 193.455 8.17572C193.351 8.17572 193.251 8.21731 193.177 8.29135C193.103 8.36538 193.061 8.4658 193.061 8.5705V11.5371H16.9251C13.7651 11.5371 10.0657 13.1775 9.7176 18.7543C9.7176 18.7543 9.57605 24.1202 9.57605 34.764C9.57605 45.4077 9.7176 51.4176 9.7176 51.4176Z"*/}
            {/*            fill="#B7C0C4"/>*/}
            {/*        <path*/}
            {/*            d="M0.359932 43.2421C0.708065 48.2248 4.26208 50.4632 7.56743 50.4632H188.78C192.086 50.4632 196.229 45.3732 197.755 40.586C197.755 40.586 199.285 37.2131 199.285 26.5885C199.285 15.9639 197.755 13.2388 197.755 13.2388C196.699 9.2335 191.936 3.36162 188.78 3.36162H7.56743C4.42658 3.36162 0.708065 5.00207 0.359932 10.5827C0.359932 10.5827 0.218384 15.9486 0.218384 26.5885C0.218384 37.2284 0.359932 43.2421 0.359932 43.2421Z"*/}
            {/*            fill="#005B8D"/>*/}
            {/*        <path*/}
            {/*            d="M189.718 5.45821C191.302 5.45821 195.514 10.391 196.447 13.7333C196.466 13.8099 197.882 16.4162 197.882 26.5809C197.882 36.8184 196.447 40.0878 196.428 40.1452C194.653 45.7105 190.49 48.3666 189.718 48.3666H6.59597C5.83084 48.3666 1.87131 48.428 1.68385 42.7899C1.68385 42.7439 1.62646 36.8184 1.62646 26.5809C1.62646 16.4201 1.6609 11.1538 1.6609 11.1001C1.69533 7.35929 3.71144 5.79166 6.59597 5.45821H189.718Z"*/}
            {/*            fill="#0063A6"/>*/}
            {/*        <path*/}
            {/*            d="M189.717 6.73869C190.762 6.73869 194.438 11.2844 195.215 14.0785C195.41 14.6995 196.603 17.187 196.603 26.5812C196.603 36.5465 195.222 39.7124 195.207 39.7699C193.979 43.6027 191.542 47.0868 189.717 47.0868H6.59538C5.13781 47.0868 2.93807 45.5843 2.89981 42.3992V26.5812C2.89599 16.75 2.87303 11.5642 2.87303 11.4991C2.84625 8.68193 5.16841 6.66587 6.59538 6.72719L189.717 6.73869Z"*/}
            {/*            fill="#0075B3"/>*/}
            {/*        <path d="M182.732 43.9823C182.732 43.9823 184.308 25.9681 182.732 10.1615H177.376V43.9823H182.732Z"*/}
            {/*              fill="url(#paint0_linear_1097_44670)"/>*/}
            {/*        <path*/}
            {/*            d="M183.543 43.8439L189.664 45.1854C190.341 45.0206 192.273 42.7516 193.402 39.2101L193.429 39.1219L193.455 39.0529C193.585 38.7233 194.714 35.5804 194.714 26.5771C194.714 21.0731 194.308 17.1483 193.505 14.91C193.467 14.8065 193.436 14.7183 193.398 14.588C192.847 12.6141 190.379 9.40988 189.354 8.63948L183.543 9.96564C183.543 9.96564 184.343 13.6681 184.343 26.83C184.343 39.992 183.543 43.8439 183.543 43.8439Z"*/}
            {/*            fill="#072129"/>*/}
            {/*        <path*/}
            {/*            d="M188.749 10.6019C187.912 13.6413 187.483 18.9766 187.483 26.4583C187.483 38.6965 188.669 42.9662 188.803 43.4147L188.83 43.5066L188.86 43.6255C188.987 44.1352 189.121 44.6297 189.266 45.1011L189.648 45.1854C190.326 45.0206 192.258 42.7516 193.386 39.2101L193.413 39.1219L193.44 39.0529C193.57 38.7233 194.698 35.5804 194.698 26.5771C194.698 21.0731 194.293 17.1483 193.489 14.91C193.451 14.8065 193.421 14.7183 193.382 14.588C192.831 12.6141 190.364 9.40988 189.339 8.63948C189.147 9.13834 188.992 9.65079 188.876 10.1726C188.822 10.3489 188.792 10.4562 188.749 10.6019Z"*/}
            {/*            fill="#25333A"/>*/}
            {/*        <path*/}
            {/*            d="M183.543 43.8441L185.903 44.3615C186.087 43.1235 186.646 38.4015 186.646 26.8379C186.646 15.2743 186.079 10.6136 185.9 9.43689L183.543 9.96582C183.543 9.96582 184.343 13.6683 184.343 26.8302C184.343 39.9921 183.543 43.8441 183.543 43.8441Z"*/}
            {/*            fill="#D5EEF2"/>*/}
            {/*        <path d="M187.614 46.3966H8.43982V45.4537H181.837L187.614 46.3966Z" fill="#25333A"/>*/}
            {/*        <path d="M163.018 8.36758H181.836L187.613 7.42854H163.018V8.36758Z" fill="#25333A"/>*/}
            {/*        <path d="M141.335 7.42854H48.3871V8.37142H141.335V7.42854Z" fill="#25333A"/>*/}
            {/*        <path d="M26.7072 7.42854H8.43982V8.37142H26.7072V7.42854Z" fill="#25333A"/>*/}
            {/*        <path*/}
            {/*            d="M117.401 20.6322H97.5462C96.9821 20.6322 96.5248 21.0904 96.5248 21.6556V32.169C96.5248 32.7342 96.9821 33.1924 97.5462 33.1924H117.401C117.965 33.1924 118.423 32.7342 118.423 32.169V21.6556C118.423 21.0904 117.965 20.6322 117.401 20.6322Z"*/}
            {/*            fill="#0063A6"/>*/}
            {/*        <path d="M97.4279 21.5293V32.2842H114.425V21.5293H97.4279Z" fill="#0075B3"/>*/}
            {/*        <path d="M97.4279 23.0588V30.7551H112.619V23.0588H97.4279Z" fill="url(#paint1_linear_1097_44670)"/>*/}
            {/*        <path*/}
            {/*            d="M116.181 32.2807H117.788V21.5488H116.181C116.027 21.5498 115.88 21.6115 115.771 21.7205C115.662 21.8296 115.601 21.9772 115.6 22.1314V31.7134C115.605 31.8649 115.668 32.0087 115.776 32.1144C115.885 32.2202 116.03 32.2798 116.181 32.2807Z"*/}
            {/*            fill="#072129"/>*/}
            {/*        <path*/}
            {/*            d="M78.8583 20.6322H59.0033C58.4391 20.6322 57.9818 21.0904 57.9818 21.6556V32.169C57.9818 32.7342 58.4391 33.1924 59.0033 33.1924H78.8583C79.4224 33.1924 79.8798 32.7342 79.8798 32.169V21.6556C79.8798 21.0904 79.4224 20.6322 78.8583 20.6322Z"*/}
            {/*            fill="#0063A6"/>*/}
            {/*        <path d="M58.8842 21.5332V32.2881H75.8814V21.5332H58.8842Z" fill="#0075B3"/>*/}
            {/*        <path d="M58.881 23.0588V30.7551H74.0726V23.0588H58.881Z" fill="url(#paint2_linear_1097_44670)"/>*/}
            {/*        <path*/}
            {/*            d="M77.638 32.2807H79.2448V21.5488H77.638C77.4838 21.5488 77.3359 21.6102 77.2268 21.7194C77.1178 21.8287 77.0565 21.9769 77.0565 22.1314V31.7134C77.0615 31.8649 77.1248 32.0087 77.2332 32.1144C77.3416 32.2202 77.4867 32.2798 77.638 32.2807Z"*/}
            {/*            fill="#072129"/>*/}
            {/*        <path*/}
            {/*            d="M40.3146 20.6322H20.4596C19.8954 20.6322 19.4381 21.0904 19.4381 21.6556V32.169C19.4381 32.7342 19.8954 33.1924 20.4596 33.1924H40.3146C40.8787 33.1924 41.3361 32.7342 41.3361 32.169V21.6556C41.3361 21.0904 40.8787 20.6322 40.3146 20.6322Z"*/}
            {/*            fill="#0063A6"/>*/}
            {/*        <path d="M20.3373 21.5332V32.2881H37.3346V21.5332H20.3373Z" fill="#0075B3"/>*/}
            {/*        <path d="M20.3373 23.0588V30.7551H35.5289V23.0588H20.3373Z" fill="url(#paint3_linear_1097_44670)"/>*/}
            {/*        <path*/}
            {/*            d="M39.0942 32.2807H40.701V21.5488H39.0942C38.9397 21.5488 38.7914 21.61 38.6817 21.7192C38.572 21.8283 38.5099 21.9765 38.5089 22.1314V31.7134C38.5148 31.8653 38.579 32.009 38.688 32.1147C38.797 32.2203 38.9425 32.2798 39.0942 32.2807Z"*/}
            {/*            fill="#072129"/>*/}
            {/*        <path*/}
            {/*            d="M174.112 13.6487H131.327C130.369 13.6487 129.594 14.4261 129.594 15.385V38.4394C129.594 39.3983 130.369 40.1757 131.327 40.1757H174.112C175.07 40.1757 175.845 39.3983 175.845 38.4394V15.385C175.845 14.4261 175.07 13.6487 174.112 13.6487Z"*/}
            {/*            fill="#0063A6"/>*/}
            {/*        <path d="M131.496 15.5387V38.2596H171.255V15.5387L131.496 15.5387Z" fill="#0075B3"/>*/}
            {/*        <path d="M131.496 17.0454V36.7653H168.532V17.0454L131.496 17.0454Z"*/}
            {/*              fill="url(#paint4_linear_1097_44670)"/>*/}
            {/*        <path*/}
            {/*            d="M172.877 38.2711H174.533V27.1751H172.877C172.718 27.1751 172.565 27.2385 172.452 27.3514C172.34 27.4642 172.276 27.6173 172.276 27.7769V37.6694C172.276 37.829 172.34 37.982 172.452 38.0949C172.565 38.2077 172.718 38.2711 172.877 38.2711Z"*/}
            {/*            fill="#072129"/>*/}
            {/*        <path*/}
            {/*            d="M172.877 26.6428H174.533V15.5276H172.877C172.718 15.5276 172.565 15.591 172.452 15.7039C172.34 15.8167 172.276 15.9698 172.276 16.1294V26.0372C172.276 26.1165 172.291 26.1952 172.321 26.2687C172.351 26.3421 172.395 26.4089 172.451 26.4652C172.507 26.5215 172.573 26.5661 172.646 26.5966C172.719 26.6271 172.798 26.6428 172.877 26.6428Z"*/}
            {/*            fill="#072129"/>*/}
            {/*        <path*/}
            {/*            d="M184.098 53.8248C184.199 53.8248 184.296 53.7844 184.368 53.7125C184.44 53.6407 184.48 53.5432 184.48 53.4415V51.939H186.144C188.229 51.939 188.78 50.2104 188.78 49.2982V46.485H188.111V49.2944C188.111 49.4975 188.042 51.2683 186.144 51.2683H184.488V49.8272C184.488 49.7225 184.446 49.6221 184.372 49.548C184.299 49.474 184.198 49.4324 184.094 49.4324C183.989 49.4324 183.889 49.474 183.815 49.548C183.741 49.6221 183.7 49.7225 183.7 49.8272V53.43C183.698 53.4826 183.707 53.535 183.727 53.5839C183.746 53.6328 183.776 53.6771 183.813 53.7142C183.85 53.7512 183.895 53.7801 183.944 53.7992C183.993 53.8182 184.045 53.8269 184.098 53.8248Z"*/}
            {/*            fill="#0075B3"/>*/}
            {/*        <path*/}
            {/*            d="M184.098 0.00030677C184.2 0.00030677 184.297 0.0406882 184.369 0.112567C184.44 0.184447 184.481 0.281936 184.481 0.383589V1.91672H186.145C188.23 1.91672 188.781 3.64532 188.781 4.55753V7.37082H188.111V4.5422C188.111 4.33906 188.042 2.5683 186.145 2.5683H184.488V3.99794C184.488 4.10264 184.447 4.20305 184.373 4.27709C184.299 4.35113 184.199 4.39272 184.094 4.39272C183.99 4.39272 183.89 4.35113 183.816 4.27709C183.742 4.20305 183.7 4.10264 183.7 3.99794V0.383589C183.7 0.331939 183.711 0.280814 183.731 0.233283C183.751 0.185753 183.781 0.142799 183.818 0.107C183.855 0.0712008 183.899 0.043296 183.947 0.0249614C183.995 0.00662688 184.047 -0.00175924 184.098 0.00030677Z"*/}
            {/*            fill="#0075B3"/>*/}
            {/*        <defs>*/}
            {/*            <linearGradient id="paint0_linear_1097_44670" x1="177.636" y1="27.0719" x2="182.674"*/}
            {/*                            y2="27.0719"*/}
            {/*                            gradientUnits="userSpaceOnUse">*/}
            {/*                <stop stopColor="#0075B3"/>*/}
            {/*                <stop offset="0.39" stopColor="#007DB9"/>*/}
            {/*                <stop offset="1" stopColor="#0093C9"/>*/}
            {/*            </linearGradient>*/}
            {/*            <linearGradient id="paint1_linear_1097_44670" x1="6451.94" y1="4782.56" x2="6756.98"*/}
            {/*                            y2="4782.56"*/}
            {/*                            gradientUnits="userSpaceOnUse">*/}
            {/*                <stop stopColor="#0075B3"/>*/}
            {/*                <stop offset="0.39" stopColor="#007DB9"/>*/}
            {/*                <stop offset="1" stopColor="#0093C9"/>*/}
            {/*            </linearGradient>*/}
            {/*            <linearGradient id="paint2_linear_1097_44670" x1="5639.43" y1="4782.57" x2="5944.48"*/}
            {/*                            y2="4782.57"*/}
            {/*                            gradientUnits="userSpaceOnUse">*/}
            {/*                <stop stopColor="#0075B3"/>*/}
            {/*                <stop offset="0.39" stopColor="#007DB9"/>*/}
            {/*                <stop offset="1" stopColor="#0093C9"/>*/}
            {/*            </linearGradient>*/}
            {/*            <linearGradient id="paint3_linear_1097_44670" x1="4826.86" y1="4782.57" x2="5131.91"*/}
            {/*                            y2="4782.57"*/}
            {/*                            gradientUnits="userSpaceOnUse">*/}
            {/*                <stop stopColor="#0075B3"/>*/}
            {/*                <stop offset="0.39" stopColor="#007DB9"/>*/}
            {/*                <stop offset="1" stopColor="#0093C9"/>*/}
            {/*            </linearGradient>*/}
            {/*            <linearGradient id="paint4_linear_1097_44670" x1="18525.5" y1="11757.7" x2="20431" y2="11757.7"*/}
            {/*                            gradientUnits="userSpaceOnUse">*/}
            {/*                <stop stopColor="#0075B3"/>*/}
            {/*                <stop offset="0.39" stopColor="#007DB9"/>*/}
            {/*                <stop offset="1" stopColor="#0093C9"/>*/}
            {/*            </linearGradient>*/}
            {/*        </defs>*/}
            {/*    </svg>*/}
            {/*</div>*/}
            {/*<svg width="41" height="794" viewBox="0 0 41 794" fill="none" xmlns="http://www.w3.org/2000/svg"*/}
            {/*     id={"second-section-path"}>*/}
            {/*    <path d="M20.5414 0.447021V793.21" stroke="#DADADA" strokeOpacity="0.45" strokeWidth="40"*/}
            {/*          ref={pathRef1}/>*/}
            {/*</svg>*/}
            <MDBContainer>
                <MDBRow>
                    <MDBCol size="12" md={"4"} className={"service-item-column px-sm-0"}>
                        <div data-aos="fade-right"
                             data-aos-duration="1000"
                             data-aos-easing="linear"
                             data-aos-once="true"
                        >
                            <p className={"title"}>Our Service</p>
                            <p className={"description"}>
                                At PRM Roadways, we offer 24x7 speedy, dependable services, ensuring enjoyable travel experience with safety and reliability. Our team of dedicated professional drivers and mechanics have extensive experience in handling LMVs, heavy-duty trucks, buses and tempo travellers.
                            </p>
                            <Link to={"/services"}>
                                <button type={"button"} className={"btn view-more-btn"}>
                                    View More
                                </button>
                                {/*<MDBBtn className={"view-more-btn"}>View More</MDBBtn>*/}
                            </Link>
                        </div>
                    </MDBCol>
                    <MDBCol size="12" md={"8"} className={"px-sm-0"}>
                        {/*<div className={"mobile-service-list"}>*/}
                        {/*    <Slider {...settings}>*/}
                        {/*        <MDBCard>*/}
                        {/*            <MDBCardBody>*/}
                        {/*                <div className={"card-image-section"}>*/}
                        {/*                    <img src={Car} alt={"Car"} className={"img-fluid"}/>*/}
                        {/*                </div>*/}
                        {/*                <div className={"card-bottom"}>*/}
                        {/*                    <p className={"service-name"}>Staff Transport</p>*/}
                        {/*                    <p className={"service-description"}>*/}
                        {/*                        Lorem ipsum dolor sit amet, consectetur*/}
                        {/*                        adipiscing elit. Augue sapien viverra mi*/}
                        {/*                    </p>*/}
                        {/*                    <MDBBtn outline className={"read-more-btn"}>Read more</MDBBtn>*/}
                        {/*                </div>*/}
                        {/*            </MDBCardBody>*/}
                        {/*        </MDBCard>*/}
                        {/*        <MDBCard>*/}
                        {/*            <MDBCardBody>*/}
                        {/*                <div className={"card-image-section"}>*/}
                        {/*                    <img src={Lorry} alt={"Lorry"} className={"img-fluid"}/>*/}
                        {/*                </div>*/}
                        {/*                <div className={"card-bottom"}>*/}
                        {/*                    <p className={"service-name"}>Goods</p>*/}
                        {/*                    <p className={"service-description"}>*/}
                        {/*                        Lorem ipsum dolor sit amet, consectetur*/}
                        {/*                        adipiscing elit. Augue sapien viverra mi*/}
                        {/*                    </p>*/}
                        {/*                    <MDBBtn outline className={"read-more-btn"}>Read more</MDBBtn>*/}
                        {/*                </div>*/}
                        {/*            </MDBCardBody>*/}
                        {/*        </MDBCard>*/}
                        {/*    </Slider>*/}
                        {/*</div>*/}
                        {/*<div className={"service-list-section"} >*/}
                        {/*    <MDBCard className={"service-card"} data-aos="flip-right"*/}
                        {/*             data-aos-easing="linear"*/}
                        {/*             data-aos-duration="1000"*/}
                        {/*             data-aos-delay="500"*/}
                        {/*             data-aos-once="true"*/}
                        {/*    >*/}
                        {/*        <MDBCardBody>*/}
                        {/*            <div className={"card-image-section"}>*/}
                        {/*                <img src={Car} alt={"Car"} className={"img-fluid"}/>*/}
                        {/*            </div>*/}
                        {/*            <div className={"card-bottom"}>*/}
                        {/*                <p className={"service-name"}>Staff Transport</p>*/}
                        {/*                <p className={"service-description"}>*/}
                        {/*                    Lorem ipsum dolor sit amet, consectetur*/}
                        {/*                    adipiscing elit. Augue sapien viverra mi*/}
                        {/*                </p>*/}
                        {/*                <MDBBtn outline className={"read-more-btn"}>Read more</MDBBtn>*/}
                        {/*            </div>*/}
                        {/*        </MDBCardBody>*/}
                        {/*    </MDBCard >*/}
                        {/*    <MDBCard className={"service-card"} data-aos="flip-left"*/}
                        {/*             data-aos-easing="linear"*/}
                        {/*             data-aos-duration="1000"*/}
                        {/*             data-aos-delay="500"*/}
                        {/*             data-aos-once="true"*/}
                        {/*    >*/}
                        {/*        <MDBCardBody>*/}
                        {/*            <div className={"card-image-section"}>*/}
                        {/*                <img src={Lorry} alt={"Lorry"} className={"img-fluid"}/>*/}
                        {/*            </div>*/}
                        {/*            <div className={"card-bottom"}>*/}
                        {/*                <p className={"service-name"}>Goods</p>*/}
                        {/*                <p className={"service-description"}>*/}
                        {/*                    Lorem ipsum dolor sit amet, consectetur*/}
                        {/*                    adipiscing elit. Augue sapien viverra mi*/}
                        {/*                </p>*/}
                        {/*                <MDBBtn outline className={"read-more-btn"}>Read more</MDBBtn>*/}
                        {/*            </div>*/}
                        {/*        </MDBCardBody>*/}
                        {/*    </MDBCard>*/}
                        {/*</div>*/}
                        <MDBBox className={"service-list"}>
                            <MDBContainer className={"p-0"}>
                                <MDBRow>
                                    <MDBCol sm={"4"} size={"12"} className={"service-column"}>
                                        <MDBBox className={"service-list-item"}>
                                            <MDBBox className={"img-section"}>
                                                <img src={BusServiceImg} alt={"BusServiceImg"} className={"img-fluid"} width={"100%"}/>
                                            </MDBBox>
                                            <MDBBox className={"service-card-bottom text-center"}>
                                                <p className={"m-0 service-item--name"}>Corporate</p>
                                            </MDBBox>
                                        </MDBBox>
                                    </MDBCol>
                                    <MDBCol sm={"4"} size={"12"} className={"service-column"}>
                                        <MDBBox className={"service-list-item"}>
                                            <MDBBox className={"img-section"}>
                                                <img src={GoodsServiceImg} alt={"BusServiceImg"} className={"img-fluid"} width={"100%"}/>
                                            </MDBBox>
                                            <MDBBox className={"service-card-bottom text-center"}>
                                                <p className={"m-0 service-item--name"}>Goods</p>
                                            </MDBBox>
                                        </MDBBox>
                                    </MDBCol>
                                    <MDBCol sm={"4"} size={"12"} className={"service-column"}>
                                        <MDBBox className={"service-list-item"}>
                                            <MDBBox className={"img-section"}>
                                                <img src={TourServiceImg} alt={"BusServiceImg"} className={"img-fluid"} width={"100%"}/>
                                            </MDBBox>
                                            <MDBBox className={"service-card-bottom text-center"}>
                                                <p className={"m-0 service-item--name"}>Tours & Travels</p>
                                            </MDBBox>
                                        </MDBBox>
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </MDBBox>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div>
    );
}

const AboutUs = (props) => {
    // const busRef2 = useRef();
    // const pathRef2 = useRef();
    //
    // useEffect(() => {
    //     gsap.to(busRef2.current, {
    //         motionPath: {
    //             path: pathRef2.current,
    //             align: pathRef2.current,
    //             autoRotate: true,
    //             immediateRender: true,
    //             alignOrigin: [0.5, 0.5],
    //         }
    //     });
    //     gsap.timeline({
    //         scrollTrigger: {
    //             trigger: '.about-us-section',
    //             start: 'top 80%',
    //             end: 'bottom top',
    //             toggleClass:"active",
    //             scrub: 0.3,
    //             onUpdate: ({progress}) => console.log(progress) //  info for position
    //         },
    //         defaults: {duration: 3, ease: 'none'}
    //     })
    //         .to(busRef2.current, {
    //             motionPath: {
    //                 path: pathRef2.current,
    //                 align: pathRef2.current,
    //                 paused: true,
    //                 ease: "none",
    //                 autoRotate: true,
    //                 immediateRender: true,
    //                 alignOrigin: [0.5, 0.5],
    //                 start: 1,
    //                 end: 0
    //             }
    //         });
    // });

    return (
        <div className={"about-us-section"}>
            {/*<div ref={busRef2} id={"busref2"}>*/}
            {/*    <svg width="182" height="82" viewBox="0 0 182 82" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*        <path d="M179.617 20.5106V60.7957C179.617 64.4393 178.17 67.9336 175.593 70.51C173.017 73.0864 169.523 74.5339 165.879 74.5339H131.815C131.205 74.5339 130.62 74.2915 130.188 73.8602C129.757 73.4288 129.515 72.8438 129.515 72.2337V9.07062C129.515 8.46059 129.757 7.87555 130.188 7.4442C130.62 7.01284 131.205 6.77051 131.815 6.77051L165.879 6.77051C167.683 6.77051 169.47 7.12592 171.137 7.81645C172.804 8.50697 174.318 9.51909 175.594 10.795C176.87 12.0709 177.882 13.5856 178.572 15.2526C179.262 16.9196 179.617 18.7063 179.617 20.5106V20.5106Z" fill="#3671C6"/>*/}
            {/*        <path d="M171.402 18.2765V63.0255C171.402 64.6321 170.764 66.173 169.628 67.3091C168.492 68.4451 166.951 69.0834 165.344 69.0834H165.019C164.672 69.0834 164.339 68.9456 164.094 68.7002C163.849 68.4549 163.711 68.1222 163.711 67.7752V13.521C163.711 13.1741 163.849 12.8414 164.094 12.596C164.339 12.3507 164.672 12.2129 165.019 12.2129H165.339C166.945 12.2129 168.486 12.8511 169.622 13.9872C170.758 15.1233 171.397 16.6641 171.397 18.2708L171.402 18.2765Z" fill="#03374F"/>*/}
            {/*        <path d="M158.878 11.6568V9.68794C158.878 9.04076 158.354 8.51611 157.707 8.51611H146.395C145.748 8.51611 145.224 9.04076 145.224 9.68794V11.6568C145.224 12.3039 145.748 12.8286 146.395 12.8286H157.707C158.354 12.8286 158.878 12.3039 158.878 11.6568Z" fill="#03374F"/>*/}
            {/*        <path d="M141.732 11.6568V9.68794C141.732 9.04076 141.207 8.51611 140.56 8.51611H135.203C134.555 8.51611 134.031 9.04076 134.031 9.68794V11.6568C134.031 12.3039 134.555 12.8286 135.203 12.8286H140.56C141.207 12.8286 141.732 12.3039 141.732 11.6568Z" fill="#03374F"/>*/}
            {/*        <path d="M162.78 2.11536V8.00478C162.78 8.14185 162.726 8.27331 162.629 8.37023C162.532 8.46715 162.401 8.5216 162.263 8.5216H160.295C160.158 8.5206 160.028 8.46571 159.932 8.3689C159.836 8.27208 159.782 8.1412 159.782 8.00478V0.665253C159.782 0.529163 159.835 0.398563 159.931 0.301803C160.027 0.205042 160.157 0.149933 160.293 0.148438L160.813 0.148438C161.335 0.148438 161.835 0.355667 162.204 0.724537C162.573 1.09341 162.78 1.5937 162.78 2.11536V2.11536Z" fill="#448ED8"/>*/}
            {/*        <path d="M162.4 65.0799V16.2229C162.4 15.1732 161.549 14.3223 160.5 14.3223L132.876 14.3223C131.826 14.3223 130.975 15.1732 130.975 16.2229V65.0799C130.975 66.1297 131.826 66.9806 132.876 66.9806H160.5C161.549 66.9806 162.4 66.1297 162.4 65.0799Z" fill="#448ED8"/>*/}
            {/*        <path d="M129.514 64.0099V17.2939H127.255V64.0099H129.514Z" fill="#3F3F3F"/>*/}
            {/*        <path d="M127.254 75.7643V5.54183C127.254 4.95215 126.776 4.47412 126.187 4.47412L1.52445 4.47412C0.934776 4.47412 0.456757 4.95215 0.456757 5.54183V75.7643C0.456757 76.354 0.934776 76.832 1.52445 76.832H126.187C126.776 76.832 127.254 76.354 127.254 75.7643Z" fill="#E2E2E2"/>*/}
            {/*        <path d="M133.004 20.0996C133.004 19.9038 133.082 19.716 133.22 19.5776C133.358 19.4391 133.546 19.3613 133.742 19.3613H159.038C159.135 19.3611 159.231 19.38 159.32 19.417C159.41 19.454 159.492 19.5084 159.56 19.577C159.629 19.6456 159.683 19.7271 159.72 19.8168C159.757 19.9065 159.776 20.0026 159.776 20.0996V20.0996C159.776 20.1966 159.757 20.2926 159.72 20.3822C159.683 20.4718 159.628 20.5531 159.56 20.6217C159.491 20.6903 159.41 20.7446 159.32 20.7817C159.231 20.8188 159.135 20.8379 159.038 20.8379H133.742C133.546 20.8374 133.359 20.7595 133.221 20.6211C133.082 20.4828 133.004 20.2953 133.004 20.0996V20.0996Z" fill="#3671C6"/>*/}
            {/*        <path d="M133.004 28.3252C133.004 28.1296 133.082 27.9421 133.221 27.8037C133.359 27.6654 133.546 27.5874 133.742 27.5869H159.038C159.233 27.5869 159.421 27.6647 159.56 27.8032C159.698 27.9416 159.776 28.1294 159.776 28.3252V28.3252C159.776 28.521 159.698 28.7088 159.56 28.8473C159.421 28.9857 159.233 29.0635 159.038 29.0635H133.742C133.546 29.063 133.359 28.9851 133.221 28.8467C133.082 28.7084 133.004 28.5209 133.004 28.3252V28.3252Z" fill="#3671C6"/>*/}
            {/*        <path d="M133.004 36.5508C133.004 36.3551 133.082 36.1676 133.221 36.0293C133.359 35.8909 133.546 35.813 133.742 35.8125H159.038C159.233 35.8125 159.421 35.8903 159.56 36.0287C159.698 36.1672 159.776 36.355 159.776 36.5508V36.5508C159.776 36.6478 159.757 36.744 159.72 36.8336C159.683 36.9233 159.629 37.0048 159.56 37.0734C159.492 37.142 159.41 37.1964 159.32 37.2334C159.231 37.2704 159.135 37.2894 159.038 37.2891H133.742C133.546 37.2891 133.358 37.2113 133.22 37.0729C133.082 36.9344 133.004 36.7466 133.004 36.5508Z" fill="#3671C6"/>*/}
            {/*        <path d="M133.004 44.7783C133.004 44.5825 133.082 44.3947 133.22 44.2563C133.358 44.1178 133.546 44.04 133.742 44.04H159.038C159.135 44.0398 159.231 44.0587 159.32 44.0957C159.41 44.1327 159.492 44.1871 159.56 44.2557C159.629 44.3243 159.683 44.4058 159.72 44.4955C159.757 44.5852 159.776 44.6813 159.776 44.7783V44.7783C159.776 44.9742 159.698 45.162 159.56 45.3004C159.421 45.4389 159.233 45.5167 159.038 45.5167H133.742C133.546 45.5162 133.359 45.4382 133.221 45.2999C133.082 45.1615 133.004 44.974 133.004 44.7783V44.7783Z" fill="#3671C6"/>*/}
            {/*        <path d="M133.004 52.979C133.004 52.7832 133.082 52.5954 133.22 52.457C133.358 52.3185 133.546 52.2407 133.742 52.2407L159.038 52.2407C159.135 52.2405 159.231 52.2594 159.32 52.2964C159.41 52.3334 159.492 52.3878 159.56 52.4564C159.629 52.525 159.683 52.6065 159.72 52.6962C159.757 52.7859 159.776 52.882 159.776 52.979V52.979C159.776 53.1748 159.698 53.3626 159.56 53.5011C159.421 53.6396 159.233 53.7173 159.038 53.7173H133.742C133.546 53.7168 133.359 53.6389 133.221 53.5005C133.082 53.3622 133.004 53.1747 133.004 52.979Z" fill="#3671C6"/>*/}
            {/*        <path d="M159.038 61.9429C159.445 61.9429 159.776 61.6124 159.776 61.2046C159.776 60.7969 159.445 60.4663 159.038 60.4663H133.742C133.334 60.4663 133.004 60.7969 133.004 61.2046C133.004 61.6124 133.334 61.9429 133.742 61.9429H159.038Z" fill="#3671C6"/>*/}
            {/*        <path d="M145.221 69.6474V71.6163C145.221 72.2634 145.746 72.7881 146.393 72.7881H157.704C158.351 72.7881 158.876 72.2634 158.876 71.6163V69.6474C158.876 69.0003 158.351 68.4756 157.704 68.4756H146.393C145.746 68.4756 145.221 69.0003 145.221 69.6474Z" fill="#03374F"/>*/}
            {/*        <path d="M134.032 69.6474V71.6163C134.032 72.2634 134.557 72.7881 135.204 72.7881H140.562C141.209 72.7881 141.733 72.2634 141.733 71.6163V69.6474C141.733 69.0003 141.209 68.4756 140.562 68.4756H135.204C134.557 68.4756 134.032 69.0003 134.032 69.6474Z" fill="#03374F"/>*/}
            {/*        <path d="M159.776 80.6371V73.3032C159.776 73.2353 159.789 73.1681 159.815 73.1054C159.841 73.0427 159.879 72.9857 159.927 72.9378C159.975 72.8898 160.032 72.8517 160.095 72.8257C160.158 72.7998 160.225 72.7864 160.293 72.7864H162.262C162.329 72.7864 162.397 72.7998 162.459 72.8257C162.522 72.8517 162.579 72.8898 162.627 72.9378C162.675 72.9857 162.713 73.0427 162.739 73.1054C162.765 73.1681 162.778 73.2353 162.778 73.3032V79.1888C162.778 79.7105 162.571 80.2108 162.202 80.5797C161.833 80.9485 161.333 81.1558 160.811 81.1558H160.291C160.223 81.1555 160.156 81.1419 160.093 81.1157C160.031 81.0895 159.974 81.0512 159.926 81.0031C159.878 80.9549 159.84 80.8978 159.814 80.835C159.789 80.7722 159.776 80.7049 159.776 80.6371Z" fill="#448ED8"/>*/}
            {/*        <path d="M6.10055 72.0839C6.10055 71.845 6.1476 71.6084 6.23903 71.3877C6.33045 71.167 6.46446 70.9664 6.63339 70.7975C6.80233 70.6286 7.00288 70.4946 7.2236 70.4031C7.44432 70.3117 7.6809 70.2646 7.91981 70.2646H121.555C122.037 70.2646 122.5 70.4563 122.841 70.7975C123.182 71.1387 123.374 71.6014 123.374 72.0839V72.0839C123.374 72.3227 123.327 72.5592 123.236 72.7798C123.144 73.0004 123.01 73.2009 122.841 73.3697C122.672 73.5384 122.472 73.6723 122.251 73.7635C122.03 73.8547 121.794 73.9015 121.555 73.9013H7.91981C7.68099 73.9015 7.44446 73.8547 7.22375 73.7635C7.00304 73.6723 6.80247 73.5384 6.63351 73.3697C6.46455 73.2009 6.33051 73.0004 6.23906 72.7798C6.14761 72.5592 6.10055 72.3227 6.10055 72.0839V72.0839Z" fill="#ADADAD"/>*/}
            {/*        <path d="M6.10055 9.22014C6.10055 8.98123 6.1476 8.74466 6.23903 8.52394C6.33045 8.30322 6.46446 8.10266 6.63339 7.93373C6.80233 7.76479 7.00288 7.63079 7.2236 7.53936C7.44432 7.44794 7.6809 7.40088 7.91981 7.40088L121.555 7.40088C121.794 7.40088 122.03 7.44794 122.251 7.53936C122.472 7.63079 122.672 7.76479 122.841 7.93373C123.01 8.10266 123.144 8.30322 123.236 8.52394C123.327 8.74466 123.374 8.98123 123.374 9.22014V9.22014C123.373 9.70166 123.18 10.1629 122.839 10.5029C122.498 10.8428 122.036 11.0337 121.555 11.0337L7.91981 11.0337C7.43829 11.0337 6.97641 10.8428 6.63539 10.5029C6.29438 10.1629 6.10205 9.70166 6.10055 9.22014V9.22014Z" fill="#ADADAD"/>*/}
            {/*        <path d="M178.48 66.2722C179.232 64.5436 179.618 62.6785 179.616 60.7935V20.5104C179.618 18.6254 179.232 16.7603 178.48 15.0317H175.101C174.842 15.0315 174.585 15.0823 174.345 15.1814C174.106 15.2804 173.889 15.4257 173.705 15.6089C173.522 15.7922 173.377 16.0097 173.278 16.2492C173.179 16.4886 173.128 16.7452 173.128 17.0043V64.2996C173.128 64.5586 173.179 64.8151 173.278 65.0544C173.377 65.2938 173.523 65.5112 173.706 65.6944C173.889 65.8776 174.106 66.0229 174.346 66.122C174.585 66.2211 174.842 66.2722 175.101 66.2722H178.48Z" fill="#448ED8"/>*/}
            {/*        <path d="M181.457 21.0067V29.5653C181.457 29.7792 181.372 29.9843 181.221 30.1356C181.07 30.2868 180.864 30.3718 180.651 30.3718H179.617L179.617 20.2002H180.651C180.756 20.2002 180.861 20.2211 180.959 20.2616C181.057 20.3021 181.146 20.3615 181.221 20.4364C181.296 20.5113 181.355 20.6002 181.396 20.698C181.436 20.7959 181.457 20.9007 181.457 21.0067V21.0067Z" fill="#CECDC6"/>*/}
            {/*        <path d="M181.457 51.7352V60.2939C181.457 60.5078 181.372 60.7129 181.221 60.8642C181.07 61.0154 180.864 61.1004 180.651 61.1004H179.617L179.617 50.9307H180.651C180.864 50.9307 181.069 51.0154 181.22 51.1662C181.371 51.317 181.457 51.5217 181.457 51.7352V51.7352Z" fill="#CECDC6"/>*/}
            {/*    </svg>*/}
            {/*</div>*/}

            {/*<svg width="1091" height="403" viewBox="0 0 1091 403" fill="none" xmlns="http://www.w3.org/2000/svg"*/}
            {/*     id={"third-section-path"}>*/}
            {/*    <path d="M0.185913 382.384H935.434C1009.99 382.384 1070.43 321.942 1070.43 247.384V0.936523"*/}
            {/*          stroke="#DADADA" strokeOpacity="0.45" strokeWidth="40" ref={pathRef2}/>*/}
            {/*</svg>*/}
            <div className={"empty-spacer"}/>
            <div className={"about-us-container"}>
                <div className={"about-us-left-section"} >
                    <div className={"about-us-image-section"} data-aos="fade-right"
                         data-aos-duration="1000"
                         data-aos-easing="linear"
                         data-aos-once="true"
                    >
                        <img src={AboutUsBG} alt={"About Us"} className={"img-fluid about-us-bg"}/>
                        <img src={AboutUsImg} alt={"About Us"} className={"img-fluid about-us-img"}/>
                        <Link to={"/aboutus"}>
                            <button type={"button"} className={"btn view-more-btn"}>
                                View More
                            </button>
                        </Link>
                    </div>
                </div>
                <div className={"about-us-right-section"}>
                    <div className={"about-us-content-section"}  data-aos="fade-up"
                         data-aos-duration="1000"
                         data-aos-easing="linear"
                         data-aos-once="true"
                    >
                        <div className={"about-content-wrapper"}>
                            <p className={"title"}>About Us</p>
                            <p className={"description"}>
                                PRM Roadways, a 3rd generation dynamic and persevering Enterprise, providing gamut of customer focussed strategic mobility and logistics solutions since 1970 with ISO certification with hubs in Tamilnadu, Andhra Pradesh, Kerala, Karnataka and Pondicherry. We provide quality solutions for any type of transportation needs in South India. Our services have evolved in response to the specialized needs of the clients we serve. We continue to adapt to the ever-changing demands and regulations placed in the transportation industry
                            </p>
                            <Link to={"/aboutus"}>
                                <button type={"button"} className={"btn view-more-btn"}>
                                    View More
                                </button>
                            </Link>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
}

const Facilities = (props) => {
    const busRef3 = useRef();
    const pathRef3 = useRef();

    useEffect(() => {
        gsap.to(busRef3.current, {
            motionPath: {
                path: pathRef3.current,
                align: pathRef3.current,
                autoRotate: true,
                immediateRender: true,
                alignOrigin: [0.5, 0.5],
            }
        });
        gsap.timeline({
            scrollTrigger: {
                trigger: '.facilities-section',
                start: 'top 80%',
                end: 'bottom top',
                toggleClass:"active",
                scrub: 0.3,
                onUpdate: ({progress}) => console.log(progress) //  info for position
            },
            defaults: {duration: 3, ease: 'none'}
        })
            .to(busRef3.current, {
                motionPath: {
                    path: pathRef3.current,
                    align: pathRef3.current,
                    paused: true,
                    ease: "none",
                    autoRotate: true,
                    immediateRender: true,
                    alignOrigin: [0.5, 0.5],
                }
            });
    });

    return (
        <div className={"facilities-section"} >
            <div ref={busRef3} id={"busref3"}>
                <svg width="182" height="82" viewBox="0 0 182 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M179.617 20.5106V60.7957C179.617 64.4393 178.17 67.9336 175.593 70.51C173.017 73.0864 169.523 74.5339 165.879 74.5339H131.815C131.205 74.5339 130.62 74.2915 130.188 73.8602C129.757 73.4288 129.515 72.8438 129.515 72.2337V9.07062C129.515 8.46059 129.757 7.87555 130.188 7.4442C130.62 7.01284 131.205 6.77051 131.815 6.77051L165.879 6.77051C167.683 6.77051 169.47 7.12592 171.137 7.81645C172.804 8.50697 174.318 9.51909 175.594 10.795C176.87 12.0709 177.882 13.5856 178.572 15.2526C179.262 16.9196 179.617 18.7063 179.617 20.5106V20.5106Z" fill="#3671C6"/>
                    <path d="M171.402 18.2765V63.0255C171.402 64.6321 170.764 66.173 169.628 67.3091C168.492 68.4451 166.951 69.0834 165.344 69.0834H165.019C164.672 69.0834 164.339 68.9456 164.094 68.7002C163.849 68.4549 163.711 68.1222 163.711 67.7752V13.521C163.711 13.1741 163.849 12.8414 164.094 12.596C164.339 12.3507 164.672 12.2129 165.019 12.2129H165.339C166.945 12.2129 168.486 12.8511 169.622 13.9872C170.758 15.1233 171.397 16.6641 171.397 18.2708L171.402 18.2765Z" fill="#03374F"/>
                    <path d="M158.878 11.6568V9.68794C158.878 9.04076 158.354 8.51611 157.707 8.51611H146.395C145.748 8.51611 145.224 9.04076 145.224 9.68794V11.6568C145.224 12.3039 145.748 12.8286 146.395 12.8286H157.707C158.354 12.8286 158.878 12.3039 158.878 11.6568Z" fill="#03374F"/>
                    <path d="M141.732 11.6568V9.68794C141.732 9.04076 141.207 8.51611 140.56 8.51611H135.203C134.555 8.51611 134.031 9.04076 134.031 9.68794V11.6568C134.031 12.3039 134.555 12.8286 135.203 12.8286H140.56C141.207 12.8286 141.732 12.3039 141.732 11.6568Z" fill="#03374F"/>
                    <path d="M162.78 2.11536V8.00478C162.78 8.14185 162.726 8.27331 162.629 8.37023C162.532 8.46715 162.401 8.5216 162.263 8.5216H160.295C160.158 8.5206 160.028 8.46571 159.932 8.3689C159.836 8.27208 159.782 8.1412 159.782 8.00478V0.665253C159.782 0.529163 159.835 0.398563 159.931 0.301803C160.027 0.205042 160.157 0.149933 160.293 0.148438L160.813 0.148438C161.335 0.148438 161.835 0.355667 162.204 0.724537C162.573 1.09341 162.78 1.5937 162.78 2.11536V2.11536Z" fill="#448ED8"/>
                    <path d="M162.4 65.0799V16.2229C162.4 15.1732 161.549 14.3223 160.5 14.3223L132.876 14.3223C131.826 14.3223 130.975 15.1732 130.975 16.2229V65.0799C130.975 66.1297 131.826 66.9806 132.876 66.9806H160.5C161.549 66.9806 162.4 66.1297 162.4 65.0799Z" fill="#448ED8"/>
                    <path d="M129.514 64.0099V17.2939H127.255V64.0099H129.514Z" fill="#3F3F3F"/>
                    <path d="M127.254 75.7643V5.54183C127.254 4.95215 126.776 4.47412 126.187 4.47412L1.52445 4.47412C0.934776 4.47412 0.456757 4.95215 0.456757 5.54183V75.7643C0.456757 76.354 0.934776 76.832 1.52445 76.832H126.187C126.776 76.832 127.254 76.354 127.254 75.7643Z" fill="#E2E2E2"/>
                    <path d="M133.004 20.0996C133.004 19.9038 133.082 19.716 133.22 19.5776C133.358 19.4391 133.546 19.3613 133.742 19.3613H159.038C159.135 19.3611 159.231 19.38 159.32 19.417C159.41 19.454 159.492 19.5084 159.56 19.577C159.629 19.6456 159.683 19.7271 159.72 19.8168C159.757 19.9065 159.776 20.0026 159.776 20.0996V20.0996C159.776 20.1966 159.757 20.2926 159.72 20.3822C159.683 20.4718 159.628 20.5531 159.56 20.6217C159.491 20.6903 159.41 20.7446 159.32 20.7817C159.231 20.8188 159.135 20.8379 159.038 20.8379H133.742C133.546 20.8374 133.359 20.7595 133.221 20.6211C133.082 20.4828 133.004 20.2953 133.004 20.0996V20.0996Z" fill="#3671C6"/>
                    <path d="M133.004 28.3252C133.004 28.1296 133.082 27.9421 133.221 27.8037C133.359 27.6654 133.546 27.5874 133.742 27.5869H159.038C159.233 27.5869 159.421 27.6647 159.56 27.8032C159.698 27.9416 159.776 28.1294 159.776 28.3252V28.3252C159.776 28.521 159.698 28.7088 159.56 28.8473C159.421 28.9857 159.233 29.0635 159.038 29.0635H133.742C133.546 29.063 133.359 28.9851 133.221 28.8467C133.082 28.7084 133.004 28.5209 133.004 28.3252V28.3252Z" fill="#3671C6"/>
                    <path d="M133.004 36.5508C133.004 36.3551 133.082 36.1676 133.221 36.0293C133.359 35.8909 133.546 35.813 133.742 35.8125H159.038C159.233 35.8125 159.421 35.8903 159.56 36.0287C159.698 36.1672 159.776 36.355 159.776 36.5508V36.5508C159.776 36.6478 159.757 36.744 159.72 36.8336C159.683 36.9233 159.629 37.0048 159.56 37.0734C159.492 37.142 159.41 37.1964 159.32 37.2334C159.231 37.2704 159.135 37.2894 159.038 37.2891H133.742C133.546 37.2891 133.358 37.2113 133.22 37.0729C133.082 36.9344 133.004 36.7466 133.004 36.5508Z" fill="#3671C6"/>
                    <path d="M133.004 44.7783C133.004 44.5825 133.082 44.3947 133.22 44.2563C133.358 44.1178 133.546 44.04 133.742 44.04H159.038C159.135 44.0398 159.231 44.0587 159.32 44.0957C159.41 44.1327 159.492 44.1871 159.56 44.2557C159.629 44.3243 159.683 44.4058 159.72 44.4955C159.757 44.5852 159.776 44.6813 159.776 44.7783V44.7783C159.776 44.9742 159.698 45.162 159.56 45.3004C159.421 45.4389 159.233 45.5167 159.038 45.5167H133.742C133.546 45.5162 133.359 45.4382 133.221 45.2999C133.082 45.1615 133.004 44.974 133.004 44.7783V44.7783Z" fill="#3671C6"/>
                    <path d="M133.004 52.979C133.004 52.7832 133.082 52.5954 133.22 52.457C133.358 52.3185 133.546 52.2407 133.742 52.2407L159.038 52.2407C159.135 52.2405 159.231 52.2594 159.32 52.2964C159.41 52.3334 159.492 52.3878 159.56 52.4564C159.629 52.525 159.683 52.6065 159.72 52.6962C159.757 52.7859 159.776 52.882 159.776 52.979V52.979C159.776 53.1748 159.698 53.3626 159.56 53.5011C159.421 53.6396 159.233 53.7173 159.038 53.7173H133.742C133.546 53.7168 133.359 53.6389 133.221 53.5005C133.082 53.3622 133.004 53.1747 133.004 52.979Z" fill="#3671C6"/>
                    <path d="M159.038 61.9429C159.445 61.9429 159.776 61.6124 159.776 61.2046C159.776 60.7969 159.445 60.4663 159.038 60.4663H133.742C133.334 60.4663 133.004 60.7969 133.004 61.2046C133.004 61.6124 133.334 61.9429 133.742 61.9429H159.038Z" fill="#3671C6"/>
                    <path d="M145.221 69.6474V71.6163C145.221 72.2634 145.746 72.7881 146.393 72.7881H157.704C158.351 72.7881 158.876 72.2634 158.876 71.6163V69.6474C158.876 69.0003 158.351 68.4756 157.704 68.4756H146.393C145.746 68.4756 145.221 69.0003 145.221 69.6474Z" fill="#03374F"/>
                    <path d="M134.032 69.6474V71.6163C134.032 72.2634 134.557 72.7881 135.204 72.7881H140.562C141.209 72.7881 141.733 72.2634 141.733 71.6163V69.6474C141.733 69.0003 141.209 68.4756 140.562 68.4756H135.204C134.557 68.4756 134.032 69.0003 134.032 69.6474Z" fill="#03374F"/>
                    <path d="M159.776 80.6371V73.3032C159.776 73.2353 159.789 73.1681 159.815 73.1054C159.841 73.0427 159.879 72.9857 159.927 72.9378C159.975 72.8898 160.032 72.8517 160.095 72.8257C160.158 72.7998 160.225 72.7864 160.293 72.7864H162.262C162.329 72.7864 162.397 72.7998 162.459 72.8257C162.522 72.8517 162.579 72.8898 162.627 72.9378C162.675 72.9857 162.713 73.0427 162.739 73.1054C162.765 73.1681 162.778 73.2353 162.778 73.3032V79.1888C162.778 79.7105 162.571 80.2108 162.202 80.5797C161.833 80.9485 161.333 81.1558 160.811 81.1558H160.291C160.223 81.1555 160.156 81.1419 160.093 81.1157C160.031 81.0895 159.974 81.0512 159.926 81.0031C159.878 80.9549 159.84 80.8978 159.814 80.835C159.789 80.7722 159.776 80.7049 159.776 80.6371Z" fill="#448ED8"/>
                    <path d="M6.10055 72.0839C6.10055 71.845 6.1476 71.6084 6.23903 71.3877C6.33045 71.167 6.46446 70.9664 6.63339 70.7975C6.80233 70.6286 7.00288 70.4946 7.2236 70.4031C7.44432 70.3117 7.6809 70.2646 7.91981 70.2646H121.555C122.037 70.2646 122.5 70.4563 122.841 70.7975C123.182 71.1387 123.374 71.6014 123.374 72.0839V72.0839C123.374 72.3227 123.327 72.5592 123.236 72.7798C123.144 73.0004 123.01 73.2009 122.841 73.3697C122.672 73.5384 122.472 73.6723 122.251 73.7635C122.03 73.8547 121.794 73.9015 121.555 73.9013H7.91981C7.68099 73.9015 7.44446 73.8547 7.22375 73.7635C7.00304 73.6723 6.80247 73.5384 6.63351 73.3697C6.46455 73.2009 6.33051 73.0004 6.23906 72.7798C6.14761 72.5592 6.10055 72.3227 6.10055 72.0839V72.0839Z" fill="#ADADAD"/>
                    <path d="M6.10055 9.22014C6.10055 8.98123 6.1476 8.74466 6.23903 8.52394C6.33045 8.30322 6.46446 8.10266 6.63339 7.93373C6.80233 7.76479 7.00288 7.63079 7.2236 7.53936C7.44432 7.44794 7.6809 7.40088 7.91981 7.40088L121.555 7.40088C121.794 7.40088 122.03 7.44794 122.251 7.53936C122.472 7.63079 122.672 7.76479 122.841 7.93373C123.01 8.10266 123.144 8.30322 123.236 8.52394C123.327 8.74466 123.374 8.98123 123.374 9.22014V9.22014C123.373 9.70166 123.18 10.1629 122.839 10.5029C122.498 10.8428 122.036 11.0337 121.555 11.0337L7.91981 11.0337C7.43829 11.0337 6.97641 10.8428 6.63539 10.5029C6.29438 10.1629 6.10205 9.70166 6.10055 9.22014V9.22014Z" fill="#ADADAD"/>
                    <path d="M178.48 66.2722C179.232 64.5436 179.618 62.6785 179.616 60.7935V20.5104C179.618 18.6254 179.232 16.7603 178.48 15.0317H175.101C174.842 15.0315 174.585 15.0823 174.345 15.1814C174.106 15.2804 173.889 15.4257 173.705 15.6089C173.522 15.7922 173.377 16.0097 173.278 16.2492C173.179 16.4886 173.128 16.7452 173.128 17.0043V64.2996C173.128 64.5586 173.179 64.8151 173.278 65.0544C173.377 65.2938 173.523 65.5112 173.706 65.6944C173.889 65.8776 174.106 66.0229 174.346 66.122C174.585 66.2211 174.842 66.2722 175.101 66.2722H178.48Z" fill="#448ED8"/>
                    <path d="M181.457 21.0067V29.5653C181.457 29.7792 181.372 29.9843 181.221 30.1356C181.07 30.2868 180.864 30.3718 180.651 30.3718H179.617L179.617 20.2002H180.651C180.756 20.2002 180.861 20.2211 180.959 20.2616C181.057 20.3021 181.146 20.3615 181.221 20.4364C181.296 20.5113 181.355 20.6002 181.396 20.698C181.436 20.7959 181.457 20.9007 181.457 21.0067V21.0067Z" fill="#CECDC6"/>
                    <path d="M181.457 51.7352V60.2939C181.457 60.5078 181.372 60.7129 181.221 60.8642C181.07 61.0154 180.864 61.1004 180.651 61.1004H179.617L179.617 50.9307H180.651C180.864 50.9307 181.069 51.0154 181.22 51.1662C181.371 51.317 181.457 51.5217 181.457 51.7352V51.7352Z" fill="#CECDC6"/>
                </svg>
            </div>

            <svg width="41" height="547" viewBox="0 0 41 547" fill="none" xmlns="http://www.w3.org/2000/svg"
                 id={"fourth-section-path"}>
                <path d="M20.6052 0.120728V546.515" stroke="#DADADA" strokeOpacity="0.45" strokeWidth="40"
                      ref={pathRef3}/>
            </svg>
            <div className={"empty-spacer"}/>
            <div>
                <div  data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-easing="linear"
                      data-aos-once="true"
                >
                <p className={"title"}>Our Facilities</p>
                <p className={"description"}>
                    We have OEM supported State of the art Vehicle Service Centre, equipped with modern tools, advanced equipment and expert staff. We are operating multiple Maintenance Hubs for site-based maintenance and operating Mobile maintenance vans.  We ensure that our clients and customers receive prompt and secure transportation best in class maintenance services.

                </p>
                </div>
                <div className={"facility-list"}>
                    <div className={"facility-item"}
                         data-aos="zoom-in"
                         data-aos-duration="1000"
                         data-aos-easing="linear"
                         data-aos-once="true"
                    >
                        <div>
                            <img src={OilService} alt={"Oil Service"} className={"img-fluid facility-icon"}/>
                            <p className={"facility-name"}>Oil Service</p>
                        </div>
                    </div>
                    <div className={"facility-item"}
                         data-aos="zoom-out"
                         data-aos-duration="1000"
                         data-aos-easing="linear"
                         data-aos-once="true"
                    >
                        <div>
                            <img src={MaintananceService} alt={"Maintenance Service"}
                                 className={"img-fluid facility-icon"}/>
                            <p className={"facility-name"}>Maintenance Service</p>
                        </div>
                    </div>
                    <div className={"facility-item"}
                         data-aos="zoom-in"
                         data-aos-duration="1000"
                         data-aos-easing="linear"
                         data-aos-once="true"
                    >
                        <div>
                            <img src={PaintShop} alt={"Paint Shop"} className={"img-fluid facility-icon"}/>
                            <p className={"facility-name"}>Paint Shop</p>
                        </div>
                    </div>
                    <div className={"facility-item"} data-aos="zoom-in"
                         data-aos-duration="1000"
                         data-aos-easing="linear"
                         data-aos-once="true"
                    >
                        <div>
                            <img src={WaterWash} alt={"Pressure Water Wash"} className={"img-fluid facility-icon"}/>
                            <p className={"facility-name"}>Pressure Water Wash</p>
                        </div>
                    </div>
                    <div className={"facility-item"} data-aos="zoom-out"
                         data-aos-duration="1000"
                         data-aos-easing="linear"
                         data-aos-once="true"
                    >
                        <div>
                            <img src={CraneLifting} alt={"Crane Lifting"} className={"img-fluid facility-icon"}/>
                            <p className={"facility-name"}>Crane Lifting</p>
                        </div>
                    </div>
                    <div className={"facility-item"} data-aos="zoom-in"
                         data-aos-duration="1000"
                         data-aos-easing="linear"
                         data-aos-once="true"
                    >
                        <div>
                            <img src={WhealMaintanance} alt={"Wheel Maintenance"}
                                 className={"img-fluid facility-icon"}/>
                            <p className={"facility-name"}>Wheel Maintenance</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const TestimonialsAndManagement = (props) => {
    const busRef4 = useRef();
    const pathRef4 = useRef();

    useEffect(() => {
        gsap.to(busRef4.current, {
            motionPath: {
                path: pathRef4.current,
                align: pathRef4.current,
                autoRotate: true,
                immediateRender: true,
                alignOrigin: [0.5, 0.5],
            }
        });
        gsap.timeline({
            scrollTrigger: {
                trigger: '.testimonials-and-management',
                start: 'top 80%',
                end: 'bottom center',
                toggleClass:"active",
                scrub: 0.3,
                onUpdate: ({progress}) => console.log(progress) //  info for position
            },
            defaults: {duration: 5, ease: 'none'}
        })
            .to(busRef4.current, {
                motionPath: {
                    path: pathRef4.current,
                    align: pathRef4.current,
                    paused: true,
                    ease: "none",
                    autoRotate: true,
                    immediateRender: true,
                    alignOrigin: [0.5, 0.5],
                }
            });

        document.addEventListener("scroll", function () {
            let next_section = document.getElementById("awards-and-recognition-section");
            if(busRef4.current){
                if(next_section && next_section.classList.contains('active')) {
                    busRef4.current.style.opacity = 0;
                } else {
                    busRef4.current.style.opacity = 1;
                }
            }
        });

    });

    return (
        <div className={"testimonials-and-management"} >
            <div ref={busRef4} id={"busref4"}>
                <svg width="209" height="62" viewBox="0 0 209 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9.7176 51.4176C10.0657 56.3888 13.6083 58.6425 16.9251 58.6425H193.061V61.6052C193.061 61.7099 193.103 61.8103 193.177 61.8844C193.251 61.9584 193.351 62 193.455 62C193.56 62 193.66 61.9584 193.734 61.8844C193.808 61.8103 193.849 61.7099 193.849 61.6052V60.1066H195.506C196.005 60.1385 196.501 60.0149 196.927 59.7528C197.354 59.4906 197.688 59.1028 197.885 58.6425H198.138C201.443 58.6425 205.586 53.5486 207.113 48.7653C207.113 48.7653 208.643 45.3924 208.643 34.7678C208.643 24.1432 207.113 21.4181 207.113 21.4181C206.057 17.409 201.294 11.5371 198.138 11.5371H197.885C197.688 11.0768 197.354 10.6889 196.927 10.4268C196.501 10.1646 196.005 10.041 195.506 10.073H193.849V8.5705C193.849 8.4658 193.808 8.36538 193.734 8.29135C193.66 8.21731 193.56 8.17572 193.455 8.17572C193.351 8.17572 193.251 8.21731 193.177 8.29135C193.103 8.36538 193.061 8.4658 193.061 8.5705V11.5371H16.9251C13.7651 11.5371 10.0657 13.1775 9.7176 18.7543C9.7176 18.7543 9.57605 24.1202 9.57605 34.764C9.57605 45.4077 9.7176 51.4176 9.7176 51.4176Z"
                        fill="#B7C0C4"/>
                    <path
                        d="M0.359932 43.2421C0.708065 48.2248 4.26208 50.4632 7.56743 50.4632H188.78C192.086 50.4632 196.229 45.3732 197.755 40.586C197.755 40.586 199.285 37.2131 199.285 26.5885C199.285 15.9639 197.755 13.2388 197.755 13.2388C196.699 9.2335 191.936 3.36162 188.78 3.36162H7.56743C4.42658 3.36162 0.708065 5.00207 0.359932 10.5827C0.359932 10.5827 0.218384 15.9486 0.218384 26.5885C0.218384 37.2284 0.359932 43.2421 0.359932 43.2421Z"
                        fill="#005B8D"/>
                    <path
                        d="M189.718 5.45821C191.302 5.45821 195.514 10.391 196.447 13.7333C196.466 13.8099 197.882 16.4162 197.882 26.5809C197.882 36.8184 196.447 40.0878 196.428 40.1452C194.653 45.7105 190.49 48.3666 189.718 48.3666H6.59597C5.83084 48.3666 1.87131 48.428 1.68385 42.7899C1.68385 42.7439 1.62646 36.8184 1.62646 26.5809C1.62646 16.4201 1.6609 11.1538 1.6609 11.1001C1.69533 7.35929 3.71144 5.79166 6.59597 5.45821H189.718Z"
                        fill="#0063A6"/>
                    <path
                        d="M189.717 6.73869C190.762 6.73869 194.438 11.2844 195.215 14.0785C195.41 14.6995 196.603 17.187 196.603 26.5812C196.603 36.5465 195.222 39.7124 195.207 39.7699C193.979 43.6027 191.542 47.0868 189.717 47.0868H6.59538C5.13781 47.0868 2.93807 45.5843 2.89981 42.3992V26.5812C2.89599 16.75 2.87303 11.5642 2.87303 11.4991C2.84625 8.68193 5.16841 6.66587 6.59538 6.72719L189.717 6.73869Z"
                        fill="#0075B3"/>
                    <path d="M182.732 43.9823C182.732 43.9823 184.308 25.9681 182.732 10.1615H177.376V43.9823H182.732Z"
                          fill="url(#paint0_linear_1097_44670)"/>
                    <path
                        d="M183.543 43.8439L189.664 45.1854C190.341 45.0206 192.273 42.7516 193.402 39.2101L193.429 39.1219L193.455 39.0529C193.585 38.7233 194.714 35.5804 194.714 26.5771C194.714 21.0731 194.308 17.1483 193.505 14.91C193.467 14.8065 193.436 14.7183 193.398 14.588C192.847 12.6141 190.379 9.40988 189.354 8.63948L183.543 9.96564C183.543 9.96564 184.343 13.6681 184.343 26.83C184.343 39.992 183.543 43.8439 183.543 43.8439Z"
                        fill="#072129"/>
                    <path
                        d="M188.749 10.6019C187.912 13.6413 187.483 18.9766 187.483 26.4583C187.483 38.6965 188.669 42.9662 188.803 43.4147L188.83 43.5066L188.86 43.6255C188.987 44.1352 189.121 44.6297 189.266 45.1011L189.648 45.1854C190.326 45.0206 192.258 42.7516 193.386 39.2101L193.413 39.1219L193.44 39.0529C193.57 38.7233 194.698 35.5804 194.698 26.5771C194.698 21.0731 194.293 17.1483 193.489 14.91C193.451 14.8065 193.421 14.7183 193.382 14.588C192.831 12.6141 190.364 9.40988 189.339 8.63948C189.147 9.13834 188.992 9.65079 188.876 10.1726C188.822 10.3489 188.792 10.4562 188.749 10.6019Z"
                        fill="#25333A"/>
                    <path
                        d="M183.543 43.8441L185.903 44.3615C186.087 43.1235 186.646 38.4015 186.646 26.8379C186.646 15.2743 186.079 10.6136 185.9 9.43689L183.543 9.96582C183.543 9.96582 184.343 13.6683 184.343 26.8302C184.343 39.9921 183.543 43.8441 183.543 43.8441Z"
                        fill="#D5EEF2"/>
                    <path d="M187.614 46.3966H8.43982V45.4537H181.837L187.614 46.3966Z" fill="#25333A"/>
                    <path d="M163.018 8.36758H181.836L187.613 7.42854H163.018V8.36758Z" fill="#25333A"/>
                    <path d="M141.335 7.42854H48.3871V8.37142H141.335V7.42854Z" fill="#25333A"/>
                    <path d="M26.7072 7.42854H8.43982V8.37142H26.7072V7.42854Z" fill="#25333A"/>
                    <path
                        d="M117.401 20.6322H97.5462C96.9821 20.6322 96.5248 21.0904 96.5248 21.6556V32.169C96.5248 32.7342 96.9821 33.1924 97.5462 33.1924H117.401C117.965 33.1924 118.423 32.7342 118.423 32.169V21.6556C118.423 21.0904 117.965 20.6322 117.401 20.6322Z"
                        fill="#0063A6"/>
                    <path d="M97.4279 21.5293V32.2842H114.425V21.5293H97.4279Z" fill="#0075B3"/>
                    <path d="M97.4279 23.0588V30.7551H112.619V23.0588H97.4279Z" fill="url(#paint1_linear_1097_44670)"/>
                    <path
                        d="M116.181 32.2807H117.788V21.5488H116.181C116.027 21.5498 115.88 21.6115 115.771 21.7205C115.662 21.8296 115.601 21.9772 115.6 22.1314V31.7134C115.605 31.8649 115.668 32.0087 115.776 32.1144C115.885 32.2202 116.03 32.2798 116.181 32.2807Z"
                        fill="#072129"/>
                    <path
                        d="M78.8583 20.6322H59.0033C58.4391 20.6322 57.9818 21.0904 57.9818 21.6556V32.169C57.9818 32.7342 58.4391 33.1924 59.0033 33.1924H78.8583C79.4224 33.1924 79.8798 32.7342 79.8798 32.169V21.6556C79.8798 21.0904 79.4224 20.6322 78.8583 20.6322Z"
                        fill="#0063A6"/>
                    <path d="M58.8842 21.5332V32.2881H75.8814V21.5332H58.8842Z" fill="#0075B3"/>
                    <path d="M58.881 23.0588V30.7551H74.0726V23.0588H58.881Z" fill="url(#paint2_linear_1097_44670)"/>
                    <path
                        d="M77.638 32.2807H79.2448V21.5488H77.638C77.4838 21.5488 77.3359 21.6102 77.2268 21.7194C77.1178 21.8287 77.0565 21.9769 77.0565 22.1314V31.7134C77.0615 31.8649 77.1248 32.0087 77.2332 32.1144C77.3416 32.2202 77.4867 32.2798 77.638 32.2807Z"
                        fill="#072129"/>
                    <path
                        d="M40.3146 20.6322H20.4596C19.8954 20.6322 19.4381 21.0904 19.4381 21.6556V32.169C19.4381 32.7342 19.8954 33.1924 20.4596 33.1924H40.3146C40.8787 33.1924 41.3361 32.7342 41.3361 32.169V21.6556C41.3361 21.0904 40.8787 20.6322 40.3146 20.6322Z"
                        fill="#0063A6"/>
                    <path d="M20.3373 21.5332V32.2881H37.3346V21.5332H20.3373Z" fill="#0075B3"/>
                    <path d="M20.3373 23.0588V30.7551H35.5289V23.0588H20.3373Z" fill="url(#paint3_linear_1097_44670)"/>
                    <path
                        d="M39.0942 32.2807H40.701V21.5488H39.0942C38.9397 21.5488 38.7914 21.61 38.6817 21.7192C38.572 21.8283 38.5099 21.9765 38.5089 22.1314V31.7134C38.5148 31.8653 38.579 32.009 38.688 32.1147C38.797 32.2203 38.9425 32.2798 39.0942 32.2807Z"
                        fill="#072129"/>
                    <path
                        d="M174.112 13.6487H131.327C130.369 13.6487 129.594 14.4261 129.594 15.385V38.4394C129.594 39.3983 130.369 40.1757 131.327 40.1757H174.112C175.07 40.1757 175.845 39.3983 175.845 38.4394V15.385C175.845 14.4261 175.07 13.6487 174.112 13.6487Z"
                        fill="#0063A6"/>
                    <path d="M131.496 15.5387V38.2596H171.255V15.5387L131.496 15.5387Z" fill="#0075B3"/>
                    <path d="M131.496 17.0454V36.7653H168.532V17.0454L131.496 17.0454Z"
                          fill="url(#paint4_linear_1097_44670)"/>
                    <path
                        d="M172.877 38.2711H174.533V27.1751H172.877C172.718 27.1751 172.565 27.2385 172.452 27.3514C172.34 27.4642 172.276 27.6173 172.276 27.7769V37.6694C172.276 37.829 172.34 37.982 172.452 38.0949C172.565 38.2077 172.718 38.2711 172.877 38.2711Z"
                        fill="#072129"/>
                    <path
                        d="M172.877 26.6428H174.533V15.5276H172.877C172.718 15.5276 172.565 15.591 172.452 15.7039C172.34 15.8167 172.276 15.9698 172.276 16.1294V26.0372C172.276 26.1165 172.291 26.1952 172.321 26.2687C172.351 26.3421 172.395 26.4089 172.451 26.4652C172.507 26.5215 172.573 26.5661 172.646 26.5966C172.719 26.6271 172.798 26.6428 172.877 26.6428Z"
                        fill="#072129"/>
                    <path
                        d="M184.098 53.8248C184.199 53.8248 184.296 53.7844 184.368 53.7125C184.44 53.6407 184.48 53.5432 184.48 53.4415V51.939H186.144C188.229 51.939 188.78 50.2104 188.78 49.2982V46.485H188.111V49.2944C188.111 49.4975 188.042 51.2683 186.144 51.2683H184.488V49.8272C184.488 49.7225 184.446 49.6221 184.372 49.548C184.299 49.474 184.198 49.4324 184.094 49.4324C183.989 49.4324 183.889 49.474 183.815 49.548C183.741 49.6221 183.7 49.7225 183.7 49.8272V53.43C183.698 53.4826 183.707 53.535 183.727 53.5839C183.746 53.6328 183.776 53.6771 183.813 53.7142C183.85 53.7512 183.895 53.7801 183.944 53.7992C183.993 53.8182 184.045 53.8269 184.098 53.8248Z"
                        fill="#0075B3"/>
                    <path
                        d="M184.098 0.00030677C184.2 0.00030677 184.297 0.0406882 184.369 0.112567C184.44 0.184447 184.481 0.281936 184.481 0.383589V1.91672H186.145C188.23 1.91672 188.781 3.64532 188.781 4.55753V7.37082H188.111V4.5422C188.111 4.33906 188.042 2.5683 186.145 2.5683H184.488V3.99794C184.488 4.10264 184.447 4.20305 184.373 4.27709C184.299 4.35113 184.199 4.39272 184.094 4.39272C183.99 4.39272 183.89 4.35113 183.816 4.27709C183.742 4.20305 183.7 4.10264 183.7 3.99794V0.383589C183.7 0.331939 183.711 0.280814 183.731 0.233283C183.751 0.185753 183.781 0.142799 183.818 0.107C183.855 0.0712008 183.899 0.043296 183.947 0.0249614C183.995 0.00662688 184.047 -0.00175924 184.098 0.00030677Z"
                        fill="#0075B3"/>
                    <defs>
                        <linearGradient id="paint0_linear_1097_44670" x1="177.636" y1="27.0719" x2="182.674"
                                        y2="27.0719"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0075B3"/>
                            <stop offset="0.39" stopColor="#007DB9"/>
                            <stop offset="1" stopColor="#0093C9"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_1097_44670" x1="6451.94" y1="4782.56" x2="6756.98"
                                        y2="4782.56"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0075B3"/>
                            <stop offset="0.39" stopColor="#007DB9"/>
                            <stop offset="1" stopColor="#0093C9"/>
                        </linearGradient>
                        <linearGradient id="paint2_linear_1097_44670" x1="5639.43" y1="4782.57" x2="5944.48"
                                        y2="4782.57"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0075B3"/>
                            <stop offset="0.39" stopColor="#007DB9"/>
                            <stop offset="1" stopColor="#0093C9"/>
                        </linearGradient>
                        <linearGradient id="paint3_linear_1097_44670" x1="4826.86" y1="4782.57" x2="5131.91"
                                        y2="4782.57"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0075B3"/>
                            <stop offset="0.39" stopColor="#007DB9"/>
                            <stop offset="1" stopColor="#0093C9"/>
                        </linearGradient>
                        <linearGradient id="paint4_linear_1097_44670" x1="18525.5" y1="11757.7" x2="20431" y2="11757.7"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0075B3"/>
                            <stop offset="0.39" stopColor="#007DB9"/>
                            <stop offset="1" stopColor="#0093C9"/>
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <div id={"fifth-section-path"}>
                <svg width="41" height="1114" viewBox="0 0 41 1114" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.7505 0V1113.5" stroke="#DADADA" strokeOpacity="0.45" strokeWidth="40" ref={pathRef4}/>
                </svg>
            </div>
            <div className={"empty-spacer"} />
            <Testimonials/>
            <Management/>
        </div>
    );
}

const Testimonials = (props) => {

    const testimonial_cards = [
        <TestimonialCard authour={"Saint Gobain"}
                         data={"We appreciate the PRM Roadways team for consistent service and support for almost two decades in smoothly managing our Employee Transport services with at most care and commitment. During pandemic support was excellent and all driver and vehicle are well maintained as per Covid safety protocols."}
                         rating={"4.9"}
        />,
        // <TestimonialCard authour={"Samsung"}
        //                  data={"Would like to thank PRM Team for ensuring 24 x 7 Employee transport service and catering to all our requests swiftly and safely on various occasions with committed and coordinated team work"}
        //                  rating={"4.3"}
        // />,
        // <TestimonialCard authour={"Pegatron"}
        //                  data={"Happy to have PRM Roadways for our Employee Mobility service for their commendable service and support extended with short notice in the need of the hour. Wishing them all the very best"}
        //                  rating={"4.7"}
        // />,
        <TestimonialCard authour={"Hyundai"}
                         data={"Happy to see the growth of PRM with yet another milestone. Your immense support has always been proven that PRM stands with their clients in any circumstances. Safe and Secured Transportation with Robust support and coordination. Played an key role and stood as an Trend setter by operating Employee Transportation during Covid Crisis situation. Handled very efficiently many disasters like Chennai Flood & Varda cyclone etc by reaching HMI employees safe to their residences."}
                         rating={"4.3"}
        />,
        <TestimonialCard authour={"Saint Gobain Glass"}
                         data={"I have been informed by our Purchase Team that you have taken timely and appropriate steps to transport sand from Tada to our Sriperumbudur Plant.\n" +
                         "I greately appreciate your commitment and dedication in arranging to fulfill our emergency requirements of sand and I thank you for the excellent support that you have extended.\n" +
                         "I am pleased to present you with a Gold Coin and a Memento for your dedicated efforts.\n" +
                         "Looking forward to your continued support."}
                         rating={"4.9"}
        />,
        <TestimonialCard authour={"INDSIL Electromelts Limited"}
                         data={"PRM has been regularly transporting raw materials to our factory from far off places like karnataka, Andhra Pradesh, Madhya Pradesh, etc. Their work is satisfactory.\n" +
                         "We confidently recommend them for transportation since they are reliable and efficient."}
                         rating={"4.9"}
        />,
        // <TestimonialCard authour={"Flex"}
        //                  data={"With close and regular interaction, we found PRM Roadways team is more sensitive to customer requirements and having well experienced professionals support us proactively ensuring safe and smooth employee transportation service"}
        //                  rating={"4.6"}
        // />,
        // <TestimonialCard authour={"NSN"}
        //                  data={"Always see the sense of dedicated approach from PRM Team in meeting the challenges by offering complete flexibility in fleet size management during the COVID period with PRM COVID Safety Assurance"}
        //                  rating={"4.8"}
        // />,
        // <TestimonialCard authour={"Mobis"}
        //                  data={"PRM Roadways team is highly reliable,professional and proactive in providing the best of Employee Mobility service. Would like to thank PRM for their  efforts and process excellence in managing our Employee Mobility service hassle free"}
        //                  rating={"4.9"}
        // />
    ];

    return (
        <div className={"testimonial-section"} data-aos="fade-up"
             data-aos-duration="1000"
             data-aos-easing="linear"
             data-aos-once="true"
        >
            {/*<br/>*/}
            {/*<br/>*/}
            {/*<br/>*/}
            {/*<br/>*/}
            <MDBContainer className={"testimonial-slider"}>
                <p className={"title"}>Our Customer Testimonials</p>
                {/*<br/>*/}
                {/*<br/>*/}
                <SlickCarousel slides={testimonial_cards} autoplay={false} interval={1000} arrows={true}/>
                {/*<br/>*/}
                {/*<br/>*/}
            </MDBContainer>

        </div>
    );
}

const TestimonialCard = (props) => {
    const {authour,data,rating} = props;
    return (
        <div className={"testimonial-card"}>
            <MDBCard>
                <MDBCardBody>
                    <div className={"d-flex justify-content-start"}>
                        <img src={Quote} alt={"quote"} className={"img-fluid quote-img"}/>
                    </div>
                    <p className={"testimonial-description"}>{data}</p>
                    <div className={"d-flex justify-content-between align-items-center"}>
                        <div>
                            <p className={"customer-name"}>{authour}</p>
                            {/*<p className={"customer-profession"}>HCL manager</p>*/}
                        </div>
                        {/*<div>*/}
                        {/*    <p className={"rating-count"}>{rating}/5</p>*/}
                        {/*    <div>*/}
                        {/*        <img src={Star} alt={"star"} className={"img-fluid rating-star"}/>*/}
                        {/*        <img src={Star} alt={"star"} className={"img-fluid rating-star"}/>*/}
                        {/*        <img src={Star} alt={"star"} className={"img-fluid rating-star"}/>*/}
                        {/*        <img src={Star} alt={"star"} className={"img-fluid rating-star"}/>*/}
                        {/*        <img src={Star} alt={"star"} className={"img-fluid rating-star"}/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>

                </MDBCardBody>
            </MDBCard>
        </div>
    );
}

const Management = (props) => {
    const management_cards = [
        <MangementCard img_src={Management1} title={"Customer Focussed"}/>,
        <MangementCard img_src={Management2} title={"Driven by Values"}/>,
        <MangementCard img_src={Management3} title={"Solid & Long Term \n" +
        "Revenue Strategy"}/>,
        <MangementCard img_src={Management4} title={"Customer Focussed"}/>
    ];
    return (
        <div className={"management-section"} data-aos="fade-up"
             data-aos-duration="1000"
             data-aos-easing="linear"
             data-aos-once="true"
        >
            {/*<br/>*/}
            {/*<br/>*/}
            {/*<br/>*/}
            {/*<br/>*/}
            <div className={"empty-spacer"}/>
            <MDBContainer className={"management-container"}>
                <p className={"title"}>Our Management</p>
                {/*<br/>*/}
                {/*<br/>*/}
                <div className={"large-device-cards"}>
                    <MDBRow>
                        <MDBCol md={"6"} lg={"3"}>
                            <MangementCard img_src={Management1} title={"Customer Focussed"}/>
                        </MDBCol>
                        <MDBCol md={"6"} lg={"3"}>
                            <MangementCard img_src={Management2} title={"Driven by Values"}/>
                        </MDBCol>
                        <MDBCol md={"6"} lg={"3"}>
                            <MangementCard img_src={Management3} title={"Solid & Long Term \n" +
                            "Revenue Strategy"}/>
                        </MDBCol>
                        <MDBCol md={"6"} lg={"3"}>
                            <MangementCard img_src={Management4} title={"Governed by Policies"}/>
                        </MDBCol>
                    </MDBRow>
                </div>
                <div className={"small-device-cards"}>
                    <SlickCarousel slides={management_cards} autoplay={false} interval={1000} arrows={false}/>
                </div>
            </MDBContainer>
        </div>
    );
}

const MangementCard = (props) => {
    const {img_src, title} = props;
    return (
        <div className={"management-card"}>
            <div className={"card-image"}>
                <img src={img_src} alt={""} className={"img-fluid"}/>
            </div>
            <div className={"card-bottom-content"}>
                <p className={"management-item-content"}>{title}</p>
            </div>
        </div>
    );
}

const AwardsAndRecognition = (props) => {
    const slider = useRef(null);
    const [currentSlide,setCurrentSlide] = useState(0);
    const award_settings = {
        dots: false,
        infinite: true,
        speed: 500,
        centerMode:true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,                }
            }

        ]
    };
    const next = () => {
        slider.current.slickNext();
    }
    const previous = () => {
        slider.current.slickPrev();
    }
    const busRef6 = useRef();
    const pathRef6 = useRef();

    useEffect(() => {
        gsap.to(busRef6.current, {
            motionPath: {
                path: pathRef6.current,
                align: pathRef6.current,
                autoRotate: true,
                immediateRender: true,
                alignOrigin: [0.5, 0.5],
            }
        });
        gsap.timeline({
            scrollTrigger: {
                trigger: '.awards-and-recognition-section',
                start: 'top 60%',
                end: 'bottom top',
                toggleClass:"active",
                scrub: 0.3,
                onUpdate: ({progress}) => console.log(progress) //  info for position
            },
            defaults: {duration: 5, ease: 'none'}
        })
            .to(busRef6.current, {
                motionPath: {
                    path: pathRef6.current,
                    align: pathRef6.current,
                    paused: true,
                    ease: "none",
                    autoRotate: true,
                    immediateRender: true,
                    alignOrigin: [0.5, 0.5],
                    start: 1,
                    end: 0
                }
            });


    });

    // useEffect(()=>{
    //     setActiveSlide();
    // },[currentSlide])
    //
    // const setActiveSlide = () => {
    //     var active_slides = document.querySelectorAll(".slick-slide.slick-active");
    //     var other_slides = document.querySelectorAll(".slick-slide");
    //     var active_index = 2;
    //     if(active_slides.length > 0) {
    //         active_slides[active_index].style.opacity = 1;
    //     }
    //     if(other_slides.length > 0){
    //         for(var i=0;i<other_slides.length;i++){
    //             other_slides[i].style.opacity = 0.25;
    //         }
    //     }
    // }

    const awards_cards = [
        <AwardCard img_src={Award1} />,
        <AwardCard img_src={Award2} />,
        <AwardCard img_src={Award3} />,
        <AwardCard img_src={Award1} />,
        <AwardCard img_src={Award2} />,
        <AwardCard img_src={Award3} />,
    ];

    return (
        <div className={"awards-and-recognition-section"} id={"awards-and-recognition-section"}>
            <div ref={busRef6} id={"busref6"}>
                <svg width="209" height="62" viewBox="0 0 209 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9.7176 51.4176C10.0657 56.3888 13.6083 58.6425 16.9251 58.6425H193.061V61.6052C193.061 61.7099 193.103 61.8103 193.177 61.8844C193.251 61.9584 193.351 62 193.455 62C193.56 62 193.66 61.9584 193.734 61.8844C193.808 61.8103 193.849 61.7099 193.849 61.6052V60.1066H195.506C196.005 60.1385 196.501 60.0149 196.927 59.7528C197.354 59.4906 197.688 59.1028 197.885 58.6425H198.138C201.443 58.6425 205.586 53.5486 207.113 48.7653C207.113 48.7653 208.643 45.3924 208.643 34.7678C208.643 24.1432 207.113 21.4181 207.113 21.4181C206.057 17.409 201.294 11.5371 198.138 11.5371H197.885C197.688 11.0768 197.354 10.6889 196.927 10.4268C196.501 10.1646 196.005 10.041 195.506 10.073H193.849V8.5705C193.849 8.4658 193.808 8.36538 193.734 8.29135C193.66 8.21731 193.56 8.17572 193.455 8.17572C193.351 8.17572 193.251 8.21731 193.177 8.29135C193.103 8.36538 193.061 8.4658 193.061 8.5705V11.5371H16.9251C13.7651 11.5371 10.0657 13.1775 9.7176 18.7543C9.7176 18.7543 9.57605 24.1202 9.57605 34.764C9.57605 45.4077 9.7176 51.4176 9.7176 51.4176Z"
                        fill="#B7C0C4"/>
                    <path
                        d="M0.359932 43.2421C0.708065 48.2248 4.26208 50.4632 7.56743 50.4632H188.78C192.086 50.4632 196.229 45.3732 197.755 40.586C197.755 40.586 199.285 37.2131 199.285 26.5885C199.285 15.9639 197.755 13.2388 197.755 13.2388C196.699 9.2335 191.936 3.36162 188.78 3.36162H7.56743C4.42658 3.36162 0.708065 5.00207 0.359932 10.5827C0.359932 10.5827 0.218384 15.9486 0.218384 26.5885C0.218384 37.2284 0.359932 43.2421 0.359932 43.2421Z"
                        fill="#005B8D"/>
                    <path
                        d="M189.718 5.45821C191.302 5.45821 195.514 10.391 196.447 13.7333C196.466 13.8099 197.882 16.4162 197.882 26.5809C197.882 36.8184 196.447 40.0878 196.428 40.1452C194.653 45.7105 190.49 48.3666 189.718 48.3666H6.59597C5.83084 48.3666 1.87131 48.428 1.68385 42.7899C1.68385 42.7439 1.62646 36.8184 1.62646 26.5809C1.62646 16.4201 1.6609 11.1538 1.6609 11.1001C1.69533 7.35929 3.71144 5.79166 6.59597 5.45821H189.718Z"
                        fill="#0063A6"/>
                    <path
                        d="M189.717 6.73869C190.762 6.73869 194.438 11.2844 195.215 14.0785C195.41 14.6995 196.603 17.187 196.603 26.5812C196.603 36.5465 195.222 39.7124 195.207 39.7699C193.979 43.6027 191.542 47.0868 189.717 47.0868H6.59538C5.13781 47.0868 2.93807 45.5843 2.89981 42.3992V26.5812C2.89599 16.75 2.87303 11.5642 2.87303 11.4991C2.84625 8.68193 5.16841 6.66587 6.59538 6.72719L189.717 6.73869Z"
                        fill="#0075B3"/>
                    <path d="M182.732 43.9823C182.732 43.9823 184.308 25.9681 182.732 10.1615H177.376V43.9823H182.732Z"
                          fill="url(#paint0_linear_1097_44670)"/>
                    <path
                        d="M183.543 43.8439L189.664 45.1854C190.341 45.0206 192.273 42.7516 193.402 39.2101L193.429 39.1219L193.455 39.0529C193.585 38.7233 194.714 35.5804 194.714 26.5771C194.714 21.0731 194.308 17.1483 193.505 14.91C193.467 14.8065 193.436 14.7183 193.398 14.588C192.847 12.6141 190.379 9.40988 189.354 8.63948L183.543 9.96564C183.543 9.96564 184.343 13.6681 184.343 26.83C184.343 39.992 183.543 43.8439 183.543 43.8439Z"
                        fill="#072129"/>
                    <path
                        d="M188.749 10.6019C187.912 13.6413 187.483 18.9766 187.483 26.4583C187.483 38.6965 188.669 42.9662 188.803 43.4147L188.83 43.5066L188.86 43.6255C188.987 44.1352 189.121 44.6297 189.266 45.1011L189.648 45.1854C190.326 45.0206 192.258 42.7516 193.386 39.2101L193.413 39.1219L193.44 39.0529C193.57 38.7233 194.698 35.5804 194.698 26.5771C194.698 21.0731 194.293 17.1483 193.489 14.91C193.451 14.8065 193.421 14.7183 193.382 14.588C192.831 12.6141 190.364 9.40988 189.339 8.63948C189.147 9.13834 188.992 9.65079 188.876 10.1726C188.822 10.3489 188.792 10.4562 188.749 10.6019Z"
                        fill="#25333A"/>
                    <path
                        d="M183.543 43.8441L185.903 44.3615C186.087 43.1235 186.646 38.4015 186.646 26.8379C186.646 15.2743 186.079 10.6136 185.9 9.43689L183.543 9.96582C183.543 9.96582 184.343 13.6683 184.343 26.8302C184.343 39.9921 183.543 43.8441 183.543 43.8441Z"
                        fill="#D5EEF2"/>
                    <path d="M187.614 46.3966H8.43982V45.4537H181.837L187.614 46.3966Z" fill="#25333A"/>
                    <path d="M163.018 8.36758H181.836L187.613 7.42854H163.018V8.36758Z" fill="#25333A"/>
                    <path d="M141.335 7.42854H48.3871V8.37142H141.335V7.42854Z" fill="#25333A"/>
                    <path d="M26.7072 7.42854H8.43982V8.37142H26.7072V7.42854Z" fill="#25333A"/>
                    <path
                        d="M117.401 20.6322H97.5462C96.9821 20.6322 96.5248 21.0904 96.5248 21.6556V32.169C96.5248 32.7342 96.9821 33.1924 97.5462 33.1924H117.401C117.965 33.1924 118.423 32.7342 118.423 32.169V21.6556C118.423 21.0904 117.965 20.6322 117.401 20.6322Z"
                        fill="#0063A6"/>
                    <path d="M97.4279 21.5293V32.2842H114.425V21.5293H97.4279Z" fill="#0075B3"/>
                    <path d="M97.4279 23.0588V30.7551H112.619V23.0588H97.4279Z" fill="url(#paint1_linear_1097_44670)"/>
                    <path
                        d="M116.181 32.2807H117.788V21.5488H116.181C116.027 21.5498 115.88 21.6115 115.771 21.7205C115.662 21.8296 115.601 21.9772 115.6 22.1314V31.7134C115.605 31.8649 115.668 32.0087 115.776 32.1144C115.885 32.2202 116.03 32.2798 116.181 32.2807Z"
                        fill="#072129"/>
                    <path
                        d="M78.8583 20.6322H59.0033C58.4391 20.6322 57.9818 21.0904 57.9818 21.6556V32.169C57.9818 32.7342 58.4391 33.1924 59.0033 33.1924H78.8583C79.4224 33.1924 79.8798 32.7342 79.8798 32.169V21.6556C79.8798 21.0904 79.4224 20.6322 78.8583 20.6322Z"
                        fill="#0063A6"/>
                    <path d="M58.8842 21.5332V32.2881H75.8814V21.5332H58.8842Z" fill="#0075B3"/>
                    <path d="M58.881 23.0588V30.7551H74.0726V23.0588H58.881Z" fill="url(#paint2_linear_1097_44670)"/>
                    <path
                        d="M77.638 32.2807H79.2448V21.5488H77.638C77.4838 21.5488 77.3359 21.6102 77.2268 21.7194C77.1178 21.8287 77.0565 21.9769 77.0565 22.1314V31.7134C77.0615 31.8649 77.1248 32.0087 77.2332 32.1144C77.3416 32.2202 77.4867 32.2798 77.638 32.2807Z"
                        fill="#072129"/>
                    <path
                        d="M40.3146 20.6322H20.4596C19.8954 20.6322 19.4381 21.0904 19.4381 21.6556V32.169C19.4381 32.7342 19.8954 33.1924 20.4596 33.1924H40.3146C40.8787 33.1924 41.3361 32.7342 41.3361 32.169V21.6556C41.3361 21.0904 40.8787 20.6322 40.3146 20.6322Z"
                        fill="#0063A6"/>
                    <path d="M20.3373 21.5332V32.2881H37.3346V21.5332H20.3373Z" fill="#0075B3"/>
                    <path d="M20.3373 23.0588V30.7551H35.5289V23.0588H20.3373Z" fill="url(#paint3_linear_1097_44670)"/>
                    <path
                        d="M39.0942 32.2807H40.701V21.5488H39.0942C38.9397 21.5488 38.7914 21.61 38.6817 21.7192C38.572 21.8283 38.5099 21.9765 38.5089 22.1314V31.7134C38.5148 31.8653 38.579 32.009 38.688 32.1147C38.797 32.2203 38.9425 32.2798 39.0942 32.2807Z"
                        fill="#072129"/>
                    <path
                        d="M174.112 13.6487H131.327C130.369 13.6487 129.594 14.4261 129.594 15.385V38.4394C129.594 39.3983 130.369 40.1757 131.327 40.1757H174.112C175.07 40.1757 175.845 39.3983 175.845 38.4394V15.385C175.845 14.4261 175.07 13.6487 174.112 13.6487Z"
                        fill="#0063A6"/>
                    <path d="M131.496 15.5387V38.2596H171.255V15.5387L131.496 15.5387Z" fill="#0075B3"/>
                    <path d="M131.496 17.0454V36.7653H168.532V17.0454L131.496 17.0454Z"
                          fill="url(#paint4_linear_1097_44670)"/>
                    <path
                        d="M172.877 38.2711H174.533V27.1751H172.877C172.718 27.1751 172.565 27.2385 172.452 27.3514C172.34 27.4642 172.276 27.6173 172.276 27.7769V37.6694C172.276 37.829 172.34 37.982 172.452 38.0949C172.565 38.2077 172.718 38.2711 172.877 38.2711Z"
                        fill="#072129"/>
                    <path
                        d="M172.877 26.6428H174.533V15.5276H172.877C172.718 15.5276 172.565 15.591 172.452 15.7039C172.34 15.8167 172.276 15.9698 172.276 16.1294V26.0372C172.276 26.1165 172.291 26.1952 172.321 26.2687C172.351 26.3421 172.395 26.4089 172.451 26.4652C172.507 26.5215 172.573 26.5661 172.646 26.5966C172.719 26.6271 172.798 26.6428 172.877 26.6428Z"
                        fill="#072129"/>
                    <path
                        d="M184.098 53.8248C184.199 53.8248 184.296 53.7844 184.368 53.7125C184.44 53.6407 184.48 53.5432 184.48 53.4415V51.939H186.144C188.229 51.939 188.78 50.2104 188.78 49.2982V46.485H188.111V49.2944C188.111 49.4975 188.042 51.2683 186.144 51.2683H184.488V49.8272C184.488 49.7225 184.446 49.6221 184.372 49.548C184.299 49.474 184.198 49.4324 184.094 49.4324C183.989 49.4324 183.889 49.474 183.815 49.548C183.741 49.6221 183.7 49.7225 183.7 49.8272V53.43C183.698 53.4826 183.707 53.535 183.727 53.5839C183.746 53.6328 183.776 53.6771 183.813 53.7142C183.85 53.7512 183.895 53.7801 183.944 53.7992C183.993 53.8182 184.045 53.8269 184.098 53.8248Z"
                        fill="#0075B3"/>
                    <path
                        d="M184.098 0.00030677C184.2 0.00030677 184.297 0.0406882 184.369 0.112567C184.44 0.184447 184.481 0.281936 184.481 0.383589V1.91672H186.145C188.23 1.91672 188.781 3.64532 188.781 4.55753V7.37082H188.111V4.5422C188.111 4.33906 188.042 2.5683 186.145 2.5683H184.488V3.99794C184.488 4.10264 184.447 4.20305 184.373 4.27709C184.299 4.35113 184.199 4.39272 184.094 4.39272C183.99 4.39272 183.89 4.35113 183.816 4.27709C183.742 4.20305 183.7 4.10264 183.7 3.99794V0.383589C183.7 0.331939 183.711 0.280814 183.731 0.233283C183.751 0.185753 183.781 0.142799 183.818 0.107C183.855 0.0712008 183.899 0.043296 183.947 0.0249614C183.995 0.00662688 184.047 -0.00175924 184.098 0.00030677Z"
                        fill="#0075B3"/>
                    <defs>
                        <linearGradient id="paint0_linear_1097_44670" x1="177.636" y1="27.0719" x2="182.674"
                                        y2="27.0719"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0075B3"/>
                            <stop offset="0.39" stopColor="#007DB9"/>
                            <stop offset="1" stopColor="#0093C9"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_1097_44670" x1="6451.94" y1="4782.56" x2="6756.98"
                                        y2="4782.56"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0075B3"/>
                            <stop offset="0.39" stopColor="#007DB9"/>
                            <stop offset="1" stopColor="#0093C9"/>
                        </linearGradient>
                        <linearGradient id="paint2_linear_1097_44670" x1="5639.43" y1="4782.57" x2="5944.48"
                                        y2="4782.57"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0075B3"/>
                            <stop offset="0.39" stopColor="#007DB9"/>
                            <stop offset="1" stopColor="#0093C9"/>
                        </linearGradient>
                        <linearGradient id="paint3_linear_1097_44670" x1="4826.86" y1="4782.57" x2="5131.91"
                                        y2="4782.57"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0075B3"/>
                            <stop offset="0.39" stopColor="#007DB9"/>
                            <stop offset="1" stopColor="#0093C9"/>
                        </linearGradient>
                        <linearGradient id="paint4_linear_1097_44670" x1="18525.5" y1="11757.7" x2="20431" y2="11757.7"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0075B3"/>
                            <stop offset="0.39" stopColor="#007DB9"/>
                            <stop offset="1" stopColor="#0093C9"/>
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <div>
                <svg width="1734" height="1124" viewBox="0 0 1734 1124" fill="none" xmlns="http://www.w3.org/2000/svg"
                     id={"seventh-section-path"}>
                    <path
                        d="M1734 1098.86H1124.39H160C85.4416 1098.86 25 1038.41 25 963.856V484.812C25 410.254 85.4416 349.812 160 349.812H1422.68C1497.24 349.812 1557.68 289.371 1557.68 214.812V0.944824"
                        stroke="#DADADA" strokeOpacity="0.45" strokeWidth="50" ref={pathRef6}/>
                </svg>
            </div>
            <div className={"empty-spacer"}/>
            <MDBContainer data-aos="fade-up"
                          data-aos-duration="1000"
                          data-aos-easing="linear"
                          data-aos-once="true"
                          className={"award-slider-container"}
            >
                <p className={"title"}>Awards & Recognition</p>
                <br/>
                <br/>
                <div className={"slider-section large-device-slider"}>
                    <Slider {...award_settings} ref={slider}>
                        <div className={"award-item"}>
                            <img src={Award1} alt={"Award"} className={"img-fluid award-img"}/>
                        </div>
                        <div className={"award-item"}>
                            <img src={Award2} alt={"Award"} className={"img-fluid award-img"} width={"40%"}/>
                        </div>
                        <div className={"award-item"}>
                            <img src={Award3} alt={"Award"} className={"img-fluid award-img"}/>
                        </div>
                        <div className={"award-item"}>
                            <img src={Award1} alt={"Award"} className={"img-fluid award-img"}/>
                        </div>
                        <div className={"award-item"}>
                            <img src={Award2} alt={"Award"} className={"img-fluid award-img"} width={"40%"}/>
                        </div>
                        <div className={"award-item"}>
                            <img src={Award3} alt={"Award"} className={"img-fluid award-img"}/>
                        </div>
                    </Slider>
                    <button className="prev-btn" onClick={previous}>
                        {/*<img src={ArrowLeft} alt={"Prev"}/>*/}
                    </button>
                    <button className="next-btn" onClick={next}>
                        {/*<img src={ArrowRight} alt={"Next"}/>*/}
                    </button>
                </div>
                <div className={"small-device-slider"}>
                    <SlickCarousel slides={awards_cards} autoplay={false} interval={1000} arrows={false}/>
                </div>
                <br/>
                <br/>
            </MDBContainer>
            <div className={"empty-spacer"}/>
        </div>
    );
}

const AwardCard = (props) => {
    const {img_src} = props;
    return (
        <div className={"award-card"}>
            <div className={"award-image-section"}>
                <img src={img_src} alt={"Award"} className={"img-fluid award-img"} style={{height:"314px"}}/>
            </div>
        </div>
    );
}
