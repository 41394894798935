import {
    Environment,
    Network,
    RecordSource,
    Store,
    QueryResponseCache,
} from 'relay-runtime';
import {api} from "./ServiceApi";

const oneMinute = 60 * 1000 * 10;
const cache = new QueryResponseCache({ size: 1024, ttl: oneMinute });

function fetchQuery(
    operation,
    variables,
    cacheConfig,
) {

    const queryID = operation.text;
    const isMutation = operation.operationKind === 'mutation';
    const isQuery = operation.operationKind === 'query';
    const forceFetch = cacheConfig && cacheConfig.force;

    const fromCache = cache.get(queryID, variables);
    if (isQuery && fromCache !== null && !forceFetch) {
        return fromCache;
    }

    let requestVariables = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',

        }
    }
    let body = JSON.stringify({
        query: operation.text,
        variables
    })


    return fetch(api, {
        ...requestVariables,
        body
    }).then(response => {
        return response.json();
    }).then(json => {
        // Update cache on queries
        if (isQuery && json) {
            cache.set(queryID, variables, json);
        }
        // Clear cache on mutations
        if (isMutation) {
            cache.clear();
        }

        return json;
    });
}

const environment = new Environment({
    network: Network.create(fetchQuery),
    store: new Store(new RecordSource()),
});

export default environment;
