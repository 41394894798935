import React, {useEffect, useRef, useState} from "react";
import "./service.scss";
import AOS from 'aos';
import $ from 'jquery';

import car1 from "../../assets/images/car-1.png";
import carbg from "../../assets/images/car-bg.png";
import bus1 from "../../assets/images/bus21.png";
import bus2 from "../../assets/images/img-2.png";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

import CorporateBg from "../../assets/images/corporate-bg.png";
import ToursAndTravelBg from "../../assets/images/tours-and-travel-bg.png";
import GoodsBg from "../../assets/images/Layer21.png";
import GoToTop from "../../GoToTop";
import {useLocation, withRouter} from "react-router-dom";

import CorporatePattern from "../../assets/images/corporate-pattern.png";
import ToursAndTravelsPattern from "../../assets/images/tours-and-travels-pattern.png";
import GoodsPattern from "../../assets/images/goods-pattern.png";


// import createHistory from 'history/createBrowserHistory'



const Service = (props) => {

    const location=useLocation()
    const [count,setcount]=useState(0)


    useEffect(() => {
        AOS.init();
        $(window).scroll(function (event) {
            var corporate_container = document.getElementById("corporate-container-section");
            var tours_travels_container = document.getElementById("tours-travels-section");
            var goods_container = document.getElementById("goods-section");


            if (corporate_container && inView(corporate_container)) {
                document.getElementById("car-image").classList.add('animate');
            }
            if (tours_travels_container && inView(tours_travels_container)) {
                document.getElementById("bus-image").classList.add('animate');
            }
            if (goods_container && inView(goods_container)) {
                document.getElementById("lorry-image").classList.add('animate');
            }
        });
    },[]);
    GoToTop()




    const inView = (element) => {
        // get window height
        var elementHeight = element.clientHeight;

        var windowHeight = window.innerHeight;
        // get number of pixels that the document is scrolled
        var scrollY = window.scrollY || window.pageYOffset;

        // get current scroll position (distance from the top of the page to the bottom of the current viewport)
        var scrollPosition = scrollY + windowHeight;
        // get element position (distance from the top of the page to the bottom of the element)
        var elementPosition = element.getBoundingClientRect().top + scrollY + elementHeight;

        // is scroll position greater than element position? (is element in view?)
        if (scrollPosition > elementPosition) {
            return true;
        }

        return false;
    }

    const staff=useRef()
    const Tours=useRef()
    const Goods=useRef()

    useEffect(()=>{
       if (count===0){
           setcount(count + 1)
           // const history = createHistory();
           switch (location.state){
               case 'staff':
                     window.scrollTo(0, staff.current.offsetTop -50);
                  props.history.location.state=undefined
                   break
               case 'Tours':
                    window.scrollTo(0, Tours.current.offsetTop -50);
                   break
               case 'Goods':
                    window.scrollTo(0, Goods.current.offsetTop -50);
                   break
               default:
                   return window.scrollTo(0, 0);
           }
           // props.history.push({pathname:"/services",state:'wrtwg'})
           console.log(location.state)
       }
    },[])

    return (
        <div className="services">
            {/*<Header page={"services"}/>*/}
            <div className="sercice-top mx-0">
                <div className='my'>
                    <p className="header"
                         data-aos="fade-up"
                         data-aos-duration="1000"
                         data-aos-easing="linear"
                         data-aos-once="true"
                    >
                        <span className={"highlighted-title"}>
                        Service We Offer
                    </span>


                    </p>

                    <p className="content"
                       data-aos="fade-up"
                       data-aos-duration="1000"
                       data-aos-easing="linear"
                       data-aos-delay="1000"
                       data-aos-once="true"
                    >
                        Driven by our unwavering commitment to quality services, <br/>
                        we deliver the industry’s most comprehensive transportation <br/>
                        solutions to meet our client’s and customer’s needs.

                    </p>
                </div>
            </div>
            <div className="main row mx-0"  ref={staff} id={"corporate-container-section"}>
                <div className="col-md-6 col-10 main-1 grid-columns corporate "
                     data-aos="fade-right"
                     data-aos-duration="1000"
                     data-aos-easing="linear"
                     data-aos-once="true"
                >
                    <div className={"container corporate-container"}

                    >
                        <div className={"move-to-right"}>
                            <div className="mainwrap"
                            >
                                <div className="title">Corporate</div>
                                <div className="content">
                                    With our well-maintained Infrastructure and best in class service, we have travelled 6 Billion safe miles till date in Employee Mobility service. We are constantly working on developing the mobility value chain with strategic alliance to further enhance our customer experience
                                </div>

                            </div>
                            {/*<div className="pickup-count">*/}
                            {/*    <div className="main-pickup">*/}
                            {/*        <div className="pickup-number">500 +</div>*/}
                            {/*        <div className="pickup">Pickup & Drop</div>*/}
                            {/*    </div>*/}
                            {/*    <div className="main-pick-2">*/}
                            {/*        <div className="count-number">1000+</div>*/}
                            {/*        <div className="count">Count Of Vehicles</div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>

                    </div>
                    <img src={CorporatePattern} alt={"CorporatePattern"} className={"img-fluid corporate-pattern"}/>
                </div>
                <div className="col-md-6  col-12  grid-columns fullcar px-0">
                    {/*<img src={carbg} alt="car background" className="carbg"/>*/}
                    <div className={"bg-section"}>
                        <img src={CorporateBg} alt="car image" className="corporate-inner-img" id={"car-image"} width={"100%"}/>
                    </div>
                </div>
            </div>
            <div className="main row mx-0" id={"tours-travels-section"} ref={Tours}>
                <div className="col-md-6 order-md-2 col-12 main-1 grid-columns tour-and-travel"
                     data-aos="fade-left"
                     data-aos-duration="1000"
                     data-aos-easing="linear"
                     data-aos-once="true"
                >
                    <div className={"move-to-left"}>
                        <div className="mainwrap">
                            <div className="title ">Tours & Travels</div>
                            <div className="content">
                                We offer on-demand transportation services to travel in comfort
                                and style and make every trip fun and memorable. Enjoy one-day excursions, weekend getaways and guided tours on our fleet throughout South India.

                            </div>
                        </div>
                        {/*<div className="pickup-count">*/}
                        {/*    <div className="main-pickupbus">*/}
                        {/*        <div className="pickup-number">500 +</div>*/}
                        {/*        <div className="pickup">Pickup & Drop</div>*/}
                        {/*    </div>*/}
                        {/*    <div className="main-pick-2">*/}
                        {/*        <div className="count-number">1000+</div>*/}
                        {/*        <div className="count">Count Of Vehicles</div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    <img src={ToursAndTravelsPattern} alt={"ToursAndTravelsPattern"} className={"img-fluid tours-travels-pattern"}/>

                </div>
                <div className="col-md-6 order-md-1 col-12  fullcar px-0 grid-columns">
                    {/*<img src={carbg} alt="bus background" className="busbg"/>*/}
                    <div className={"bg-section"}>
                        <img src={ToursAndTravelBg} alt="bus image" className="tours-inner-img" id={"bus-image"}/>
                    </div>
                </div>
            </div>

            <div className="main row mx-0" ref={Goods} id={"goods-section"}>
                <div className="col-md-6 col-10 main-1 grid-columns goods">
                    <div className={"container corporate-container"}
                         data-aos="fade-right"
                         data-aos-duration="1000"
                         data-aos-easing="linear"
                         data-aos-once="true"
                    >
                        <div className={"move-to-right"}>
                            <div className="mainwrap">
                                <div className="title">Goods</div>
                                <div className="content">
                                    We are the major Logistics service provider catering to the inbound and outbound logistics requirement to Industries majorly in Southern states. With our strong fleet including Trucks, Tippers, Bulkers, Tankers, we provide the excellent integrated supply chain solutions in handling various raw materials and industrial products


                                </div>
                            </div>
                            {/*<div className=" pickup-count ">*/}
                            {/*    <div className="main-pickup">*/}
                            {/*        <div className="pickup-number">250 +</div>*/}
                            {/*        <div className="pickup">Pickup & Drop</div>*/}
                            {/*    </div>*/}
                            {/*    <div className="main-pick-2">*/}
                            {/*        <div className="count-number">150 +</div>*/}
                            {/*        <div className="count">Count Of Vehicles</div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <img src={GoodsPattern} alt={"GoodsPattern"} className={"img-fluid Goods-pattern"}/>

                </div>
                <div className="col-md-6  col-12  fullcar px-0 grid-columns">
                    {/*<img src={carbg} alt="car background" className=" bus2bg"/>*/}
                    <div className={"bg-section"}>
                        <img src={GoodsBg} alt="car image" className="goods-inner-img" id={"lorry-image"}/>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
            <br/>
            {/*<br/>*/}
            {/*<br/>*/}
            <Footer/>
        </div>

    );
};
export default withRouter(Service);

