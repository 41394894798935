
import "./AboutUs.scss";
import Header from "../../Components/Header/Header";
import React, {useEffect} from "react";
import Footer from "../../Components/Footer/Footer";
import AOS from 'aos';


import main1 from "../../assets/images/About-main-1.png";
import main2 from "../../assets/images/About-main-2.png";
import MisionBg from "../../assets/images/mision-bg.png";
import Mision from "../../assets/images/mission.png";
import VisionBg from "../../assets/images/vision-bg.png";
import Vision from "../../assets/images/vision.png";
import DotIcon from "../../assets/images/dot-icon.svg";

import OurVisionLargeDeviceImage from "../../assets/images/our-vision-desktop.png";
import OurVisionMobileDeviceImage from "../../assets/images/our-vision-mobile.png";
import OurMisionLargeDeviceImage from "../../assets/images/our-mision-desktop.png";
import OurMisionMobileDeviceImage from "../../assets/images/our-mision-mobile.png";
import OurVisionMobilePattern from "../../assets/images/our-vision-mobile-patern.png";
import OurMisionMobilePattern from "../../assets/images/our-mision-mobile-patern.png";


import $ from "jquery";
import GoToTop from "../../GoToTop";

const AboutUs = () => {
    useEffect(() => {
        AOS.init();
    },[]);
    GoToTop()

  return (
    <div className="About">
      {/*<Header page={"aboutus"}/>*/}
      <div className="top">
        <div className="bg">
            <p className="title"
               data-aos="fade-up"
               data-aos-duration="1000"
               data-aos-easing="linear"
               data-aos-once="true"
            >
                        <span className={"highlighted-title"}>
                        Company Overview
                    </span>


            </p>

          <div className="para"
               data-aos="fade-up"
               data-aos-duration="1000"
               data-aos-easing="linear"
               data-aos-delay="1000"
               data-aos-once="true"
          >
              PRM Roadways Private Limited, a team of highly dedicated and dynamic professionals focussed on high-quality Employee Mobility Services and Logistics service provider operating in South India. Our ownership has made the transition to the 3rd generation, and we have been operating successfully for 45 years now with 3000+ highly skilled employees. Our customer focussed value added robust business processes ensure in providing faster customer solutions with committed team work. We continuously invest in employees development and technology to enhance customers’ delight experience


          </div>
        </div>
      </div>
      <div className="main">
          <div className={"container-fluid vision-container"}>
              <div className="row mx-0">
                  <div className="col-12 col-md-6 main-1-content vision-content-section p-0">
                      <div className={"our-vission-content-section"}
                           data-aos="fade-right"
                           data-aos-duration="1000"
                           data-aos-easing="linear"
                           data-aos-once="true"
                      >
                          <div className="title">Our Vision </div>
                          <div className="para">
                              To have a sustainable, efficient, Safe & Complete Transport Solution to the customers at National level with international quality.
                          </div>

                      </div>
                      <img src={OurVisionMobilePattern} alt={"OurVisionMobilePattern"}
                           className={"img-fluid our-vision-mobile-patern"}/>
                  </div>
                  <div className="col-12 col-md-6 parent-vision-image-section p-0">
                      <div className={"vision-image-section"}
                           data-aos="fade-left"
                           data-aos-duration="1000"
                           data-aos-easing="linear"
                           data-aos-once="true"
                      >
                          <img src={OurVisionLargeDeviceImage} alt={"Vision BG"} className={"img-fluid our-vision-large-img"} width={"80%"}/>
                          <img src={OurVisionMobileDeviceImage} alt={"Vision BG"} className={"img-fluid our-vision-mobile-img"} width={"80%"}/>

                          {/*<img src={VisionBg} alt={"Vision BG"} className={"img-fluid vision-bg"}/>*/}
                          {/*<img src={Vision} alt={"Vision"} className={"img-fluid vision-img"}/>*/}
                      </div>
                  </div>
                  {/*<div className="col-12 col-md-6 order-md-4">*/}
                  {/*  <div className="main-img-2">*/}
                  {/*    <img src={main2} alt="Our Mission" className="img-2" />*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*<div className="col-12 col-md-6 px-0 main-1-content order-md-3">*/}
                  {/*  <div className={"our-vision-content-section"}>*/}
                  {/*    <div className="title">Our Vision</div>*/}
                  {/*    <div className="para">*/}
                  {/*      PRM Rodways Pvt.ltd is a 3rd generation entrepreneur and 4 decades*/}
                  {/*      of transport service. Lorem ipsum dolor sit amet, con adipiscing*/}
                  {/*      elit. Augue sapien viverra mi lorem ipsum dolor sit amet,*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}

              </div>
          </div>
          <div className={"container-fluid mision-container"}>
          <div className="row mx-0">
            <div className="col-12 col-md-6 order-md-1 p-0">
              <div className={"mision-image-section"}
                   data-aos="fade-right"
                   data-aos-duration="1000"
                   data-aos-easing="linear"
                   data-aos-once="true"
              >
                  <img src={OurMisionLargeDeviceImage} alt={"Vision BG"} className={"img-fluid our-mision-large-img"} width={"80%"}/>
                  <img src={OurMisionMobileDeviceImage} alt={"Vision BG"} className={"img-fluid our-mision-mobile-img"} width={"80%"}/>

                {/*  <img src={MisionBg} alt={"Mision BG"} className={"img-fluid mision-bg"}/>*/}
                {/*<img src={Mision} alt={"Mision"} className={"img-fluid mision-img"}/>*/}
              </div>
            </div>
            <div className="col-12 col-md-6 px-0 main-1-content mision-content-section order-md-2 p-0">
              <div className={"our-mission-content-section"}
                   data-aos="fade-left"
                   data-aos-duration="1000"
                   data-aos-easing="linear"
                   data-aos-once="true"
              >
                <div className="title">Our Mission </div>
                  <div className={"d-flex align-items-baseline mb-4 mission-points"} >
                      <img src={DotIcon} alt={"dot-icon"} className={"dot-icon"}/>
                      <div className="para mb-0">
                          Responsible organisation for the upliftment of society by providing job opportunities & Services.
                      </div>
                  </div>
                  {/*<div className={"d-flex align-items-baseline mb-4 mission-points"} >*/}
                  {/*    <img src={DotIcon} alt={"dot-icon"} className={"dot-icon"}/>*/}
                  {/*    <div className="para mb-0">*/}
                  {/*        Implement of policies for efficient and safe transportation.*/}
                  {/*    </div>*/}
                  {/*</div>*/}
                  <div className={"d-flex align-items-baseline mb-4 mission-points"} >
                      <img src={DotIcon} alt={"dot-icon"} className={"dot-icon"}/>
                      <div className="para mb-0">
                          Socially responsible for environment friendly solutions for safer, fuel efficient and cleaner transportation in alignment with international standards.
                      </div>
                  </div>
                  <div className={"d-flex align-items-baseline mb-4 mission-points"} >
                      <img src={DotIcon} alt={"dot-icon"} className={"dot-icon"}/>
                      <div className="para mb-0">
                          Enhancing Digital solutions for quicker resolutions to customers’ needs.
                      </div>
                  </div>
                  {/*<div className={"d-flex align-items-baseline mb-4 mission-points"} >*/}
                  {/*    <img src={DotIcon} alt={"dot-icon"} className={"dot-icon"}/>*/}
                  {/*    <div className="para mb-0">*/}
                  {/*        Reinforce the infrastructure of facilities.*/}
                  {/*    </div>*/}
                  {/*</div>*/}

              </div>
                <img src={OurMisionMobilePattern} alt={"OurVisionMobilePattern"}
                     className={"img-fluid our-mision-mobile-patern"}/>
            </div>
            {/*<div className="col-12 col-md-6 order-md-4">*/}
            {/*  <div className="main-img-2">*/}
            {/*    <img src={main2} alt="Our Mission" className="img-2" />*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="col-12 col-md-6 px-0 main-1-content order-md-3">*/}
            {/*  <div className={"our-vision-content-section"}>*/}
            {/*    <div className="title">Our Vision</div>*/}
            {/*    <div className="para">*/}
            {/*      PRM Rodways Pvt.ltd is a 3rd generation entrepreneur and 4 decades*/}
            {/*      of transport service. Lorem ipsum dolor sit amet, con adipiscing*/}
            {/*      elit. Augue sapien viverra mi lorem ipsum dolor sit amet,*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}

          </div>
        </div>
      </div>
      <br/>
      <br/>
      <Footer/>
    </div>
  );
};

export default AboutUs;
