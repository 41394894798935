import React, {useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive'
import "./subGallery.scss";


import clossbutton from "../../assets/images/closs-button.png";
import subGallery1 from "../../assets/images/subGallery1.png";
import subGallery2 from "../../assets/images/subGallery2.png";
import subGallery3 from "../../assets/images/subGallery3.png";
import subGallery4 from "../../assets/images/subGallery4.png";
import subGallery5 from "../../assets/images/subGallery5.png";
import subGallery6 from "../../assets/images/subGallery6.png";
import {MDBModal, MDBModalBody} from 'mdbreact';
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import GoToTop from "../../GoToTop";
import {StaggeredGrid} from "../../StaggeredGrid";
import AOS from "aos";
import {withRouter} from "react-router-dom"
import Pagination from "react-js-pagination";
import {FleetImages,InfraImages,FacilityImages} from "./gallery-data";


const SubGallery = (props) => {
    // const [modal, setModal] = useState('');
    // const isTabletOrMobile = useMediaQuery({query: '(min-width: 780px)'})
    // const toggleHandler = (subGallery1) => {
    //     if (isTabletOrMobile) {
    //         setModal(subGallery1)
    //     }
    // }

    const [activePage,setactivePage] = useState(1)
    GoToTop()
    useEffect(() => {
        AOS.init();
    },[]);


    const handlePageChange = (pageNumber) => {
        console.log(`active page is ${pageNumber}`);
        setactivePage(pageNumber);
    }

    return (
        <div>
            {/*<Header page={"sub-gallery"}/>*/}
            <div data-aos="fade-in"
                 data-aos-duration="1000"
                 data-aos-easing="linear"
                 data-aos-once="true">
            <StaggeredGrid gallery={props.match.params.gallery} currentPage={activePage}/>

            </div>
            <div className="SubGallery">
                <Pagination
                    activePage={activePage}
                    itemsCountPerPage={6}
                    totalItemsCount={12}
                    pageRangeDisplayed={2}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                    hideFirstLastPages={true}
                    prevPageText={"Previous"}
                    nextPageText={"Next"}
                    itemClassPrev={"prev-button"}
                    itemClassNext={"next-button"}
                    activeClass={"active-page"}
                    // hideDisabled={true}
                    // innerClass={"custom-pagination"}

                />

            </div>
            <br/>
            <br/>
            <Footer/>
            <GoToTop/>
        </div>
    );
};

export default withRouter(SubGallery);





