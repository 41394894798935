import React from "react";
import "./Footer.scss";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import {withRouter} from "react-router-dom";

import AddressIcon from "../../assets/images/address-icon.png";
import PhoneIcon from "../../assets/images/phone-icon.png";
import EmailIcon from "../../assets/images/email-icon.png";
import FacebookIcon from "../../assets/images/facebook-icon.png";
import TwitterIcon from "../../assets/images/twitter-icon.png";
import InstagramIcon from "../../assets/images/instagram-icon.png";

const Footer = (props) => {
    return(
        <div className={"footer-component"}>
            <MDBFooter  className="footer-section">
                <MDBContainer fluid={true} className=" text-lg-left footer-container">
                    {/*<MDBRow>*/}
                       <div className={"footer-columns"}>
                        {/*<MDBCol md="4" className={"footer-columns"}>*/}
                            <div>
                                <h5 className="title">Contact Us</h5>
                                <div className={"footer-content-item"}>
                                    <img src={AddressIcon} alt={"address"}/>
                                    <div className={"footer-content-section"}>
                                        <p className={"footer-content"}>
                                            PRM Towers,
                                        </p>
                                        <p className={"footer-content"}>
                                            No. 419,
                                        </p>
                                        <p className={"footer-content"}>
                                            Mambakkam,
                                        </p>
                                        <p className={"footer-content"}>
                                            Chennai-Bangalore NH Road,
                                        </p>
                                        <p className={"footer-content"}>
                                            Sriperumpudur - 602 105,
                                        </p>
                                        <p className={"footer-content"}>
                                            Kanchipuram(Dt)
                                        </p>
                                    </div>
                                </div>
                                <div className={"footer-content-item"}>
                                    <img src={PhoneIcon} alt={"phone"}/>
                                    <div className={"footer-content-section"}>
                                        <p className={"footer-content"}>
                                            Toll Free No:
                                        </p>
                                        <p className={"footer-content"}>
                                            1800 5998 8889
                                        </p>
                                    </div>
                                </div>
                                <div className={"footer-content-item e-mail-div"}>
                                    <img src={EmailIcon} alt={"email"}/>
                                    <div className={"footer-content-section"}>
                                        <p className={"footer-content"}>
                                            Email: info@prmtransport.com
                                        </p>
                                    </div>
                                </div>
                            </div>

                        {/*</MDBCol>*/}
                       </div>
                    <div className={"footer-columns services"}>
                        {/*<MDBCol md="4" className={"footer-columns"}>*/}
                            <div>
                                <h5 className="title">Our Service</h5>
                                <div className={"footer-content-item clickable"}>
                                    <p className={"footer-content"} onClick={()=> props.history.push({pathname:"/services",state:'staff'})}>
                                        Staff Transport
                                    </p>
                                </div>
                                <div className={"footer-content-item clickable"}>
                                    <p className={"footer-content"} onClick={()=> props.history.push({pathname:"/services",state:'Tours'})}>Tours & Travels</p>
                                </div>
                                <div className={"footer-content-item clickable"}>
                                    <p className={"footer-content"} onClick={()=> props.history.push({pathname:"/services",state:'Goods'})}>Goods</p>
                                </div>
                            </div>
                        {/*</MDBCol>*/}
                    </div>
                    <div className={"footer-columns"}>
                        {/*<MDBCol md="4" className={"footer-columns"}>*/}
                            <div>
                                <h5 className="title Social-Media">Company</h5>
                                {/*<div className={"d-flex align-items-center social-media-section"} >*/}
                                {/*    <div className={"footer-content-item flex-column clickable"}>*/}
                                {/*        <img src={FacebookIcon} alt={"facebook"} className={"media-icons"}/>*/}
                                {/*        /!*<p className={"footer-content"} onClick={()=>{*!/*/}
                                {/*        /!*    props.history.push("/faq");*!/*/}
                                {/*        /!*}}>FAQ</p>*!/*/}
                                {/*    </div>*/}
                                {/*    <div className={"footer-content-item flex-column clickable"}>*/}
                                {/*        <img src={TwitterIcon} alt={"twitter"} className={"media-icons"}/>*/}
                                {/*        /!*<p className={"footer-content"} onClick={()=>{*!/*/}
                                {/*        /!*    props.history.push("/privacy-policy");*!/*/}
                                {/*        /!*}}>Privacy Policy</p>*!/*/}
                                {/*    </div>*/}
                                {/*    <div className={"footer-content-item flex-column clickable"}>*/}
                                {/*        <img src={InstagramIcon} alt={"instagram"} className={"media-icons"}/>*/}
                                {/*        /!*<p className={"footer-content"} onClick={()=>{*!/*/}
                                {/*        /!*    props.history.push("/terms-and-conditions");*!/*/}
                                {/*        /!*}}>Terms & Conditions</p>*!/*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className={"d-flex align-items-center social-media-section"} >
                                    <div className={"footer-content-item flex-column clickable"}>
                                        {/*<img src={FacebookIcon} alt={"facebook"} className={"media-icons"}/>*/}
                                        <p className={"footer-content"} onClick={()=>{
                                            props.history.push("/faq");
                                        }}>FAQ</p>
                                    </div>
                                    <div className={"footer-content-item flex-column clickable"}>
                                        {/*<img src={TwitterIcon} alt={"twitter"} className={"media-icons"}/>*/}
                                        <p className={"footer-content"} onClick={()=>{
                                            props.history.push("/privacy-policy");
                                        }}>Privacy Policy</p>
                                    </div>
                                    <div className={"footer-content-item flex-column clickable"}>
                                        {/*<img src={InstagramIcon} alt={"instagram"} className={"media-icons"}/>*/}
                                        <p className={"footer-content"} onClick={()=>{
                                            props.history.push("/terms-and-conditions");
                                        }}>Terms & Conditions</p>
                                    </div>
                                </div>

                            </div>
                        {/*</MDBCol>*/}
                    </div>
                    {/*</MDBRow>*/}
                </MDBContainer>
                <div className={"copy-right-text"}>
                    <p className={"content"}>Copyright © 2022 PRM Roadways Ltd. All rights reserved.</p>
                </div>
            </MDBFooter>
        </div>
    );
}

export default withRouter(Footer);
